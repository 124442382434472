import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '../store'
import { getToken } from '@/utils/auth'
import { convertStringToSnake } from '@/utils/text'
import i18n from '../lang/i18n'

import routesConfig from '@/config/routes'

const defaultErrorLabel = 'request_error'
/**
 * Get error message from translation
 * @param {Object} data
 * @param {String} data.message
 * @param {Object} data.errorData
 * @param {Object} config
 * @returns {String}
 */
const getErrorMessage = (data, config) => {
  // Will convert default Boom errors to label format (e.g. "Not Found" => "not_found")
  if (data.message) {
    const messageLabel = convertStringToSnake(data.message)
    return i18n.t(`error.${messageLabel}`, data.errorData)
  }

  if (config && config.shouldSetDefaultError) {
    return i18n.t(`error.${defaultErrorLabel}`)
  }

  return ''
}

/**
 * Get the error message from response errorLabel
 * @param {Object} res
 * @param {String} res.errorLabel
 * @param {Object} res.errorData
 * @param {Object} i18n
 * @returns {String|null}
 */
const getResponseErrorLabel = (res, i18n) => {
  if (!res.errorLabel) {
    return null
  }

  return i18n.t(`error.${res.errorLabel}`, res.errorData)
}

/**
 * Get the error message from response error
 * @param {Object} res
 * @param {String} res.error
 * @param {Object} res.errorData
 * @param {Object} i18n
 * @returns {String|null}
 */
const getResponseError = (res, i18n) => {
  if (!res.error) {
    return null
  }

  // Temporary check to handle non-boomified endpoints throwing Boom errors - they don't have an errorLabel field
  if (i18n.te(`error.${res.error}`)) {
    return i18n.t(`error.${res.error}`, res.errorData)
  }

  return res.error
}

// Create axios instance
const service = axios.create({
  baseURL: import.meta.env.VITE_BASE_API_URL,
  timeout: 30000
})

// request interceptors
service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      config.headers['Authorization'] = getToken() // Let each request carry a custom token Please modify it according to the actual situation
    }
    return config
  },
  error => {
    // Do something with request error
    Promise.reject(error)
  }
)

// response interceptors
service.interceptors.response.use(
  response => {
    /**
     * The code is non-20000 error-free
     */
    if (!response || !response.data) {
      return Promise.reject(i18n.t('login.error_empty_response'))
    }
    const res = response.data

    // Update token if new one is found in response
    const token = res.token || response.headers.authorization
    if (token) {
      store.dispatch('UpdateUserToken', token)
    }

    // @TODO remove res.code checks once all the API responses are formatted
    if (response.status !== 200 || (res.code && res.code !== 20000 && res.code !== 20100)) {
      switch (res.code) {
        // @TODO move to error handling once all the API returned data have been reworked
        // (https://trello.com/c/ab5CxccU/1292-unify-the-format-of-the-returned-data-from-the-api)
        case 40300:
          if (
            store.getters.selectedAssistant._id &&
            response.config.url.includes(store.getters.selectedAssistant._id)
          ) {
            store.dispatch('CheckAssistantPermission', store.getters.selectedAssistant)
          }
          return response.data
        // Silent error
        case 40066:
          return response.data
        // Invalid username
        case 40316:
          return Promise.reject(i18n.t('login.error_invalid_username'))
        // User disconnected
        case 50008:
        case 50012:
        case 50014:
          MessageBox.confirm(i18n.t('login.message_box_text'), i18n.t('login.message_box_title'), {
            confirmButtonText: i18n.t('login.message_box_confirm_button'),
            cancelButtonText: i18n.t('login.message_box_cancel_button'),
            type: 'warning'
          })
            .then(() => {
              store.dispatch('FedLogOut').then(() => {
                location.reload() // To re-instantiate the vue-router object Avoid bugs
              })
            })
            .catch(() => {
              // User did not ask to relog, @TODO something special
            })
          break
        // Default error message
        default:
          // @TODO Remove once all the errors are handled with Boom
          Message({
            message: getResponseErrorLabel(res, i18n) || getResponseError(res, i18n) || res.message,
            type: 'error',
            duration: 5 * 1000
          })
          return response.data
      }
    } else {
      return response.data
    }
  },
  error => {
    // request is canceled, no message to display in UI
    if (error && error.__CANCEL__) {
      return Promise.resolve({})
    }

    console.error('request error debug : ', error) // for debug

    const data = error.response && error.response.data
    const config = error.response && error.response.config

    // We don't want to display errors for codes 500 and 400
    if (data && data.statusCode !== 500 && data.statusCode !== 400) {
      // Unauthorized request, which means no token or invalid token => logout the user
      if (data.statusCode === 401) {
        // On the login page, we don't want to redirect again to the login page
        // If the login fails, an error message is displayed in the login page instead
        const isLoginPath = config && RegExp(routesConfig.API_LOGIN_REGEX).test(config.url)
        if (!isLoginPath) {
          store.dispatch('FedLogOut').then(() => {
            location.reload() // To re-instantiate the vue-router object Avoid bugs
          })
        }
      }

      const errorMessage = getErrorMessage(data, config)

      if (errorMessage) {
        error.message = errorMessage
      }
    } else if (config && config.shouldSetDefaultError) {
      /*
       If we want the errors with a statusCode of 500 or 400 to automatically have a default error message to display, set shouldSetDefaultError config to true
       Especially useful for CUD (Create/Update/Delete) calls asx we don't want to leave the user without a message informing that something went wrong

       In some other cases, we don't need to display any error, for example:
       - When we only need to reset data in case of error (e.g. Dashboard analytics - getDashboardAnalytics)
       - When we redirect the user (e.g. invalid demo page code - getDemoPageSettings)
      */
      error.message = i18n.t(`error.${defaultErrorLabel}`)
    }

    return Promise.reject(error)
  }
)

export default service
