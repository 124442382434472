const config = {
  SERVICE: {
    GOOGLE: {
      name: 'google'
    },
    VOXYGEN: {
      name: 'voxygen'
    },
    ELEVENLABS: {
      name: 'elevenlabs'
    },
    CARTESIA: {
      name: 'cartesia'
    }
  }
}

export default config
