import { getAdminsList } from '@/api/admin'
import masterActionAPI from '@/api/masterAction'
import { getAssistantBySlug } from '@/api/assistant'
import constants from '@/config/constants'

const admin = {
  state: {
    selectedMasterAction: {},
    selectedAssistant: {},
    adminsList: [],
    selectedLocale: constants.DEFAULT_PLATFORMS_LOCALE
  },

  mutations: {
    SET_SELECTED_MASTER_ACTION: (state, selectedMasterAction) => {
      state.selectedMasterAction = selectedMasterAction
    },
    SET_SELECTED_ADMIN_ASSISTANT: (state, selectedAssistant) => {
      state.selectedAssistant = selectedAssistant
    },
    SET_ADMINS_LIST: (state, adminsList) => {
      state.adminsList = adminsList
    },
    SET_SELECTED_SUPERADMIN_LOCALE: (state, locale) => {
      state.selectedLocale = locale
    },
    SET_ADMIN_ASSISTANT_PROPERTY: (state, { property, value }) => {
      state.selectedAssistant[property] = value
    }
  },

  actions: {
    SwitchAdminAssistant({ commit }, assistant) {
      commit('SET_SELECTED_ADMIN_ASSISTANT', assistant)
    },
    GetAdminAssistant({ commit }, assistantSlug) {
      return new Promise((resolve, reject) => {
        getAssistantBySlug(assistantSlug)
          .then(data => {
            commit('SET_SELECTED_ADMIN_ASSISTANT', data)
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    SwitchMasterAction({ commit }, masterAction) {
      commit('SET_SELECTED_MASTER_ACTION', masterAction)
    },
    SwitchIntentMaster({ commit }, intentMaster) {
      commit('SET_SELECTED_INTENT_MASTER', intentMaster)
    },
    GetAdminsList({ commit }) {
      return new Promise((resolve, reject) => {
        return getAdminsList()
          .then(data => {
            commit('SET_ADMINS_LIST', data)
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    GetMasterAction({ commit }, masterActionSlug) {
      return new Promise((resolve, reject) => {
        masterActionAPI
          .get(masterActionSlug)
          .then(response => {
            const data = response.data
            commit('SET_SELECTED_MASTER_ACTION', data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}

export default admin
