import request from '@/utils/request'

/**
 * Create user account
 * @param {Object} userInfo
 * @param {String} userInfo.firstName
 * @param {String} userInfo.lastName
 * @param {String} userInfo.email
 * @param {String} userInfo.password
 * @param {String} userInfo.googleId
 * @param {String} userInfo.avatar
 * @returns {Promise<Object>} token of the created user
 */
export function signUp(userInfo) {
  return request({
    url: '/admin/signup',
    method: 'post',
    data: userInfo,
    shouldSetDefaultError: true
  })
}

/**
 * Login of the user
 * @param {String} email
 * @param {String} password
 * @param {String} googleId
 * @returns {Promise<Object>} token of the user
 */
export function login(email, password, googleId) {
  return request({
    url: '/admin/login',
    method: 'post',
    data: {
      email,
      password,
      googleId
    },
    shouldSetDefaultError: true
  })
}

/**
 * Get info of a user
 * @param {String} username
 * @returns {Promise<Object>} user info
 */
export function getInfo(username) {
  const usernamePath = username ? '/' + username : ''
  return request({
    url: `/admin/info${usernamePath}`,
    method: 'get'
  })
}

/**
 * Log out the user
 */
export function logout() {
  return request({
    url: '/admin/logout',
    method: 'post'
  })
}

/**
 * Send reset password link to user
 */
export function sendResetLink(email) {
  return request({
    url: '/admin/password/link',
    method: 'post',
    data: { email }
  })
}

/**
 * Reset user password
 */
export function resetPassword(email, password, resetPasswordToken) {
  return request({
    url: '/admin/password/reset',
    method: 'post',
    data: { email, password, resetPasswordToken }
  })
}
