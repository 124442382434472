const config = {
  FEEDBACK_MAX_DISPLAYED_LENGTH: 30,
  INPUT_TYPE_ICONS: {
    voice: 'microphone',
    touch: 'hand-point-up',
    keyboard: 'keyboard',
    keypad: 'hand-point-up'
  },
  RATINGS_LIST: [
    {
      value: 1,
      imageName: 'angry-face_1f621.png',
      color: '#7f6767'
    },
    {
      value: 2,
      imageName: 'sad-face_2639.png',
      color: '#b78c69'
    },
    {
      value: 3,
      imageName: 'neutral-face_1f610.png',
      color: '#f1cf0e'
    },
    {
      value: 4,
      imageName: 'smiling-face_1f600.png',
      color: '#8dcc3c'
    },
    {
      value: 5,
      imageName: 'star-face_1f929.png',
      color: '#36af3c'
    }
  ],
  BADGE_TYPES: {
    ADMIN: {
      name: 'admin',
      color: '#ffb822'
    },
    DEMO: {
      name: 'demo',
      color: '#52b4ff'
    }
  },
  EXPORT_INTERVALS: {
    hour: 'hour',
    day: 'day',
    month: 'month'
  },
  STATISTICS_EXPORT_TYPE: 'conversationsStatistics',
  HISTORY_EXPORT_TYPE: 'conversationsHistory',
  EVENT_TYPES: {
    CLICK: 'click',
    OPEN: 'open',
    CLOSE: 'close',
    CALL: 'call',
    TRANSFER: 'transfer',
    HANGUP: 'hangup',
    AUTOMATIC_HANGUP: 'automatic_hangup',
    FALLBACK_TIMEOUT: 'fallback_timeout',
    CALL_TRANSFER_INIT: 'call_transfer_init',
    CALL_TRANSFER_RINGING: 'call_transfer_ringing',
    CALL_TRANSFER_ANSWERED: 'call_transfer_answered',
    CALL_TRANSFER_NO_ANSWER: 'call_transfer_no_answer',
    CALL_TRANSFER_HANGUP: 'call_transfer_hangup',
    CALL_TRANSFER_COMPLETED: 'call_transfer_completed',
    PUSH_NOTIFICATION: 'push_notification',
    GATHER: 'gather',
    DISCONNECT: 'disconnect',
    RECONNECT: 'reconnect'
  },
  EVENT_ELEMENTS: {
    LINK: 'link',
    LIVE_CHAT: 'live_chat',
    WEBCHAT_NOTIFICATION: 'webchat_notification',
    PHONE: 'phone',
    WEBCHAT_POPIN: 'webchat_popin',
    WEBCHAT_EXEC_JS_FUNC: 'webchat_exec_js_func',
    MENU: 'menu',
    MENU_LINK: 'menu_link',
    MESSENGER_CONTROL_TO_PAGE_INBOX: 'messenger_control_to_page_inbox',
    MESSENGER_CONTROL_TO_ASSISTANT: 'messenger_control_to_assistant'
  },
  SOURCE_TYPES: {
    MENU: 'menu'
  },
  DATE_FORMAT_HOURS: 'HH[h] mm[min] ss[s]',
  DATE_FORMAT_MINUTES: 'mm[min] ss[s]',
  DATE_FORMAT_SECONDS: 'ss[s]',
  NB_MESSAGES_FILTERS: {
    ALL: '0',
    MORE_THAN_MEDIUM: '3',
    MORE_THAN_LARGE: '10'
  },
  // Used to count the valid messages to show on users sections
  MAIN_MESSAGE_TYPES_LIST: ['message', 'audio', 'file', 'carousel', 'form', 'list', 'video', 'geolocation'],
  DISPLAY_OPTIONS: {
    ONLY_MESSAGES: 'only_messages',
    MESSAGES_AND_LOGS: 'messages_and_logs'
  },
  // Not adding this to action config STATIC_ANSWERS_MESSAGE_TYPE because it is not used as a block type
  API_CALL_MESSAGE_TYPE: 'apiCall',
  API_CALL_TYPES: {
    ACTION_BLOCK: 'action_block',
    GENERATIVE_AI_BLOCK: 'generative_ai_block',
    SLOT_FULFILLMENT: 'slot_fulfillment',
    POST_CALL_ACTION: 'post_call_action',
    SLOT_EXTRACTION: 'slot_extraction',
    SLOT_TRANSFORMER: 'slot_transformer',
    TRANSCRIPTION_CORRECTION: 'transcription_correction',
    SLOT_VALUE_VALIDATION: 'slot_value_validation',
    METADATA_FULFILLMENT: 'metadata_fulfillment',
    SLOT_GENERATIVE_AI_FULFILLMENT: 'slot_generative_ai_fulfillment'
  },
  // To update the conversation list every 10 seconds
  DELAY_UPDATE_ACTIVE_CONV_LIST: 10000,
  // used to save the activation status of the automatic refresh for each assistant: conv_automatic_refresh_{assistantId}
  LOCAL_STORAGE_REFRESH_KEY_BASE: 'conv_automatic_refresh_',
  DEFAULT_END_TIME: 1800, // 30 minutes in seconds
  VOICE_END_TIME: 30 // in seconds
}

config.EVENTS_WITH_DURATION = [
  config.EVENT_TYPES.HANGUP,
  config.EVENT_TYPES.CALL_TRANSFER_ANSWERED,
  config.EVENT_TYPES.CALL_TRANSFER_NO_ANSWER,
  config.EVENT_TYPES.CALL_TRANSFER_COMPLETED,
  config.EVENT_TYPES.CALL_TRANSFER_HANGUP
]

config.HANGUP_EVENTS = [config.EVENT_TYPES.HANGUP, config.EVENT_TYPES.AUTOMATIC_HANGUP]

export default config
