const config = {
  LOGIN_PATHS: {
    SIGN_IN: {
      NAME: 'signin',
      PATH: '/signin'
    },
    SIGN_UP: {
      NAME: 'signup',
      PATH: '/signup'
    },
    RESET: {
      NAME: 'reset',
      PATH: '/signin/reset'
    }
  },
  LOGIN_REDIRECTS: ['login_redirect', 'signup_redirect'],
  NOT_FOUND_PATH: {
    NAME: '404',
    PATH: '/404'
  },
  DEMO_PAGE_NAME: 'demo',
  API_LOGIN_REGEX: /^\/admin\/login\/?$/
}

const loginPaths = Object.keys(config.LOGIN_PATHS)
config.WHITE_LIST_NAME = [config.NOT_FOUND_PATH.NAME].concat(
  loginPaths.map(path => config.LOGIN_PATHS[path].NAME),
  config.LOGIN_REDIRECTS,
  [config.DEMO_PAGE_NAME]
)
config.WHITE_LIST_PATH = [config.NOT_FOUND_PATH.PATH].concat(loginPaths.map(path => config.LOGIN_PATHS[path].PATH))

export default config
