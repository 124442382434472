<i18n src="../lang/fr.json"></i18n>
<i18n src="../lang/en.json"></i18n>
<template>
  <header
    id="m_header"
    class="m-grid__item m-header"
    m-minimize-offset="200"
    m-minimize-mobile-offset="200"
    :style="{ background: `${color ? color : ''}` }"
  >
    <div class="m-container m-container--fluid m-container--full-height">
      <div class="m-stack m-stack--ver m-stack--desktop">
        <!-- BEGIN: Brand -->
        <div class="m-stack__item m-brand m-brand--skin-light">
          <div class="m-stack m-stack--ver m-stack--general">
            <div class="m-stack__item m-stack__item--middle m-brand__logo">
              <router-link to="/apps/list" class="m-brand__logo-wrapper">
                <img :src="logoUrl" style="width: 34px" />
              </router-link>
            </div>
            <navbar-dropdown :is-top-menu-app-content-visible="isTopMenuAppContentVisible" />
          </div>
        </div>
        <!-- END: Brand -->
        <div id="m_header_nav" class="m-stack__item m-stack__item--fluid m-header-head">
          <!-- BEGIN: Horizontal Menu -->
          <button
            id="m_aside_header_menu_mobile_close_btn"
            class="m-aside-header-menu-mobile-close m-aside-header-menu-mobile-close--skin-dark"
          >
            <i class="la la-close" />
          </button>
          <div
            id="m_header_menu"
            class="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark"
          >
            <div class="m-header__title">
              <h3 class="m-header__title-text">{{ capitalize(title) }}</h3>
            </div>
            <connected-live-chat-agents-list
              v-if="nonSuperAdminLiveChatAgents && nonSuperAdminLiveChatAgents.length"
              :live-chat-agents="nonSuperAdminLiveChatAgents"
            />
          </div>
          <!-- END: Horizontal Menu -->
          <keep-alive>
            <profile-menu :is-top-menu-app-content-visible="isTopMenuAppContentVisible" />
          </keep-alive>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
// Dependencies
import { mapGetters } from 'vuex'

// Components
import ProfileMenu from '@/views/layout/components/ProfileMenu.vue'
import NavbarDropdown from '@/views/layout/components/Dropdown/Index.vue'
import ConnectedLiveChatAgentsList from '@/views/layout/components/ConnectedLiveChatAgentsList.vue'

// Config
import adminConfig from '@/config/admin'
import constants from '@/config/constants'

// Utils
import { capitalize } from '@/utils/text'
import { getFileUrl } from '@/utils/image'

// Bus
import Bus from '@/Bus.js'

export default {
  name: 'LayoutNavbar',
  components: {
    ProfileMenu,
    NavbarDropdown,
    ConnectedLiveChatAgentsList
  },
  props: {
    isTopMenuAppContentVisible: { type: Boolean, default: true }
  },
  data: function () {
    const logoUrl =
      (import.meta.env.VITE_WHITE_LOGO_PATH && getFileUrl(import.meta.env.VITE_WHITE_LOGO_PATH)) ||
      getFileUrl(constants.YELDA_WHITE_LOGO_PATH)
    return {
      color: this.$store.state.app.envColor,
      titleParam: null,
      nonSuperAdminLiveChatAgents: [],
      logoUrl
    }
  },
  computed: {
    ...mapGetters(['nbActiveLiveChatConversations', 'liveChatAgents', 'selectedAssistant']),
    pageName() {
      return (this.$route.meta && this.$route.meta.name) || this.$route.name
    },
    title() {
      return this.$i18n.t(`titles.${this.pageName}`)
    }
  },
  watch: {
    '$route.name': function () {
      this.setPageTitle()
      this.setLiveChatAgentsList()
    },
    nbActiveLiveChatConversations: function () {
      this.setPageTitle()
    },
    liveChatAgents: function () {
      this.setLiveChatAgentsList()
    }
  },
  created() {
    this.setPageTitle()
    this.setLiveChatAgentsList()
    Bus.$on('pageTitleParam', param => {
      this.titleParam = `${param} -`
      this.setPageTitle()
    })
  },
  methods: {
    capitalize,
    setPageTitle() {
      let title = this.capitalize(`${this.titleParam || ''} ${this.$i18n.t(`titles.${this.pageName}`)} - Yelda`.trim())

      if (this.nbActiveLiveChatConversations && 'assistantSlug' in this.$route.params) {
        title = `(${this.nbActiveLiveChatConversations}) ${title}`
      }

      document.title = title
    },
    setLiveChatAgentsList() {
      if (!this.selectedAssistant || !this.$route.fullPath.includes('liveConversations')) {
        this.nonSuperAdminLiveChatAgents = []
        return
      }

      this.nonSuperAdminLiveChatAgents = this.liveChatAgents.filter(
        agent => agent.roles && !agent.roles.includes(adminConfig.SUPER_ADMIN_ROLE)
      )
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
header {
  z-index: 9997;
}
.m-brand .m-brand__logo {
  width: 34px;
}
.m-header .m-stack__item.m-brand.m-brand--skin-light {
  width: 210px;
  margin-right: 0;
  padding-right: 0;
}
.m-header-menu {
  padding-left: 0;
  margin-left: 0;
}
.m-brand {
  padding: 0 30px 0 16px;
}
</style>
