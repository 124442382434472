<template>
  <div id="app">
    <router-view />
  </div>
</template>

<!-- Importing Metronic CSS files -->
<script>
import { getAppRegionInfo } from '@/api/interface'

export default {
  name: 'App',
  created() {
    getAppRegionInfo().then(response => {
      if (response) {
        this.$store.dispatch('SetRegionInfo', response)
      }
    })
    document.body.addEventListener('mousedown', function () {
      document.body.classList.add('using-mouse')
    })
    document.body.addEventListener('keydown', function () {
      document.body.classList.remove('using-mouse')
    })
  }
}
</script>

<style>
@import 'assets/metronic/default/assets/demo/default/base/style.bundle.css';
@import 'assets/metronic/default/assets/vendors/base/vendors.bundle.css';
@import 'styles/app.css';
@import url('https://fonts.googleapis.com/css?family=Poppins:300i,400,400i,500,500i,600,600i,700,700i');
</style>
