import { asyncRouterMap, constantRouterMap } from '@/router'
import currentRouter from '@/router'
import Router from 'vue-router'
import { hasSuperAdminRole } from '@/utils/validate'

/**
 * Determine whether to match the current user authority by meta.role
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.indexOf(role) >= 0)
  } else {
    return true
  }
}

/**
 * Recursively filter the asynchronous routing table and return the routing table that matches the user's role permissions
 * @param asyncRouterMap
 * @param roles
 */
function filterAsyncRouter(asyncRouterMap, roles) {
  const accessedRouters = asyncRouterMap.filter(route => {
    if (hasPermission(roles, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, roles)
      }
      return true
    }
    return false
  })
  return accessedRouters
}

const permission = {
  state: {
    routers: constantRouterMap,
    filteredRouters: constantRouterMap,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = state.filteredRouters.concat(routers)
    },
    REMOVE_ROUTE: (state, routeName) => {
      state.filteredRouters = constantRouterMap.filter(route => route.name !== routeName)
      /* eslint-disable id-length */
      const updatedRouter = new Router({
        mode: 'history',
        scrollBehavior: () => ({
          y: 0
        }),
        routes: state.filteredRouters
      })
      /* eslint-enable id-length */
      currentRouter.matcher = updatedRouter.matcher
    }
  },
  actions: {
    GenerateRoutes({ commit }, roles) {
      return new Promise(resolve => {
        let accessedRouters
        if (hasSuperAdminRole(roles)) {
          accessedRouters = asyncRouterMap
        } else {
          accessedRouters = filterAsyncRouter(asyncRouterMap, roles)
        }
        commit('SET_ROUTERS', accessedRouters)
        resolve()
      })
    },
    RemoveRoute({ commit }, routeName) {
      commit('REMOVE_ROUTE', routeName)
    }
  }
}

export default permission
