import request from '@/utils/request'
import { getQueryString } from '@/utils/url'

/**
 * Find master intents by filters
 *
 * @param {Object} filters - Search filters
 * @returns {Promise<Object>}
 */
async function find(filters = {}) {
  return request({
    url: `/intentMasters${getQueryString(filters)}`,
    method: 'get',
    shouldSetDefaultError: true
  })
}

/**
 * get master intents list
 *
 * @returns {Promise<Object>}
 */
function getAllMasterIntents() {
  return new Promise(resolve => {
    request({
      url: '/intentMasters/all',
      method: 'get'
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.error('error intentMasterAPI.findSlug', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}

/**
 * Get a master intent
 *
 * @param {String} intentMasterId - Intent identifier
 * @returns {Promise<any>}
 */
function getById(intentMasterId) {
  return new Promise(resolve => {
    request({
      url: `/intentMasters/${intentMasterId}`,
      method: 'get'
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.error('error intentMasterAPI.getById', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}

/**
 * Add a master intent
 *
 * @param {Object} intentMaster - Master intent
 * @param {Array<Object>} entitiesValuesToUpdate - Array of entity values objects {id: '<EntityId>', locale:'xx_YY', valueToAdd: '<String>', selectedValue: '<String>'}
 * @returns {Promise<any>}
 */
function add(intentMaster, entitiesValuesToUpdate) {
  return new Promise(resolve => {
    request({
      url: `/intentMasters`,
      method: 'post',
      data: {
        intentMaster,
        entitiesValuesToUpdate
      }
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.error('error intentMasterAPI.add', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}

/**
 * Update a master intent
 *
 * @param {String} intentMasterId - Master intent identifier
 * @param {Object} intentMaster - Master Intent
 * @param {Array<Object>} entitiesValuesToUpdate - Array of entity values objects {id: '<EntityId>', locale:'xx_YY', valueToAdd: '<String>', selectedValue: '<String>'}
 * @returns {Promise<any>}
 */
function updateById(intentMasterId, intentMaster, entitiesValuesToUpdate) {
  return new Promise(resolve => {
    request({
      url: `/intentMasters/${intentMasterId}`,
      method: 'post',
      data: {
        intentMaster,
        entitiesValuesToUpdate
      }
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.error('error intentMasterAPI.updateById', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}

/**
 * Delete a master intent
 *
 * @param {String} intentMasterId - Master intent identifier
 * @returns {Promise<any>}
 */
function deleteById(intentMasterId) {
  return request({
    url: `/intentMasters/${intentMasterId}`,
    method: 'delete'
  })
}

/**
 * Get master intents based on entity
 * @param {Object} filters
 * @param {String} filters.entityId - entity Id
 * @param {Array} filters.industryIds - list of industryIds
 * @returns {Promise<any>}
 */

function getByEntity(filters = {}) {
  return new Promise(resolve => {
    request({
      url: `/intentMasters${getQueryString(filters)}`,
      method: 'get'
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.error('error intentMasterAPI.getByEntity', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}

/**
 * Get a localized training phrase for one of the masterIntentIds
 * @param {Object} filters
 * @param {Array<String>} filters.masterIntentIds - intents Id
 * @param {Array} filters.locale - locale
 * @return {Promise<Object|null>} Object being {locale, trainingPhrase, intentId} or null
 */
function getLocalizedIntentsTrainingPhrase(filters = {}) {
  return new Promise(resolve => {
    if (!filters.masterIntentIds || filters.masterIntentIds.length === 0 || !filters.locale) {
      return resolve(null)
    }

    filters.masterIntentIds = JSON.stringify(filters.masterIntentIds)

    request({
      url: `/intentMasters/trainingPhrase${getQueryString(filters)}`,
      method: 'get'
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.error('error intentMasterAPI.getLocalizedIntentsTrainingPhrase', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}

/**
 * Get master intent entities
 * @param {String} intentId
 * @param {Object} filters
 * @param {String} filters.assistantId
 * @returns {Promise<Array>}
 */
function getIntentEntities(intentId, filters = {}) {
  return request({
    url: `/intentMasters/${intentId}/entities${getQueryString(filters)}`,
    method: 'get'
  })
}

export default {
  find,
  getAllMasterIntents,
  getById,
  add,
  updateById,
  deleteById,
  getByEntity,
  getLocalizedIntentsTrainingPhrase,
  getIntentEntities
}
