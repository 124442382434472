<i18n src="../../lang/fr.json"></i18n>
<i18n src="../../lang/en.json"></i18n>
<template>
  <div v-if="isTopMenuAppContentVisible || isSuperAdminView" class="m-stack__item m-stack__item--middle m-brand__tools">
    <!--faire super admin, checker les ref)-->
    <assistant
      v-if="!isSuperAdminView && selectedAssistant && selectedAssistant.slug"
      :assistants="assistants"
      :selected-assistant-locale="selectedAssistantLocale"
      :selected-assistant="selectedAssistant"
      @SwitchAssistant="selectAssistant"
    />
    <locale :locales-list="localesList" :selected-locale="selectedLocale" @changeLocale="selectLocale" />
  </div>
</template>

<script>
// Api
import { updateAdminPlatformLocale } from '@/api/admin'

// Dependances
import { mapGetters } from 'vuex'

// Components
import Locale from './Locale.vue'
import Assistant from './Assistant.vue'

// Config
import constants from '@/config/constants'

// Utils
import { getDisplayId } from '@/utils/assistant'

export default {
  name: 'NavbarDropdown',
  components: {
    Locale,
    Assistant
  },
  props: {
    isTopMenuAppContentVisible: { type: Boolean, default: true }
  },
  computed: {
    ...mapGetters([
      'selectedAssistant',
      'assistants',
      'selectedAssistantLocale',
      'selectedSuperAdminLocale',
      'isSuperAdminView',
      'isSaved'
    ]),
    selectedLocale: function () {
      return (
        (!this.isSuperAdminView &&
          this.selectedAssistant &&
          this.selectedAssistant.locales &&
          this.selectedAssistantLocale) ||
        this.selectedSuperAdminLocale
      )
    },
    localesList: function () {
      return (
        (!this.isSuperAdminView && this.selectedAssistant && this.selectedAssistant.locales) ||
        this.$store.state.app.platformsAvailableLocales
      )
    }
  },
  watch: {
    selectedAssistantLocale: function () {
      if (!this.selectedAssistantLocale) {
        return
      }
      updateAdminPlatformLocale(this.$store.getters.userId, this.selectedAssistantLocale).catch(err => {
        console.error(err)
      })
    },
    selectedSuperAdminLocale: function () {
      if (!this.selectedSuperAdminLocale) {
        return
      }
      updateAdminPlatformLocale(this.$store.getters.userId, this.selectedSuperAdminLocale).catch(err => {
        console.error(err)
      })
    }
  },
  methods: {
    selectAssistant({ key, assistant }) {
      const route =
        constants.REDIRECT_TO_DASHBOARD_PAGES.indexOf(this.$route.name) > -1
          ? constants.DASHBOARD_PAGE
          : this.$route.name

      const pendingRoute = {
        name: route,
        params: { displayId: getDisplayId(assistant._id), assistantSlug: assistant.slug }
      }

      if (!this.isSaved) {
        this.$store.commit('SET_PENDING_ROUTE', pendingRoute)
        this.$store.commit('SET_SHOW_MODAL', true)
        return
      }

      this.$store.dispatch('SwitchAssistant', { assistant }).then(() => {
        this.$router.push(pendingRoute)
      })
    },
    selectLocale(locale) {
      if (!this.isSaved) {
        this.$store.commit('SET_PENDING_LOCALE_MODAL', locale) // Show modal too
        return
      }

      if (this.isSuperAdminView) {
        this.$store.commit('SET_SELECTED_SUPERADMIN_LOCALE', locale)
      } else {
        this.$store.commit('SET_SELECTED_ASSISTANT_LOCALE', locale)
      }

      this.$store.commit('UNSET_PENDING_LOCALE_MODAL') // Set isSave to true too
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.m-dropdown__inner {
  width: 270px;
}
.dropdown {
  max-height: 300px;
  overflow: auto;
  line-height: 1.6;
}
.btn-white,
.btn-white * {
  color: white;
  white-space: normal;
  text-align: center;
  line-height: 1.2;
}
.btn-white:hover,
.btn-white:hover * {
  color: #d8e5ff;
}
.m-brand__tools {
  text-align: left !important;
}
.m-header .m-dropdown__toggle.btn.btn-white.btn-lg {
  max-width: 132px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 3px;
  padding-right: 0;
  margin-left: 5px;
  text-align: left;
}
.m-header .m-dropdown .fa-angle-down {
  color: white;
  cursor: pointer;
}
.m-header .m-dropdown .fa-angle-down:hover {
  color: #d8e5ff;
}
.m-nav__link-icon {
  height: 35px !important;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  margin: 5px;
  padding: 3px;
  text-align: left;
}
.m-nav__link-icon img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 21px;
}
.m-nav .m-nav__item > .m-nav__link .m-nav__link-text {
  padding-left: 8px;
}
.m-dropdown-app a.m-dropdown__toggle {
  padding-top: 2px;
  padding-bottom: 0;
}
.m-dropdown-app-lang a.m-dropdown__toggle {
  padding-top: 2px;
  padding-bottom: 4px;
  padding-right: 0;
  margin-left: 5px;
  padding-left: 3px;
}
.m-dropdown-app .fa-angle-down {
  vertical-align: -4px;
}
.m-dropdown-app-lang .fa-angle-down {
  vertical-align: -1px;
  font-size: 10px;
}
.m-brand {
  padding: 0 30px 0 16px;
}
</style>
<style>
.dropdown-header-top .fa-angle-down {
  margin-left: 2px;
  padding-left: 3px;
}

.dropdown-description {
  display: block;
}
</style>
