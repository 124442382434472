const config = {
  DEFAULT_IMAGE_SOURCE: 'yelda',
  DEFAULT_ASSISTANT_IMAGE_PATH: 'assets/yelda-default-character.png',
  FILENAME_RAW: 'raw',
  FILENAME_THUMB: '100',
  FILENAME_FULL: '1000',
  ACCEPTED_IMAGE_TYPES: {
    GIF: {
      extension: 'gif',
      contentType: 'image/gif'
    },
    PNG: {
      extension: 'png',
      contentType: 'image/png'
    },
    JPG: {
      extension: 'jpg',
      contentType: 'image/jpeg'
    },
    JPEG: {
      extension: 'jpeg',
      contentType: 'image/jpeg'
    },
    SVG: {
      extension: 'svg',
      contentType: 'image/svg+xml'
    }
  },
  DEFAULT_MAX_IMAGE_SIZE: 10485760, // 10 Mo
  ASSISTANT_ADMIN_MAX_IMAGE_SIZE: 20971520, // 20 Mo
  ASSISTANT_ADMIN_MIN_DIMENSIONS: {
    WIDTH: 500,
    HEIGHT: 500
  },
  MAX_IMAGES_NUMBER: 10,
  VUE_IMG_CONFIG: {
    // Use `alt` attribute as gallery slide title
    altAsTitle: false,
    // Display 'download' button near 'close' that opens source image in new tab
    sourceButton: false,
    // Event listener to open gallery will be applied to <img> element
    openOn: 'click',
    // Show thumbnails for all groups with more than 1 image
    thumbnails: true
  },
  CHAT_BUBBLE_DIMENSION: {
    MAX_WIDTH: 400,
    MAX_HEIGHT: 100
  },
  UPLOAD_BASE_URL: '/media/images'
}

config.ACCEPTED_EXTENSIONS = Object.values(config.ACCEPTED_IMAGE_TYPES).map(type => type.extension)
config.ACCEPTED_CONTENT_TYPES_STRING = Object.values(config.ACCEPTED_IMAGE_TYPES)
  .map(type => type.contentType)
  .join(',')

export default config
