import Vue from 'vue'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en' // lang i18n

import '@/styles/index.scss' // global css

import App from './App.vue'
import router from './router'
import store from './store'

import i18n from './lang/i18n'

import '@/permission' // permission control

import VuePaginate from 'vue-paginate' // pagination frontend
import VueImg from 'v-img' // display images in full screen
import imageConfig from '@/config/image'
import VModal from 'vue-js-modal' // custom modal helper
import { VeeValidate, getVeeValidateInitObject } from './utils/vee-validate'
import autosize from 'autosize'

Vue.use(VuePaginate)
Vue.use(VModal)

Vue.use(VeeValidate, getVeeValidateInitObject(i18n))

Vue.use(ElementUI, { locale })
Vue.use(VueImg, imageConfig.VUE_IMG_CONFIG)

Vue.config.productionTip = false

// Focus element when it is inserted
Vue.directive('focus', {
  inserted: function (el, binding) {
    // If true value passed to v-focus or if no value is passed the element get focused
    if (!Object.prototype.hasOwnProperty.call(binding, 'value') || binding.value) {
      el.focus()
    }
  }
})

// Unlike v-if and v-show, v-visible allows us to keep the element size and position when not visible
Vue.directive('visible', function (el, binding) {
  el.style.visibility = binding.value ? 'visible' : 'hidden'
})

// to automatically resize textareas
Vue.directive('autosize', {
  bind: function (el, binding, vnode) {
    vnode.context.$nextTick(function () {
      autosize(el)
    })
  },
  update: function (el) {
    autosize.update(el)
  },
  unbind: function (el) {
    autosize.destroy(el)
  }
})

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  components: { App },
  template: '<App/>'
})
