<template>
  <div id="webchat" class="webchat-preview" :style="!isDemo && 'display: none'">
    <div id="webchat_container"></div>
  </div>
</template>

<script>
import serverConfig from '@/config/server'

import yeldaChat from 'yelda-webchat'
import 'yelda-webchat/dist/css/yeldaWebchatInjector.min.css'
import Bus from '@/Bus.js'
import { mapGetters } from 'vuex'

export default {
  name: 'Webchat',
  props: {
    isDemo: { type: Boolean, default: false },
    assistantId: { type: String, default: null },
    assistantSlug: { type: String, default: null },
    locale: { type: String, default: null },
    platformSimulated: { type: String, default: null }
  },
  data() {
    return {
      chatData: {},
      chatUrl: this.setChatUrl(window.location.origin),
      isChatSetup: false,
      isWebchatOpen: false
    }
  },
  computed: {
    ...mapGetters(['selectedAssistantLocale', 'selectedAssistant'])
  },
  watch: {
    selectedAssistantLocale: function () {
      if (!this.isDemo) {
        this.chatData.locale = this.selectedAssistantLocale
        yeldaChat.resetChat(this.chatData)
      }
    },
    platformSimulated: function () {
      this.chatData.platformSimulated = this.platformSimulated
      this.loadChat()
    },
    selectedAssistant: function (newAssistant, oldAssistant) {
      if (!this.isDemo && newAssistant.slug !== oldAssistant.slug) {
        this.loadChat()
      }
    }
  },
  created() {
    Bus.$on('openWebchat', async () => {
      this.isWebchatOpen = !this.isWebchatOpen
      if (!document.getElementById('yelda_iframe_container')) {
        await this.loadChat()
      }
      if (this.isWebchatOpen) {
        document.getElementById('webchat').style.display = 'block'
      } else {
        document.getElementById('webchat').style.display = 'none'
      }
    })
    Bus.$on('reloadChat', () => {
      this.loadChat()
    })
    Bus.$on('unLoadChat', () => {
      yeldaChat.unLoadChat()
      this.isChatSetup = false
    })

    Bus.$on('sendMessageToChat', message => {
      yeldaChat.sendMessageToChat(message)
    })
    // Used when click on detected intent or action link in webchat
    window.addEventListener('message', this.openUrl)
  },
  destroyed() {
    window.removeEventListener('message', this.openUrl)
  },
  mounted() {
    this.loadChat()
  },
  methods: {
    openUrl(evt) {
      if (evt.data.event === 'openUrl' && evt.data.data) {
        this.$router.push({ path: evt.data.data })
      }
    },
    async loadChat() {
      this.chatData = {
        assistantSlug: this.assistantSlug || this.selectedAssistant.slug,
        assistantId: this.assistantId || this.selectedAssistant.id,
        assistantUrl: this.chatUrl,
        locale: this.locale || this.selectedAssistantLocale,
        chatPath: '/chat',
        // Webchat will be accessible in admin mode even for demo
        isAdmin: true,
        parentContainerId: 'webchat_container',
        shouldBeOpened: true,
        canBeClosed: this.isDemo,
        ...(this.isDemo && { isDemo: true }),
        platformSimulated: this.platformSimulated
      }

      const chatcontainer = document.getElementById('yelda_iframe_container')

      if ((this.isChatSetup || chatcontainer) && yeldaChat && (this.assistantId || this.selectedAssistant.id)) {
        await yeldaChat.resetChat(this.chatData)
      } else if (yeldaChat && (this.assistantId || this.selectedAssistant.id)) {
        await yeldaChat.init(this.chatData)
        this.isChatSetup = true
      } else {
        console.info("can't init webchat")
      }
    },
    setChatUrl(defaultHost) {
      return serverConfig.LOCALHOST_REGEX.test(window.location.href) ? serverConfig.LOCALHOST_BASE_URL : defaultHost
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
.webchat-preview.m-aside-right {
  width: 340px;
  padding: 0;
}

.webchat-preview.m-aside-right .yelda_container.inner {
  position: fixed !important;
  right: 0;
  left: auto;
  top: 70px;
  z-index: 98;
  bottom: 0;
}

.webchat-preview.m-aside-right .yelda_container.inner .yelda_iframe_container {
  margin: 0 !important;
  border-radius: 0;
  height: calc(100vh - 70px);
  max-height: none;
  transition-duration: unset;
  width: 340px;
  box-shadow: 0 10px 5px rgba(0, 0, 0, 0.16);
}
</style>
