const config = {
  OLD_LOGIN_MINIMUM_PASSWORD_LENGTH: 5,
  NEW_MINIMUM_PASSWORD_LENGTH: 12,
  NEW_MAXIMUM_PASSWORD_LENGTH: 40,
  PASSWORD_CHECK_CHAR_THRESHOLD: 3,
  PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^&*-.,:;*+=/_~|\\(){}<>])/, // At least one lowercase (?=.*[a-z]), one uppercase (?=.*[A-Z]), one number (?=.*\d) and one special character (?=.*[#?!@$%^&*-.,:;*+=/_~|\\(){}<>])
  PASSWORD_UPPERCASE_REGEX: /[A-Z]/,
  PASSWORD_LOWERCASE_REGEX: /[a-z]/,
  PASSWORD_NUMBER_REGEX: /\d/,
  PASSWORD_SPECIAL_CHAR_REGEX: /[#?!@$%^&*-.,:;*+=/_~|\\(){}<>]/,
  ADMIN_ROLES: {
    SUPER_ADMIN: 'superAdmin',
    BASIC_ADMIN: 'admin',
    LIVE_CHAT_AGENT: 'liveChatAgent',
    INSIGHTS_MANAGER: 'insightsManager'
  },
  SUPER_ADMIN_ROLE: 'superAdmin',
  ASSISTANT_SCOPED_ROLES: []
}

config.ASSISTANT_SCOPED_ROLES = [
  config.ADMIN_ROLES.BASIC_ADMIN,
  config.ADMIN_ROLES.LIVE_CHAT_AGENT,
  config.ADMIN_ROLES.INSIGHTS_MANAGER
]

export default config
