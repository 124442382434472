<template>
  <section class="app-main">
    <transition name="fade" mode="out-in">
      <router-view :key="($route.meta && $route.meta.name) || $route.name" />
    </transition>
  </section>
</template>

<script>
// API's
import { getInProgressConversations } from '@/api/assistant'

// Dependencies
import { mapGetters } from 'vuex'

// Config
import constants from '@/config/constants'
import configLiveChat from '@/config/liveChat'

// Utils
import Bus from '@/Bus'
import { initWebSocketConnection, destructWebSocketConnection } from '@/utils/webSocket'
import { getActivatedSetting } from '@/utils/assistant'
import audioLib from '@/utils/audio'
import { hasAdminRole, hasLiveChatAgentRole } from '@/utils/validate'

export default {
  name: 'AppMain',
  computed: {
    ...mapGetters([
      'selectedAssistant',
      'selectedAssistantLocale',
      'isSuperAdminView',
      'nbActiveLiveChatConversations',
      'roles'
    ]),
    isAssistantRoute() {
      return !this.isSuperAdminView && 'assistantSlug' in this.$route.params
    },
    selectedAssistantAndLocale() {
      return { assistant: this.selectedAssistant, locale: this.selectedAssistantLocale }
    }
  },
  watch: {
    /*
      We want websocket connection only on assistant routes, and to update it on isAssistantRoute update.
      So if isAssistantRoute changes, it means we go out or to an assistant route.
      In that case we want to initilise a new websocket or destruct the existing websocket

      If we either change assistant or locale (selectedAssistantAndLocale), it means we need to update the websocket url.
      If newValue.assistant is undefined, it means we land on a non-assistant route. This change is handled by isAssistantRoute if needed
    */
    selectedAssistantAndLocale: function (newValue, oldValue) {
      if (!newValue.assistant) {
        return
      }
      if (newValue.assistant._id === oldValue.assistant._id && newValue.locale === oldValue.locale) {
        return
      }

      this.resetWebSocketConnection()
    },
    isAssistantRoute: function () {
      if (this.isAssistantRoute) {
        this.checkAndInitializeWebSocketConnection()
      } else {
        destructWebSocketConnection()
        this.destructLiveChatBusEvents()
      }
    }
  },
  created() {
    this.checkAndInitializeWebSocketConnection()
  },
  beforeDestroy() {
    destructWebSocketConnection()
    this.destructLiveChatBusEvents()
  },
  methods: {
    checkAndInitializeWebSocketConnection() {
      // no websocket connexion yet on super admin side or app list
      if (!this.isAssistantRoute) {
        // Reset nb live chat conversations
        this.$store.dispatch('SetNbLiveChatConversations', 0)
        return
      }

      initWebSocketConnection(this.selectedAssistant, this.selectedAssistantLocale)
      this.initBusEvents()

      if (!getActivatedSetting(this.selectedAssistant.settings, this.selectedAssistantLocale, 'liveChat')) {
        return
      }

      if (hasAdminRole(this.roles) || hasLiveChatAgentRole(this.roles)) {
        this.fetchLiveChatConversation()
      }
    },
    initBusEvents() {
      Bus.$on('setWebSocketConnectionStatus', isConnected => {
        // Update connection status
        this.$store.dispatch('SetWebSocketConnected', isConnected)
      })

      Bus.$on('handleLiveConversationNotification', conversationData => {
        // Update the live chat required nb count
        const previousNbActiveLiveChats = this.nbActiveLiveChatConversations
        const nbActiveLiveChats = conversationData?.nbActiveLiveChats || 0
        this.$store.dispatch('SetNbLiveChatConversations', nbActiveLiveChats)

        // if a new conversation is added to the live chat conversations list
        // and the admin is not on the liveConversations page, then play a notification sound
        if (
          previousNbActiveLiveChats < nbActiveLiveChats &&
          this.selectedAssistant &&
          !this.$route.fullPath.includes('liveConversations')
        ) {
          audioLib.ensureInit()
          audioLib.playMp3(constants.NOTIFICATION_SOUND)
        }
      })

      Bus.$on('updateLiveAgentList', liveAgents => {
        this.$store.dispatch('SetAssistantLiveChatAgents', liveAgents)
      })
    },
    destructLiveChatBusEvents() {
      Bus.$off('setWebSocketConnectionStatus')
      Bus.$off('handleLiveConversationNotification')
      Bus.$off('updateLiveAgentList')
    },
    fetchLiveChatConversation() {
      getInProgressConversations(
        this.selectedAssistant.id,
        this.selectedAssistantLocale,
        configLiveChat.CONVERSATION_TABS.AGENT_REQUESTED,
        constants.liveChatPlatformTypes
      )
        .then(data => {
          this.$store.dispatch('SetNbLiveChatConversations', data.length)
        })
        .catch(() => {
          this.$store.dispatch('SetNbLiveChatConversations', 0)
        })
    },
    resetWebSocketConnection() {
      this.$store.dispatch('SetAssistantLiveChatAgents', [])
      destructWebSocketConnection()
      this.destructLiveChatBusEvents()
      this.checkAndInitializeWebSocketConnection()
    }
  }
}
</script>
