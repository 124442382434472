<i18n src="./lang/fr.json"></i18n>
<i18n src="./lang/en.json"></i18n>

<template>
  <!-- BEGIN: Aside Menu -->
  <div
    id="m_ver_menu"
    class="m-aside-menu m-aside-menu--skin-light m-aside-menu--submenu-skin-light"
    m-menu-vertical="1"
    m-menu-scrollable="1"
    m-menu-dropdown-timeout="500"
  >
    <ul class="m-menu__nav">
      <li
        v-for="(route, key) in permissionRoutersDisplayed"
        :key="key"
        :value="route.meta.name"
        aria-haspopup="true"
        :class="route.component ? 'm-menu__item' : 'm-menu__section'"
        :style="[!route.meta.display && { display: 'none' }]"
      >
        <router-link
          v-if="route.component && route.meta && route.meta.display"
          :to="route.path | replaceId(displayId, assistantSlug)"
          class="m-menu__link"
        >
          <i class="m-menu__link-icon" :class="route.meta.class" />
          <span class="m-menu__link-title">
            <span class="m-menu__link-wrap">
              <span class="m-menu__link-text">
                {{ capitalize($t(`sidebar.${route['meta']['name'] || route['name']}`)) }}
                <span
                  v-if="route.meta.checkLiveChatCondition && selectedAssistant && nbActiveLiveChatConversations"
                  class="menuNBNotification"
                >
                  {{ nbActiveLiveChatConversations }}
                </span>
              </span>
            </span>
          </span>
        </router-link>
        <h4 v-else-if="route.meta && route.meta.display" class="m-menu__section-text">
          <i class="m-menu__section-icon" :class="route.meta.class" />
          {{ capitalize($t(`sidebar.${route['meta']['name'] || route['name']}`)) }}
        </h4>
      </li>
    </ul>
  </div>
  <!-- END: Aside Menu -->
</template>

<script>
import { mapGetters } from 'vuex'
import { capitalize } from '@/utils/text'
import { hasSuperAdminRole, hasAdminRole } from '@/utils/validate'
import { getDisplayId, getActivatedSetting } from '@/utils/assistant'

export default {
  filters: {
    replaceId: (string, id, slug) => string.replace(':displayId', id).replace(':assistantSlug', slug)
  },
  computed: {
    ...mapGetters([
      'permission_routers',
      'sidebar',
      'isSuperAdminView',
      'selectedAssistant',
      'selectedAssistantLocale',
      'nbActiveLiveChatConversations'
    ]),
    assistantSlug: function () {
      return this.selectedAssistant.slug
    },
    displayId: function () {
      return getDisplayId(this.selectedAssistant.id)
    },
    permissionRoutersDisplayed: function () {
      return this.permission_routers.filter(route => {
        if (!route.meta || !route.meta.display) {
          return false
        }

        if (this.isLiveConversationsRouteRefused(route.meta, this.selectedAssistant, this.selectedAssistantLocale)) {
          return false
        }

        return (
          (this.isSuperAdminView && hasSuperAdminRole(route.meta.roles)) ||
          (!this.isSuperAdminView && hasAdminRole(route.meta.roles))
        )
      })
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  methods: {
    capitalize,
    isLiveConversationsRouteRefused(routeMeta, assistant, locale) {
      if (!routeMeta.checkLiveChatCondition || !assistant) {
        return false
      }

      // if no getActivatedSetting for 'liveChat', then the liveChat settings is off for that locale
      return !getActivatedSetting(assistant.settings, locale, 'liveChat')
    }
  }
}
</script>

<style scoped>
div.m-aside-menu {
  overflow: auto;
}
.m-menu__link.router-link-active {
  background-color: #2a67da;
}

.m-menu__link.router-link-active:hover {
  background-color: #3d74dc !important;
}
.m-menu__item > .m-menu__link.router-link-active .m-menu__link-text,
.m-menu__item > .m-menu__link.router-link-active i {
  color: #ffffff !important;
}
.m-menu__item > .m-menu__link.router-link-active:hover .m-menu__link-text {
  color: #ffffff !important;
}

.m-aside-menu .m-menu__nav > .m-menu__item > .m-menu__link {
  padding: 9px 30px 9px 15px;
  border-radius: 5px;
}

.m-aside-menu .m-menu__nav > .m-menu__item {
  padding: 0 15px;
}
.m-aside-menu .m-menu__nav > .m-menu__section {
  margin: 0;
}
</style>
