import request from '@/utils/request'

/**
 * Return app region infos : locales and timezones
 * @returns {Promise<Object>}
 */
export function getAppRegionInfo() {
  return new Promise(resolve => {
    request({
      url: '/interface/region',
      method: 'get'
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.error('getAppRegionInfo error', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}
