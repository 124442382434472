import intentMasterAPI from '@/api/intentMaster'

const master = {
  state: {
    intents: []
  },
  mutations: {
    SET_MASTER_INTENTS: (state, intents) => {
      state.intents = intents
    }
  },
  actions: {
    SetMasterIntents({ commit }) {
      return new Promise((resolve, reject) => {
        intentMasterAPI
          .getAllMasterIntents()
          .then(response => {
            if (!response || !response.length) {
              throw new Error('empty_master_intents')
            }
            commit('SET_MASTER_INTENTS', response)
            resolve(response)
          })
          .catch(error => {
            return reject(error)
          })
      })
    }
  }
}

export default master
