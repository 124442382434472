<i18n src="../../lang/fr.json"></i18n>
<i18n src="../../lang/en.json"></i18n>
<template>
  <div
    ref="dropdownLocales"
    class="m-dropdown m-dropdown--arrow m-dropdown--open m-dropdown-app-lang dropdown-header-top"
    m-dropdown-toggle="click"
    @click.prevent="LocalesMenuDisplay = !LocalesMenuDisplay"
  >
    <a v-if="selectedLocale" href="#" class="m-dropdown__toggle btn btn-white btn-sm">
      {{ $t(`locales.${selectedLocale}`) }}
    </a>
    <i class="fa fa-angle-down"></i>
    <div
      v-show="LocalesMenuDisplay && formattedLocalesList && formattedLocalesList.length > 0"
      class="m-dropdown__wrapper"
    >
      <span class="m-dropdown__arrow m-dropdown__arrow--left" />
      <div class="m-dropdown__inner">
        <div class="m-dropdown__body dropdown">
          <div class="m-dropdown__content">
            <ul class="m-nav">
              <li v-for="locale in formattedLocalesList" :key="locale.value" class="m-nav__item" :value="locale.value">
                <a
                  href="#"
                  class="m-nav__link"
                  data-toggle="m-tooltip"
                  data-placement="right"
                  data-skin="dark"
                  data-container="body"
                  @click.prevent="selectLocale(locale.value)"
                >
                  <span class="m-nav__link-text">{{ locale.name }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatLocales } from '@/utils/text'

export default {
  name: 'LocaleDropdown',
  props: {
    localesList: { type: Array, required: true },
    selectedLocale: { type: String, required: true }
  },
  data: function () {
    return {
      LocalesMenuDisplay: false,
      formattedLocalesList: []
    }
  },
  watch: {
    localesList: {
      immediate: true,
      handler() {
        this.formattedLocalesList = formatLocales(this.localesList, this.$i18n)
      }
    }
  },
  created() {
    document.addEventListener('click', this.documentClick)
  },
  destroyed() {
    document.removeEventListener('click', this.documentClick)
  },
  methods: {
    documentClick(event) {
      // close dropdown when we click outside the list
      const localesDropdown = this.$refs.dropdownLocales
      const target = event.target

      if (localesDropdown && localesDropdown !== target && !localesDropdown.contains(target)) {
        this.LocalesMenuDisplay = false
      }
    },
    selectLocale(locale) {
      this.$emit('changeLocale', locale)
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.m-dropdown__inner {
  width: 270px;
}
.dropdown {
  max-height: 300px;
  overflow: auto;
  line-height: 1.6;
}
.btn-white,
.btn-white * {
  color: white;
  white-space: normal;
  text-align: center;
  line-height: 1.2;
}
.btn-white:hover,
.btn-white:hover * {
  color: #d8e5ff;
}
.m-brand__tools {
  text-align: left !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}
.m-header .m-dropdown__toggle.btn.btn-white.btn-lg {
  max-width: 132px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 3px;
  padding-right: 0;
  margin-left: 5px;
  text-align: left;
}
.m-header .m-dropdown .fa-angle-down {
  color: white;
  cursor: pointer;
}
.m-header .m-dropdown .fa-angle-down:hover {
  color: #d8e5ff;
}
.m-nav__link-icon {
  height: 35px !important;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  margin: 5px;
  padding: 3px;
  text-align: left;
}
.m-nav__link-icon img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 21px;
}
.m-nav .m-nav__item > .m-nav__link .m-nav__link-text {
  padding-left: 8px;
}
.m-dropdown-app a.m-dropdown__toggle {
  padding-top: 2px;
  padding-bottom: 0;
}
.m-dropdown-app-lang a.m-dropdown__toggle {
  padding-top: 2px;
  padding-bottom: 4px;
  padding-right: 0;
  margin-left: 5px;
  padding-left: 3px;
}
.m-dropdown-app .fa-angle-down {
  vertical-align: -4px;
}
.m-dropdown-app-lang .fa-angle-down {
  vertical-align: -1px;
  font-size: 10px;
}
.m-brand {
  padding: 0 30px 0 16px;
}
</style>
