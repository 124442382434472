import { formatDateToUTCString } from '@/utils/date'
import { normalizeLowerCase } from '@/utils/text'

/**
 * flatten an array of arrays
 * @param {Array} array array we want to flatten
 */
export function flatArray(array) {
  return array.reduce((acc, val) => (Array.isArray(val) ? acc.concat(flatArray(val)) : acc.concat(val)), [])
}

/**
 * get the correct key to the source array from the key of an item in a reversed array
 * @param {Number} arrayLength the length of the source array
 * @param {Number} reversedKey the key of the item we want to find from the reversed array
 * @returns {Number} the matching key in the source array
 * concrete example :
 *  we have an array of 3 elements ['hello', 'hi', 'welcome', 'good morning']
 *  the reversed array displayed will be ['good morning', 'welcome', 'hi', 'hello']
 *  we want to modify the item 'good morning' in the source array
 *  1) we retreive the element with the key 3 in the reversed array
 *  2) we need to find this element in the source array (which has the key 0)
 *     we get the length of the source array to which we substract the reverse key + 1
 *  3) we return 4 - 3 + 1 which is equals to 0, the index we wanted.
 */
export function getCorrectItemKey(arrayLength, reversedKey) {
  return arrayLength - (reversedKey + 1)
}

/**
 * Format array string element
 * @param {*} element
 * @returns {*} If the element is a string, it has been normalized, otherwise we return the element as it is
 */
function normalizeStringElement(element) {
  return typeof element === 'string' ? normalizeLowerCase(element) : element
}

/**
 * sort an array of objects based on a property of the objects
 * @param {Array} array array of objects to sort
 * @param {string} objectProperty property on which the sorting is done
 * @param {Boolean} isOrderDesc by default the order is ascending
 * @returns {Array} array sorted
 */
export function sortArrayOfObjectsByProperty(array, objectProperty, isOrderDesc = false) {
  return array.sort((elementA, elementB) => {
    const formattedA = normalizeStringElement(elementA[objectProperty])
    const formattedB = normalizeStringElement(elementB[objectProperty])
    if (formattedA > formattedB) {
      return isOrderDesc ? -1 : 1
    }
    return formattedB > formattedA ? (isOrderDesc ? 1 : -1) : 0
  })
}

/** remove element from array
 * @param {Array} array
 * @param {String} classToRemove class to remove
 */
export function removeClassFromArray(array, classToRemove) {
  const index = array.indexOf(classToRemove)
  if (index !== -1) {
    array.splice(index, 1)
  }
}

/**
 * Check if two arrays contain the same elements
 * @param {Array<String>} firstArray
 * @param {Array<String>} secondArray
 * @returns {Boolean}
 */
export function checkEquivalenceArrays(firstArray, secondArray) {
  if (
    !firstArray ||
    !secondArray ||
    !Array.isArray(firstArray) ||
    !Array.isArray(secondArray) ||
    firstArray.length !== secondArray.length
  ) {
    return false
  }

  return firstArray.every(item => secondArray.includes(item))
}

/**
 * Compare two objects to see if they are equals
 * @param {Object} firstItem
 * @param {Object} secondItem
 * @returns {Boolean}
 *
 * Check if:
 * 1. both items are objects
 * 2. they have the same number of keys
 * 3. they have the same keys and values
 *
 */
export function areObjectsEqual(firstItem, secondItem) {
  return (
    typeof firstItem === 'object' &&
    typeof secondItem === 'object' &&
    Object.keys(firstItem).length === Object.keys(secondItem).length &&
    Object.keys(firstItem).every(key => firstItem[key] === secondItem[key])
  )
}

/**
 * Compare two arrays of objects to see if they are equals
 * @param {Array<Object>} firstArray
 * @param {Array<Object>} secondArray
 * @returns {Boolean}
 */
export function areArraysOfObjectsEqual(firstArray, secondArray) {
  return (
    firstArray &&
    secondArray &&
    firstArray.length === secondArray.length &&
    firstArray.every(firstItem => secondArray.some(secondItem => areObjectsEqual(firstItem, secondItem)))
  )
}

/**
 * Return if common string are present in the two arrays
 * @param {Array<String>} arrayOne - array of object ids
 * @param {Array<String>} arrayTwo - array of object ids
 * @returns {Boolean}
 */
export function doArraysHaveCommonElements(arrayOne, arrayTwo) {
  return arrayOne.some(item => arrayTwo.includes(item))
}

/**
 * Move an element to a different index in an array
 * @param {Array} array
 * @param {Number} fromIdx
 * @param {Number} toIdx
 * @returns {Array} updated array
 */
export function moveElementInArray(array, fromIdx, toIdx) {
  const element = array.splice(fromIdx, 1)[0]
  array.splice(toIdx, 0, element)
  return array
}

/**
 * Checks the ids list changed by comparing with oldIdsList
 * @param {Array<Object>} newElementsList
 * @param {Array<String>} oldIdsList
 * @returns {Boolean}
 */
export function haveIdsListChanged(newElementsList, oldIdsList) {
  const newIdsList = newElementsList.map(element => element.id || element._id).filter(element => element)
  return !oldIdsList || !checkEquivalenceArrays(oldIdsList, newIdsList)
}

/**
 * Get the common values from an array of string arrays
 *
 * E.g.
 * const elements = [['a', 'b', 'c'], ['a'], ['a', 'c']]
 * getCommonValuesFromArrayOfArrays(elements, 'list') => ['a']
 *
 * If there is no common element, the function returns an empty array
 *
 * @param {Array<{Array}>} array of string arrays (none of the arrays should be empty)
 * @returns {Array<{String}>} array of values common to all the elements of the array
 */
export function getCommonValuesFromArrayOfArrays(list) {
  let commonElements = list.shift()

  list.some(ids => {
    commonElements = commonElements.filter(value => ids.includes(value))
    // if commonElements length is 0, '.some' lets us break the loop early
    return !commonElements.length
  })

  return commonElements
}

/**
 * Check if an array of strings or numbers has duplicate values
 * @param {Array<String|Number>} array
 * @returns {Boolean}
 */
export function hasDuplicateValues(array) {
  return new Set(array).size !== array.length
}

/**
 * Check if an array of strings has duplicate values (case insensitive)
 * @param {Array<String>} array
 * @returns {Boolean}
 */
export function hasDuplicateStringValuesCaseInsensitive(array) {
  return new Set(array.map(value => value.toLowerCase())).size !== array.length
}

/**
 * Check if an array of stringified dates has duplicate values
 * @param {Array<String>} array
 * @returns {Boolean}
 */
export function hasDuplicateDateValues(array) {
  // When we select a date, it is by in the time zone of the user browser
  // format the date to UTC to be able to compare all the dates with the same time zone
  return new Set(array.map(date => formatDateToUTCString(date))).size !== array.length
}

/**
 * Checks if all the values of a first array are in the second array
 * @param {Array} arrayOne
 * @param {Array} arrayTwo
 * @returns {Boolean}
 */
export function areAllValuesInArray(arrayOne, arrayTwo) {
  return arrayOne.every(value => arrayTwo.includes(value))
}

/**
 * Sort an array of objects alphabetically based on the formattedName property
 * @export
 * @param {Array} list
 * @param {String} sortingProperty
 * @return {Array}
 */
export function sortArrayAlphabetically(list, sortingProperty) {
  return list.sort((elementA, elementB) => {
    if (elementA[sortingProperty] < elementB[sortingProperty]) return -1
    if (elementA[sortingProperty] > elementB[sortingProperty]) return 1
    return 0
  })
}

/**
 * Group an array of objects into two groups, based on a grouping property
 * @export
 * @param {Array} list
 * @param {String} locale
 * @param {Function} formatNameForSort // function to add a formattedName property to the objects
 * @param {String} groupingProperty // property to group the objects
 * @param {String} formatProperty // property to add the formatted name

 * @return {Object}
 */
export function groupList(list, locale, formatNameForSort, groupingProperty, formatProperty) {
  const formattedList = formatNameForSort(list, locale, formatProperty)
  return formattedList.reduce(
    (accumulator, currentValue) => {
      if (currentValue[groupingProperty]) {
        accumulator.groupWithGroupingProperty.push(currentValue)
      } else {
        accumulator.groupWithoutGroupingProperty.push(currentValue)
      }
      return accumulator
    },
    { groupWithGroupingProperty: [], groupWithoutGroupingProperty: [] }
  )
}

/**
 * Sorting groups by the formattedName property and concatenating them
 *
 * @export
 * @param {Array} list
 * @param {String} locale
 * @param {Function} formatNameForSort // function to add a formattedName property to the objects
 * @param {String} groupingProperty // property to group the objects
 * @param {String} sortingProperty // key used to sort the list
 * @param {Boolean} isGroupWithoutPropertyFirst // if true, group without property will be first
 * @return {Array}
 */
export function sortAndGroupList(
  list,
  locale,
  formatNameForSort,
  groupingProperty,
  sortingProperty,
  isGroupWithoutPropertyFirst = false
) {
  const { groupWithGroupingProperty, groupWithoutGroupingProperty } = groupList(
    list,
    locale,
    formatNameForSort,
    groupingProperty,
    sortingProperty
  )
  const sortedGroupWithGroupingProperty = sortArrayAlphabetically(groupWithGroupingProperty, sortingProperty)
  const sortedGroupWithoutGroupingProperty = sortArrayAlphabetically(groupWithoutGroupingProperty, sortingProperty)
  if (isGroupWithoutPropertyFirst) {
    return sortedGroupWithoutGroupingProperty.concat(sortedGroupWithGroupingProperty)
  }
  return sortedGroupWithGroupingProperty.concat(sortedGroupWithoutGroupingProperty)
}
