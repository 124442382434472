<i18n src="./lang/fr.json"></i18n>
<i18n src="./lang/en.json"></i18n>

<template>
  <div
    class="m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-light m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default"
    :class="{ 'm-aside-left--fixed': isSidebarVisible }"
    style="height: 100%"
  >
    <div class="m-grid m-grid--hor m-grid--root m-page" :class="classObj" style="height: 100%">
      <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
      <!-- BEGIN::Header -->
      <header />
      <!-- END: Header -->
      <!-- BEGIN::Body -->
      <div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <!-- BEGIN: Left Aside -->
        <sidebar v-if="isSidebarVisible" class="m-grid__item m-aside-left m-aside-left--skin-light" />
        <!-- END: Left Aside -->
        <div class="m-grid__item m-grid__item--fluid m-wrapper">
          <navbar :is-top-menu-app-content-visible="isTopMenuAppContentVisible" />
          <!-- BEGIN: Page Content -->
          <app-main />
          <!-- END: Page Content -->
        </div>
        <webchat v-if="isTopMenuAppContentVisible" class="m-grid__item m-aside-right m-aside-right--skin-light" />
      </div>
    </div>
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain } from './components'
import Webchat from '@/components/Webchat/index.vue'
import ResizeMixin from './mixin/ResizeHandler'

import { capitalize } from '@/utils/text'
import { getValidInterfaceLocale } from '@/utils/locale'

import { mapGetters } from 'vuex'
import i18n from '@/lang/i18n'
export default {
  name: 'Layout',
  components: {
    Navbar,
    Sidebar,
    AppMain,
    Webchat
  },
  mixins: [ResizeMixin],
  props: {
    // hide left side menu, ex apps/lists, preferences
    isSidebarVisible: {
      default: true,
      type: Boolean
    },
    // hide app dropdown menu and webchat button, ex apps/lists, preferences, admins
    isTopMenuAppContentVisible: {
      default: true,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters(['locale']),
    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    }
  },
  watch: {
    locale: {
      handler() {
        if (this.$i18n.locale !== this.$store.state.user.locale) {
          const locale = this.$store.state.user.locale || navigator.language || navigator.userLanguage
          i18n.locale = getValidInterfaceLocale(locale)
        }
      },
      immediate: true
    }
  },
  methods: {
    capitalize,
    handleClickOutside() {
      this.$store.dispatch('CloseSideBar', { withoutAnimation: false })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import '../../styles/mixin.scss';
.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
}
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}
</style>
