const conf = {
  MASTER_ACTIONS_TABS_TITLES: [
    {
      NAME: 'Core'
    },
    {
      NAME: 'Triggers'
    },
    {
      NAME: 'Slots'
    },
    {
      NAME: 'Flows'
    }
  ],
  RESUMABILITY_OPTIONS: {
    resumable: true,
    not_resumable: false
  },
  ACTIVATION_OPTIONS: {
    activated: true,
    not_activated: false
  },
  DEFAULT_LIVE_OPTION: {
    master_action_default_live_status_on: true,
    master_action_default_live_status_off: false
  },
  ALLOW_RESUMABILITY_OPTIONS: {
    allow_resumability: true,
    not_allow_resumability: false
  },
  DB_DEFAULT_VALUES: {
    ALLOW_RESUMABILITY: true,
    IS_ACTIVATED: true,
    IS_ESCALATION_ACTIVATED: true,
    IS_FOLLOWUP_ACTIVATED: false,
    IS_LIVE: true,
    IS_RESUMABLE: true
  },
  WELCOME_ACTION_DEFAULT_IS_RESUMABLE: false,
  ACTION_CATEGORY: {
    ACTION: 'Action',
    MASTER: 'MasterAction'
  },
  ANSWERS_SETTINGS_TABS_TITLES: {
    activated: {
      value: 'activated',
      icon: 'la la-volume-up'
    },
    deactivated: {
      value: 'deactivated',
      icon: 'la la-volume-off'
    }
  },
  ANSWERS_MAX_LENGTH_DEFAULTS: {
    text: 5000,
    voice: 1000,
    phone: 1000,
    // https://www.twilio.com/fr/docs/sms/send-messages#body-corps
    sms: 1600,
    email: 100000
  },
  // Sort from shorter to longer so we can find the sortest one first
  ANSWERS_MAX_LENGTH_PLATFORMS: {
    messenger: 2000,
    instagram: 2000,
    workplace: 2000,
    whatsapp: 4096
  },
  SHORT_QUICK_REPLIES_MAX_LENGTH: 20, // For WhatsApp (25) & Messenger/Instagram/Workplace (20)
  LONG_QUICK_REPLIES_MAX_LENGTH: 50, // Magic number for other platforms (webchat, phone, Alexa, ...) to avoid too long QR
  SHORT_QUICK_REPLIES_PLATFORMS: ['messenger', 'instagram', 'workplace', 'whatsapp'],
  ANSWER_TYPES: [
    'staticAnswers',
    'defaultAnswers',
    'followupAnswers',
    'acknowledgementAnswers',
    'repetitionAcknowledgmentAnswers',
    'resumeActionAnswers',
    'welcomeConversationAnswers',
    'followupActionSettings'
  ],
  ANSWER_FIELD_TYPES: {
    TEXT: {
      name: 'text',
      isRequired: true,
      icon: 'fa-keyboard',
      isEmojiCompatible: true
    },
    VOICE: {
      name: 'voice',
      isRequired: false,
      icon: 'fa-microphone',
      isEmojiCompatible: false
    },
    PHONE: {
      name: 'phone',
      isRequired: false,
      icon: 'fa-phone',
      isEmojiCompatible: false
    },
    SMS: {
      name: 'sms',
      isRequired: false,
      icon: 'fa-comment',
      isEmojiCompatible: true,
      objectKey: 'phoneNumberItem'
    },
    EMAIL: {
      name: 'email',
      isRequired: false,
      icon: 'fa-envelope',
      isEmojiCompatible: true,
      objectKey: 'emailItem'
    }
  },
  STATIC_ANSWERS_MESSAGE_TYPE: {
    CHIP: 'chip',
    MESSAGE: 'message',
    CHIPS_TITLE: 'chipsTitle',
    SMS: 'sms',
    CALL_TRANSFER: 'callTransfer',
    CALL_END: 'callEnd',
    API: 'api',
    EMAIL: 'email',
    GENERATIVE_AI: 'generativeAi'
  },
  TYPE_QUICK_REPLIES_ACTIONS: {
    INTENT: 'intent',
    WEBSITE: 'website',
    GEOLOCATION: 'geolocation',
    KNOWLEDGE_URL: 'knowledge_url',
    EXEC_JS_FUNC: 'exec_js_func'
  },
  ALWAYS_ACTIVATED_ACTIONS: {
    FALLBACK: 'fallback',
    WELCOME: 'welcome'
  },
  ACTION_TABS: {
    CORE: {
      componentName: 'Core',
      tabName: 'core',
      isMasterOnly: true
    },
    FLOW: {
      componentName: 'Flow',
      tabName: 'flow',
      isMasterOnly: false
    },
    TRIGGER: {
      componentName: 'Intents',
      tabName: 'trigger',
      isMasterOnly: false
    },
    SLOTS: {
      componentName: 'Slots',
      tabName: 'slots',
      isMasterOnly: false
    },
    SETTINGS: {
      componentName: 'Settings',
      tabName: 'settings',
      isMasterOnly: false
    }
  },
  ACTION_FLOWS: {
    ACTIVATED: 'activated',
    DEACTIVATED: 'deactivated'
  },
  ACTION_CUSTOM_ANSWERS_PROPERTIES: {
    STATIC: 'useCustomStaticAnswers',
    DEFAULT: 'useCustomDefaultAnswers'
  },
  ACTION_TYPES: {
    FALLBACK: 'fallback',
    WELCOME: 'welcome',
    GENERIC: 'generic'
  },
  MASTER_ACTION_TYPES: {
    FALLBACK: 'masterFallback',
    WELCOME: 'masterWelcome',
    GENERIC: 'masterGeneric'
  },
  TRIGGER_STEP_TABS: {
    NEW: 'new',
    EXISTING: 'existing'
  },
  ENDPOINT_VALIDATION_STATUS: {
    REJECTED: {
      text: 'endpoint_status_rejected',
      icon: 'la-close',
      class: 'm--font-danger'
    },
    VALIDATED: {
      text: 'endpoint_status_validated',
      icon: 'la-check',
      class: 'm--font-success'
    },
    FORCE_VALIDATED: {
      text: 'endpoint_status_force_validated',
      icon: 'la-warning',
      class: 'm--font-warning'
    }
  },
  CREATION_STEPS: {
    ABOUT: {
      iconClass: 'fa flaticon-placeholder',
      orderPosition: 1,
      title: 'about',
      step: 'step1',
      componentName: 'About'
    },
    TRIGGER: {
      iconClass: 'fa flaticon-speech-bubble-1',
      orderPosition: 2,
      title: 'trigger',
      step: 'step2'
    },
    ANSWERS: {
      iconClass: 'fa flaticon-layers',
      orderPosition: 3,
      title: 'answers',
      step: 'step3',
      componentName: 'AnswersSettings'
    }
  },
  STATUS_FILTERS: [
    {
      status: 'all'
    },
    {
      status: 'activated'
    },
    {
      status: 'deactivated'
    },
    {
      status: 'off'
    }
  ],
  GROUP_FILTERS: {
    category: 'category',
    no_group: 'no_group'
  },
  ANSWER_CONDITION_SOURCES: {
    CONTEXT: 'context',
    SLOT: 'slot',
    CONDITION: 'condition'
  },
  SAMPLE_PER_ACTION_OPTIONS: {
    one_sample: 'one_sample',
    all_sample: 'all_sample'
  },
  EXPORT_TYPE: 'actions',
  LINKING_SENTENCES_TABS: {
    RESUME: 'resumeAction',
    REPETITION: 'repetitionAcknowledgment',
    WELCOME_CONVERSATION: 'welcomeConversation'
  },
  // For Welcome and Fallback actions
  // slots tab will be removed for fallback action using splice method
  CONTEXT_ACTIONS_TABS: {
    FLOW: 'flow',
    SLOTS: 'slots',
    SETTINGS: 'settings'
  },
  FALLBACK_ACTION_CONTEXT: {
    DEFAULT: {
      name: 'default'
    },
    REPEATED: {
      name: 'repeated'
    },
    ESCALATION: {
      name: 'escalation',
      isEditable: true
    }
  },
  WELCOME_ACTION_CONTEXT: {
    NEW: {
      name: 'new'
    },
    REGULAR: {
      name: 'regular',
      isEditable: true
    }
  },
  QUICK_REPLY_URL_REGEX: /^(tel|mailto)\:.+$/,
  // See backend default config for more info about the regex below
  MARKDOWN_LINK_REGEX: /\[([^\[]+)\]\(([^\)]+)\)/g,
  DEFAULT_READ_ORALLY_STATUS: false,
  DEFAULT_BASIC_ALTERNATIVE: {
    answers: [{ blocks: [] }],
    slotsUpdates: []
  },
  DEFAULT_ANSWER_BOOLEAN_OBJECT: {
    isDefault: true
  },
  DEFAULT_SMS_BLOCK_OBJECT: {
    recipientNumber: null,
    recipientNumberType: null,
    fallbackMessage: '',
    successMessage: '',
    text: ''
  },
  DEFAULT_EMAIL_BLOCK_OBJECT: {
    recipientEmail: null,
    recipientEmailType: null,
    body: '',
    subject: ''
  },
  DEFAULT_GENERATIVE_AI_BLOCK_OBJECT: {
    generativeAi: {}
  },
  DEFAULT_CONTEXT_SLOT_BOOLEANS: {
    isUsedInAnswer: true,
    isRequestedInActionFlow: true,
    isResetAtStart: false,
    isSlotInContextConfirmationRequired: true
  },
  API_BLOCK_ANSWER_SOURCES: {
    NO_ANSWER: 'none',
    BLOCK: 'block',
    API: 'api'
  },
  DEFAULT_LIVE_CHAT_ACTIONS: ['contact_agent_chat', 'contact_agent_chat_generic'],
  CATEGORY_MINIMUM_LENGTH: 3,
  NO_ACTION: 'no_action',
  MIN_TRANSFER_LIST_FOR_SEARCH: 5,
  RECIPIENT_NUMBER_TYPES: {
    SLOT: 'slot',
    KNOWLEDGE: 'knowledge'
  }
}

conf.DEFAULT_ANSWER_WELCOME_CONDITIONS_OBJECT = {
  conditions: [
    {
      source: conf.ANSWER_CONDITION_SOURCES.CONTEXT,
      value: conf.WELCOME_ACTION_CONTEXT.NEW.name
    }
  ]
}

conf.DEFAULT_ANSWER_FALLBACK_CONDITIONS_OBJECT = {
  conditions: [
    {
      source: conf.ANSWER_CONDITION_SOURCES.CONTEXT,
      value: conf.FALLBACK_ACTION_CONTEXT.DEFAULT.name
    }
  ]
}

conf.BOOLEAN_CONDITIONAL_VALUE = 'refusal'

conf.ANSWER_FIELD_TYPES.DEFAULT = conf.ANSWER_FIELD_TYPES.TEXT
conf.ANSWER_FIELD_TYPES_LIST = [
  conf.ANSWER_FIELD_TYPES.TEXT,
  conf.ANSWER_FIELD_TYPES.VOICE,
  conf.ANSWER_FIELD_TYPES.PHONE
]

conf.DELETEABLE_ANSWER_FIELD_TYPES = [conf.ANSWER_FIELD_TYPES.VOICE, conf.ANSWER_FIELD_TYPES.PHONE]

conf.ALWAYS_ACTIVATED_ALL_ACTION_TYPES = [conf.MASTER_ACTION_TYPES.WELCOME, conf.MASTER_ACTION_TYPES.FALLBACK].concat([
  conf.ACTION_TYPES.WELCOME,
  conf.ACTION_TYPES.FALLBACK
])

conf.ACTIONS_WITH_ACKNOWLEDGEMENT_ANSWERS = [conf.ACTION_TYPES.WELCOME, conf.ACTION_TYPES.GENERIC]

conf.QUICK_REPLIES_ADDITIONAL_ACTIONS = {
  WEBSITE: {
    type: conf.TYPE_QUICK_REPLIES_ACTIONS.WEBSITE,
    label: 'action_open_website'
  },
  GEOLOCATION: {
    type: conf.TYPE_QUICK_REPLIES_ACTIONS.GEOLOCATION,
    label: 'action_geolocation',
    helperType: 'location'
  },
  KNOWLEDGE_URL: {
    type: conf.TYPE_QUICK_REPLIES_ACTIONS.KNOWLEDGE_URL,
    label: 'action_open_knowledge_url',
    helperType: 'knowledge_url'
  },
  NO_INTENT: {
    label: 'no_intent'
  },
  EXEC_JS_FUNC: {
    type: conf.TYPE_QUICK_REPLIES_ACTIONS.EXEC_JS_FUNC,
    label: 'action_exec_js_function',
    helperType: 'exec_js_func'
  }
}

conf.CONDITIONS_HANDLED_BY_TRANSLATION = [
  conf.BOOLEAN_CONDITIONAL_VALUE,
  conf.FALLBACK_ACTION_CONTEXT.DEFAULT.name,
  conf.FALLBACK_ACTION_CONTEXT.REPEATED.name,
  conf.FALLBACK_ACTION_CONTEXT.ESCALATION.name,
  conf.WELCOME_ACTION_CONTEXT.NEW.name,
  conf.WELCOME_ACTION_CONTEXT.REGULAR.name
]

conf.ALWAYS_ACTIVATED_CONTEXT_DEFAULT_VALUES = [
  conf.FALLBACK_ACTION_CONTEXT.DEFAULT.name,
  conf.WELCOME_ACTION_CONTEXT.NEW.name
]

conf.PHONE_MESSAGE_TYPES = [conf.STATIC_ANSWERS_MESSAGE_TYPE.CALL_TRANSFER, conf.STATIC_ANSWERS_MESSAGE_TYPE.CALL_END]
conf.EMAIL_MESSAGE_TYPES = [conf.STATIC_ANSWERS_MESSAGE_TYPE.EMAIL]
conf.GENERATIVE_AI_MESSAGE_TYPES = [conf.STATIC_ANSWERS_MESSAGE_TYPE.GENERATIVE_AI]

conf.NEW_BLOCK_OPTIONS = [
  {
    messageType: conf.STATIC_ANSWERS_MESSAGE_TYPE.MESSAGE,
    icon: 'fas fa-paragraph',
    isDefault: true,
    componentName: 'AnswerGroup',
    isDefaultComponent: true
  },
  {
    messageType: conf.STATIC_ANSWERS_MESSAGE_TYPE.CHIP,
    icon: 'fas fa-hand-pointer',
    isDefault: true,
    componentName: 'ChipsBlock'
  },
  {
    messageType: conf.STATIC_ANSWERS_MESSAGE_TYPE.CALL_TRANSFER,
    icon: 'fas fa-phone',
    isDefault: false,
    componentName: 'CallManagementBlock',
    messageTypeOptions: conf.PHONE_MESSAGE_TYPES
  },
  {
    messageType: conf.STATIC_ANSWERS_MESSAGE_TYPE.SMS,
    icon: 'fas fa-comment',
    isDefault: false,
    componentName: 'SmsBlock'
  },
  {
    messageType: conf.STATIC_ANSWERS_MESSAGE_TYPE.API,
    icon: 'fas fa-arrow-left',
    isDefault: true,
    componentName: 'ApiBlock'
  },
  {
    messageType: conf.STATIC_ANSWERS_MESSAGE_TYPE.EMAIL,
    icon: 'fas fa-envelope',
    isDefault: false,
    componentName: 'EmailBlock',
    messageTypeOptions: conf.EMAIL_MESSAGE_TYPES
  },
  {
    messageType: conf.STATIC_ANSWERS_MESSAGE_TYPE.GENERATIVE_AI,
    icon: 'fas fa-robot',
    isDefault: false,
    componentName: 'GenerativeAiBlock',
    messageTypeOptions: conf.GENERATIVE_AI_MESSAGE_TYPES
  }
]

conf.CHIPS_TYPES = [conf.STATIC_ANSWERS_MESSAGE_TYPE.CHIP, conf.STATIC_ANSWERS_MESSAGE_TYPE.CHIPS_TITLE]

conf.API_BLOCK_FAILURE_ANSWER_SOURCES = [conf.API_BLOCK_ANSWER_SOURCES.NO_ANSWER, conf.API_BLOCK_ANSWER_SOURCES.BLOCK]
conf.API_BLOCK_SUCCESS_ANSWER_SOURCES = [
  conf.API_BLOCK_ANSWER_SOURCES.NO_ANSWER,
  conf.API_BLOCK_ANSWER_SOURCES.API,
  conf.API_BLOCK_ANSWER_SOURCES.BLOCK
]

export default conf
