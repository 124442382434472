import VeeValidate from 'vee-validate' // custom validation helper
import frValidationMessages from 'vee-validate/dist/locale/fr'
import enValidationMessages from 'vee-validate/dist/locale/en'
import { isValidPhoneNumber } from 'libphonenumber-js'

// Adding custom phone number validation rule
VeeValidate.Validator.extend(
  'phoneNumber',
  {
    validate: value => isValidPhoneNumber(value)
  },
  {
    immediate: false
  }
)

/**
 * Get vee validate configuration init object
 * @param {Object} i18n - vue i18n object
 * @returns {Object} vee-validate configuration object
 */
const getVeeValidateInitObject = i18n => {
  // Adding custom validation message for phoneNumber validation
  enValidationMessages.messages.phoneNumber = () => i18n.t('error.invalid_phone_number', 'en_US')
  frValidationMessages.messages.phoneNumber = () => i18n.t('error.invalid_phone_number', 'fr_FR')

  return {
    // https://github.com/ratiw/vuetable-2/issues/60
    fieldsBagName: 'veeFields',
    i18nRootKey: 'validations',
    i18n,
    dictionary: {
      en_US: enValidationMessages,
      fr_FR: frValidationMessages
    }
  }
}

export { VeeValidate, getVeeValidateInitObject }
