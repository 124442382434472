import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import permission from './modules/permission'
import admin from './modules/admin'
import master from './modules/master'
import getters from './getters'

// polyfill for NodeList.prototype.forEach
if ('NodeList' in window && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window
    for (let index = 0; index < this.length; index++) {
      callback.call(thisArg, this[index], index, this)
    }
  }
}

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    user,
    permission,
    admin,
    master,
    strict: !import.meta.env.PROD
  },
  getters
})

// @TODO https://vuex.vuejs.org/guide/hot-reload.html

export default store
