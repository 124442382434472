import i18n from '../lang/i18n'

// Config
import imageConfig from '@/config/image'
import nlpConfig from '@/config/nlp'
import constants from '@/config/constants'
import platformSettingsConfig from '@/config/platformSettings'

// Utils
import { getFileUrl } from '@/utils/image'
import { normalizeLowerCase } from '@/utils/text'

/**
 * Get props in the assistant KB KBName for the given locale
 * @param {Object} assistant
 * @param {String} KBName name of the KB
 * @param {String} locale
 * @param {String} property name of the requested property
 * @param {String|null} defaultValue will replace the assistant name if not value set
 * @return {String|undefined|null}
 */
export function getLocalizedKB(assistant, KBName, locale, property, defaultValue = null) {
  if (!assistant[KBName]) {
    return defaultValue
  }

  return (
    getLocalizedKBProperty(assistant[KBName], locale, property) ||
    // If localized property is not defined, let's fallback on the default locale
    getAnotherLocalizedKBProperty(assistant[KBName], Object.keys(assistant[KBName]), property) ||
    // If no localized property at all, let's fallback on the default value
    defaultValue
  )
}

/**
 * Get Localized KB property from assistantKB
 * @param {Object} assistantKB
 * @param {String} locale
 * @param {String} property
 * @return {String|undefined} return the searched property or undefined if it is not defined
 */
export function getLocalizedKBProperty(assistantKB, locale, property) {
  return assistantKB[locale] && assistantKB[locale][property]
}

/**
 * Get the first localized property found in assistant locales KB
 * @param {Object} assistantKB
 * @param {Array} locales
 * @param {String} property
 * @return {String|undefined|null} return the searched property or undefined if it is not defined or null if no locale
 */
export function getAnotherLocalizedKBProperty(assistantKB, locales, property) {
  const locale = locales.find(assistantLocale => {
    return getLocalizedKBProperty(assistantKB, assistantLocale, property)
  })

  return locale ? getLocalizedKBProperty(assistantKB, locale, property) : null
}

/**
 * Get the assistant-name in the assistant identity setting for the given locale
 * @param {Object} assistant
 * @param {String} locale
 * @param {String|null} defaultValue will replace the assistant name if not value set
 * @return {String|undefined|null}
 */
export function getAssistantLocalizedName(assistant, locale, defaultValue = null) {
  defaultValue =
    defaultValue ||
    i18n.t('missing_assistant_name', {
      slug: assistant.slug,
      locale: locale
    })

  if (!assistant.settings) {
    return defaultValue
  }

  return getLocalizedIdentitySettingProperty(assistant.settings, 'assistant-name', locale) || defaultValue
}

/**
 * Return localized identity assistant settings
 * @param {Array<Object>} assistantSettings - Array of assistant setting objects
 * @param {String} locale
 * @returns {Object} assistant setting object {assistant-name, image, ... } or null
 */
export function getLocalizedIdentitySetting(assistantSettings, locale) {
  if (!assistantSettings) {
    return false
  }

  const identitySetting = assistantSettings.find(setting => {
    return setting.locale === locale && Object.prototype.hasOwnProperty.call(setting, 'identity')
  })

  return identitySetting && identitySetting.identity
}

/**
 * Return localized identity assistant settings property
 * @param {Array<Object>} assistantSettings - Array of assistant setting objects
 * @param {String} locale
 * @param {String} propertyName
 * @returns {} assistant identity setting property or null
 */
export function getLocalizedIdentitySettingProperty(assistantSettings, propertyName, locale) {
  const identitySetting = getLocalizedIdentitySetting(assistantSettings, locale)

  return identitySetting && identitySetting[propertyName]
}

/**
 * Get the image URL property in the assistant identity settings for the given locale
 * @param {Object} assistant
 * @param {String} locale
 * @returns {String} assistant image url
 */
export function getLocalizedAvatarURL(assistant, locale) {
  const identitySetting = getLocalizedIdentitySetting(assistant.settings, locale)
  const assistantImage = (identitySetting && identitySetting.image) || {
    url: getFileUrl(imageConfig.DEFAULT_ASSISTANT_IMAGE_PATH)
  }

  return assistantImage.url
}

/**
 * Get propertyName assistant setting from assistant settings list
 * @param {Array<Object>} settings
 * @param {String} settings.0[propertyName]
 * @param {String} settings.0.id id
 * @param {String} propertyName the search property
 * @return {Object} setting
 */
export function getUnlocalizedSpecificSetting(settings, propertyName) {
  return (
    settings &&
    settings.find(setting => {
      return (
        setting &&
        setting.id &&
        !setting.locale &&
        !setting.platformType &&
        Object.prototype.hasOwnProperty.call(setting, propertyName)
      )
    })
  )
}

/**
 * Get propertyName assistant setting from localized assistant settings list
 * @param {Array<Object>} settings
 * @param {String} settings.0[propertyName]
 * @param {String} settings.0.id id
 * @param {String} locale
 * @param {String} propertyName the search property
 * @param {Object} defaultSetting - optional
 * @return {Object} setting
 */
export function getLocalizedSpecificSetting(settings, locale, propertyName, defaultSetting = null) {
  const setting =
    settings &&
    settings.find(setting => {
      return (
        setting &&
        setting.locale === locale &&
        !setting.platformType &&
        Object.prototype.hasOwnProperty.call(setting, propertyName)
      )
    })

  return setting ? JSON.parse(JSON.stringify(setting)) : defaultSetting
}

/**
 * Get Dialogflow agent link from project id
 * @param {String} projectId
 * @param {String|null} defaultLink
 * @returns {String|null} dialogflow link
 */
export function getDialogflowLink(projectId, defaultLink = null) {
  return projectId ? `${nlpConfig.DIALOGFLOW_AGENT_BASE_URL}/${projectId}/intents` : defaultLink
}

/**
 * Get the display id (shorten id) from an assistant id
 *
 * The aim of the display id is to have a shorter assistant id for the app URLs
 * so they will be more readable
 *
 * We want to keep the end of the assistant id because of how mongodb format the ObjectId
 * ObjectIds are segmented in 3 blocks = 4 bytes + 5 bytes + 3 bytes
 * (Check the documentation for more details about these blocks: https://docs.mongodb.com/manual/reference/method/ObjectId/)
 * The 3 last bytes are incremented, based on a random initial value
 *
 * Therefore we decided to keep the last 5 characters of an assistant id for the display id
 *
 * It also implies that to validate a route and find an assistant from the route params,
 * we should check both the displayId and the assistantSlug to be sure that we return the data of the correct assistant
 *
 * E.g. 5b7edb2b1060312cfeaa7932 => a7932
 *
 * @param {String} assistantId
 * @returns {String} display id
 */
export function getDisplayId(assistantId) {
  return assistantId && assistantId.slice(-constants.ASSISTANT_DISPLAY_ID_LENGTH)
}

/**
 * Format the assistant path thanks to the assistant data (_id & slug) and the following path
 * @param {Object} assistantObject
 * @param {String} assistantObject._id
 * @param {String} assistantObject.slug
 * @param {String} path
 * @returns {String} formatted path
 */
export function formatAssistantPath(assistantObject, path = '') {
  return `/${getDisplayId(assistantObject._id)}/${assistantObject.slug}${path}`
}

/**
 * Find an assistant from the displayId and assistantSlug in the route params
 * @param {Array<Object>} assistants
 * @param {Object} params from the route
 * @param {String} [params.displayId]
 * @param {String} [params.assistantSlug]
 * @returns {Object} assistant found
 */
export function getAssistantFromRouteParams(assistants, params = {}) {
  if (!params.displayId || !assistants.length) {
    return null
  }

  return assistants.find(assistant => checkIfRouteParamsMatchAssistant(assistant, params))
}

/**
 * Check if an assistant matches with the displayId and the assistantSlug from a route
 * @param {Object} assistant
 * @param {String} assistant._id
 * @param {String} assistant.slug
 * @param {Object} params
 * @param {String} [params.displayId]
 * @param {String} [params.assistantSlug]
 * @returns {Boolean}
 */
export function checkIfRouteParamsMatchAssistant(assistant, params) {
  return params && getDisplayId(assistant._id) === params.displayId && assistant.slug === params.assistantSlug
}

/**
 * Get view path based on super admin view
 * @param {Object} selectedAssistant
 * @param {String} selectedAssistant._id
 * @param {String} selectedAssistant.slug
 * @param {Boolean} isSuperAdminView
 * @param {Object} config
 * @returns {String}
 */
export function getViewPath(selectedAssistant, isSuperAdminView, config) {
  const masterPath = config.MASTER_FRONT_PATH || config.MASTER_PATH
  return isSuperAdminView ? masterPath : `${formatAssistantPath(selectedAssistant, config.ASSISTANT_COMMON_PATH)}`
}

/**
 * Get assistant localized platform settings
 * @param {Array<Object>} settings
 * @param {String} platformName
 * @param {String | null} platformLocale
 * @return {Object} localized platform settings
 */
export function getAssistantPlatformSettings(settings, platformName, platformLocale) {
  return settings.find(
    setting => setting.platformType === platformName && (!setting.locale || setting.locale === platformLocale)
  )
}

/**
 * Get platform activation status
 * @param {Array<Object>} settings
 * @param {String} platformName
 * @param {String|null} platformLocale
 * @return {Boolean} activation status
 */
export function getPlatformActivatedStatus(settings, platformName, platformLocale = null) {
  if (constants.DEFAULT_ACTIVATED_PLATFORMS.includes(platformName)) {
    return true
  }

  const platformSettings = getAssistantPlatformSettings(settings, platformName, platformLocale)
  if (platformSettings && Object.prototype.hasOwnProperty.call(platformSettings, 'isActivated')) {
    return platformSettings.isActivated
  }

  if (platformName === constants.PLATFORMS.website.name) {
    return platformSettingsConfig.WEBSITE_SETTINGS.isActivated
  }

  return false
}

/**
 * Search an assistant in a list
 * @param {Array<Object>} assistantsList
 * @param {String} assistantSearched
 * @param {String} locale
 * @returns {Array<String>} filtered list
 */
export function searchAssistant(assistantsList, assistantSearched, locale) {
  const normalizedSearch = normalizeLowerCase(assistantSearched)
  return assistantsList.filter(item => {
    const assistantName = getAssistantLocalizedName(item, locale, item.slug)
    const normalizedAssistantName = normalizeLowerCase(assistantName)
    return (
      normalizedAssistantName.includes(normalizedSearch) ||
      (item.slug && item.slug.includes(normalizedSearch)) ||
      (item._id && item._id.includes(normalizedSearch))
    )
  })
}

/**
 * Check if platform is activated in assistant settings
 * @param {Array<Object>} settings - assistant settings [{ platformType, isActivated }]
 * @param {String} platform - platform name we want to check
 * @return {Boolean}
 */
export function isActivatedPlatform(settings, platform) {
  return settings.some(setting => platform === setting.platformType && setting.isActivated)
}

/**
 * Get assistant activated settings
 * @param {Array<Object>} settings
 * @param {String} locale
 * @param {String} propertyName
 * @returns {Boolean}
 */
export function getActivatedSetting(settings, locale, propertyName) {
  const setting = getLocalizedSpecificSetting(settings, locale, propertyName)

  return setting && setting[propertyName].isActivated
}
