import { formatAssistantPath } from './assistant'
import { hasLiveChatAgentRole, hasAdminRole, hasInsightsManagerRole } from './validate'

/**
 * check if data is saved before leaving the page (if not saved it will display the confirmation modal)
 * @param {Object} to destination of the routing
 * @param {Function} next function to continue the routing
 * @param {Object} store vue store
 */
export function checkIfDataSavedBeforeRouteLeave(to, next, store) {
  if (!store.getters.isSaved) {
    // Only if no pendingRoute, to avoid overwritten existing pushed one
    if (!store.getters.pendingRoute) {
      store.commit('SET_PENDING_ROUTE', to.path)
    }
    store.commit('SET_SHOW_MODAL', true)
  } else {
    store.commit('SET_SHOW_MODAL', false)
    next()
  }
}

/**
 * push current pending route if any, close modal and set saved to true
 * @param {Object} router vuex router
 * @param {Object} store vue store
 */
export function pushPendingRoute(router, store) {
  let pendingRoute = store.state.app.pendingRoute
  if (pendingRoute) {
    store.commit('SET_IS_SAVED', true)

    if (typeof pendingRoute === 'string') {
      pendingRoute = {
        path: pendingRoute
      }
    } else {
      pendingRoute = {
        ...(pendingRoute.path && {
          path: pendingRoute.path
        }),
        ...(pendingRoute.name && {
          name: pendingRoute.name
        }),
        ...(pendingRoute.params && {
          params: pendingRoute.params
        })
      }
    }

    router.push(pendingRoute)
  }
}

/**
 * Get the default assistant base path from the extracted parameters or the URL
 * It will return null if we can't extract it
 * @param {Object} extractedParameters
 * @param {String} extractedParameters.displayId
 * @param {String} extractedParameters.assistantSlug
 * @param {Object} extractedParameters.assistant
 * @param {String} path expected format: /{displayId}/{assistantSlug}/remaining/path/elements
 * @returns {String|null}
 */
export function getBaseRedirectionURL({ displayId, assistantSlug, assistant }, path = '') {
  if (displayId && assistantSlug) {
    return `/${displayId}/${assistantSlug}`
  }

  if (assistant) {
    return formatAssistantPath(assistant)
  }

  // splittedURL = ['', displayId, assistantSlug, 'remaining', ...]
  const splittedURL = path.split('/')
  if (splittedURL.length >= 3) {
    // '/{displayId}/{assistantSlug}'
    return `/${splittedURL.slice(1, 3).join('/')}`
  }

  return null
}

/**
 * Get the default assistant path depending on the user roles
 * It will return null if the user does not have any role that require a specific path
 * @param {Array<String>} roles
 * @returns {String|null}
 */
export function getDefaultAssistantPath(roles) {
  if (hasAdminRole(roles) || hasInsightsManagerRole(roles)) {
    return '/dashboard'
  } else if (hasLiveChatAgentRole(roles)) {
    return '/liveConversations'
  }

  return null
}

/**
 * Get the default path for a logged in user, bases on the user roles
 * @param {String} baseRedirectionURL
 * @param {Array<String>} roles
 * @returns {String}
 */
export function getDefaultLoggedInPath(baseRedirectionURL, roles) {
  const path = getDefaultAssistantPath(roles)

  if (!path || !baseRedirectionURL) {
    return '/apps/list'
  }

  return `${baseRedirectionURL}${path}`
}

/**
 * Is a request error the 403 HTTP error
 * @param {Object} error
 * @param {Number} [error.code]
 * @param {Object} [error.response]
 * @param {Number} [error.response.status]
 * @returns {Boolean}
 */
export function isForbiddenError(error) {
  return error.code === 40300 || (error.response && error.response.status === 403)
}
