import Cookies from 'js-cookie'
import constants from '@/config/constants'

/**
 * Set pending* state properties to null and showModal to false
 * @param {Object} state
 */
const resetPendingState = function (state) {
  state.pendingRoute = null
  state.pendingLocale = null
}
const app = {
  state: {
    sidebar: {
      opened: !+Cookies.get('sidebarStatus'),
      withoutAnimation: false
    },
    env: !import.meta.env.PROD ? 'dev' : import.meta.env.VITE_NODE_ENV,
    yeldaOrWhiteBrandName: import.meta.env.VITE_WHITE_BRAND_NAME || 'Yelda',
    device: 'desktop',
    TTSVoice: '',
    TTSService: '',
    isAudioActivated: Cookies.get('isAudioActivated') ? Cookies.get('isAudioActivated') === 'true' : true,
    defaultTimezone: '',
    envColor: null,
    isSuperAdminView: false,
    interfaceAvailableLocales: constants.INTERFACE_AVAILABLE_LOCALES,
    platformsAvailableLocales: constants.PLATFORMS_AVAILABLE_LOCALES,
    pendingLocale: null,
    isSaved: true,
    showModal: false,
    pendingRoute: null,
    filters: {},
    nbActiveLiveChatConversations: 0,
    isWebSocketConnected: false,
    liveChatAgents: [],
    usersListInfo: {
      users: [],
      platformFilters: [],
      totalItemsNumber: 0
    }
  },
  mutations: {
    TOGGLE_SIDEBAR: state => {
      if (state.sidebar.opened) {
        Cookies.set('sidebarStatus', 1)
      } else {
        Cookies.set('sidebarStatus', 0)
      }
      state.sidebar.opened = !state.sidebar.opened
      state.sidebar.withoutAnimation = false
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      Cookies.set('sidebarStatus', 1)
      state.sidebar.opened = false
      state.sidebar.withoutAnimation = withoutAnimation
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    },
    SET_ADMIN_VIEW: (state, isSuperAdminView = false) => {
      state.isSuperAdminView = isSuperAdminView === true // force any other value to false
    },
    SET_ENV_COLOR: (state, envColor) => {
      state.envColor = envColor
    },
    SET_VOICE: (state, voice) => {
      state.TTSVoice = voice
    },
    SET_VOICE_SERVICE: (state, voiceService) => {
      state.TTSService = voiceService
    },
    TOGGLE_AUDIO: state => {
      state.isAudioActivated = !state.isAudioActivated
      Cookies.set('isAudioActivated', state.isAudioActivated)
    },
    SET_DEFAULT_TIMEZONE: (state, timezone) => {
      state.defaultTimezone = timezone
    },
    SET_INTERFACE_AVAILABLE_LOCALES: (state, locales) => {
      if (locales && Array.isArray(locales)) {
        state.interfaceAvailableLocales = locales
      }
    },
    SET_PLATFORMS_AVAILABLE_LOCALES: (state, locales) => {
      state.platformsAvailableLocales = locales
    },
    SET_PENDING_LOCALE_MODAL: (state, locale) => {
      state.showModal = true
      state.pendingLocale = locale
    },
    UNSET_PENDING_LOCALE_MODAL: state => {
      state.isSaved = true
      resetPendingState(state)
    },
    SET_IS_SAVED: (state, isSaved) => {
      state.isSaved = isSaved
    },
    SET_SHOW_MODAL: (state, showModal) => {
      state.showModal = showModal
    },
    RESET_PENDING_STATE: state => {
      resetPendingState(state)
    },
    SET_PENDING_ROUTE: (state, route) => {
      state.pendingRoute = route
    },
    SET_FILTERS: (state, { filterName, filterValue, page }) => {
      // Check for mandatory values. filterValue can be null or whatever value
      if (!page || !filterName) {
        return
      }
      // Create page settings if needed
      if (!state.filters[page]) {
        state.filters[page] = {}
      }

      // Assign page filter value
      state.filters[page][filterName] = filterValue
    },
    SET_NB_LIVE_CHAT_CONVERSATIONS: (state, nbActiveLiveChatConversations) => {
      state.nbActiveLiveChatConversations = nbActiveLiveChatConversations
    },
    SET_WEB_SOCKET_CONNECTED: (state, isConnected) => {
      state.isWebSocketConnected = isConnected
    },
    SET_ASSISTANT_LIVE_CHAT_AGENT: (state, liveChatAgents) => {
      state.liveChatAgents = liveChatAgents
    },
    SET_USERS_LIST_INFO: (state, { users, platformFilters, totalItemsNumber }) => {
      state.usersListInfo = {
        users,
        platformFilters,
        totalItemsNumber
      }
    }
  },
  actions: {
    ToggleSideBar: ({ commit }) => {
      commit('TOGGLE_SIDEBAR')
    },
    CloseSideBar({ commit }, { withoutAnimation }) {
      commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    ToggleDevice({ commit }, device) {
      commit('TOGGLE_DEVICE', device)
    },
    SetVoice({ commit }, { voice, voiceService }) {
      commit('SET_VOICE', voice)
      commit('SET_VOICE_SERVICE', voiceService)
    },
    ToggleAudio({ commit }) {
      commit('TOGGLE_AUDIO')
    },
    SetEnvColor({ commit }, color) {
      commit('SET_ENV_COLOR', color)
    },
    SetRegionInfo({ commit }, regionInfo) {
      commit('SET_DEFAULT_TIMEZONE', regionInfo.defaultTimezone)
      commit('SET_INTERFACE_AVAILABLE_LOCALES', regionInfo.interfaceAvailableLocales || [])
      commit('SET_PLATFORMS_AVAILABLE_LOCALES', regionInfo.platformsAvailableLocales || [])
    },
    SetFilters({ commit }, filterDetails) {
      commit('SET_FILTERS', filterDetails)
    },
    SetNbLiveChatConversations({ commit }, nbActiveLiveChatConversations) {
      commit('SET_NB_LIVE_CHAT_CONVERSATIONS', nbActiveLiveChatConversations)
    },
    SetWebSocketConnected({ commit }, isConnected) {
      commit('SET_WEB_SOCKET_CONNECTED', isConnected)
    },
    SetAssistantLiveChatAgents({ commit }, liveChatAgents) {
      commit('SET_ASSISTANT_LIVE_CHAT_AGENT', liveChatAgents)
    },
    SetUsersListInfo({ commit }, data) {
      commit('SET_USERS_LIST_INFO', data)
    }
  }
}

export default app
