'use strict'

import constants from '@/config/constants'
import adminConfig from '@/config/admin'

// @TODO : email regex
export function isvalidUsername(str) {
  return str.trim().length >= 4
}

export function isvalidPassword(str) {
  return str.trim().length >= constants.LOGIN.PASSWORD_MIN_LENGTH
}

export function validateLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

export function validateUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

export function validatAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/**
 * Check if the admin has a specific role
 * @param {Array<String>} adminRoles list of the admin roles
 * @param {String} role role to check
 * @returns {Boolean}
 */
function hasRole(adminRoles, role) {
  return adminRoles && adminRoles.length ? adminRoles.includes(role) : false
}

/**
 * Return if user has superAdmin role
 * @param {Array<String>} adminRoles
 * @returns {Boolean}
 */
export function hasSuperAdminRole(adminRoles) {
  return hasRole(adminRoles, adminConfig.ADMIN_ROLES.SUPER_ADMIN)
}

/**
 * Return if user has admin role
 * @param {Array<String>} adminRoles
 * @returns {Boolean}
 */
export function hasAdminRole(adminRoles) {
  return hasRole(adminRoles, adminConfig.ADMIN_ROLES.BASIC_ADMIN)
}

/**
 * Return if user has insights manager role
 * @param {Array<String>} roles
 * @returns {Boolean}
 */
export function hasInsightsManagerRole(roles) {
  return hasRole(roles, adminConfig.ADMIN_ROLES.INSIGHTS_MANAGER)
}

/**
 * Return if user has admin role
 * @param {Array<String>} adminRoles
 * @returns {Boolean}
 */
export function hasLiveChatAgentRole(adminRoles) {
  return hasRole(adminRoles, adminConfig.ADMIN_ROLES.LIVE_CHAT_AGENT)
}
