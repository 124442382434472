/**
 * Config
 */
import mediaConfig from '@/config/media'

/**
 * Parse query from URL
 * @param {String} url
 * @returns {Object} { url: '', query: { key: value }}
 */
export const parseQueryFromUrl = url => {
  const urlObj = new URL(url)
  const query = {}
  for (const [key, value] of urlObj.searchParams) {
    query[key] = value
  }
  return {
    url: urlObj.origin + urlObj.pathname,
    query
  }
}

/**
 * Return a URL encoded query string from a parameters object list
 * It removes empty and null values from the returned parameters string
 *
 * @param {Object} queryParameters - Query GET parameters {param1: "value1", param2: "M&Ms", param3: ''}
 * @returns {String} - Query string "?param1=value1&param2=M%26Ms"
 */
export function getQueryString(queryParameters) {
  const keys = Object.keys(queryParameters).filter(key => queryParameters[key] !== '' && queryParameters[key] !== null)

  if (!keys.length) {
    return ''
  }

  const encodedParameters = keys.map(key => [key, queryParameters[key]].map(encodeURIComponent).join('='))
  return '?' + encodedParameters.join('&')
}

/**
 * Returns a resolved promise if the media can be loaded, else a rejected promise
 * @param {String} url
 * @param {String} mediaType "video" or "image", which is used to create an HTML element to load the media
 * @returns {Promise}
 */
export function isURLResponseCodeValid(url, mediaType) {
  return new Promise((resolve, reject) => {
    try {
      let element = null

      switch (mediaType) {
        case mediaConfig.VIDEO.VALUE:
          element = document.createElement('video')
          element.onloadeddata = resolve
          break
        case mediaConfig.IMAGE.VALUE:
          element = new Image()
          element.onload = resolve
          break
        default:
          reject()
      }

      element.onerror = reject
      element.src = url
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

/**
 * Finds the given param in query string, if not found retuns default value
 * @param {String} param
 * @param {<any>} defaultValue
 * @returns {String}
 */
export function getQueryStringParam(param, defaultValue = null) {
  param = param.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp(param + '=([^&#]*)')
  const results = regex.exec(window.location.search)
  return results === null ? defaultValue : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

/**
 * Check the provided url is valid
 * @param {String} url
 * @returns {Boolean}
 */
export function isValidUrl(url) {
  const regex =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
  return regex.test(url)
}

/**
 * Get assistant path
 * @param {String} assistantId
 * @returns {String}
 */
export function getAssistantPath(assistantId) {
  return assistantId ? `/assistants/${assistantId}` : ''
}
