import request from '@/utils/request'
import { getQueryString } from '@/utils/url'

/**
 *  Update master action
 *
 * @param {String} masterActionId - Master Action Id
 * @param {Object} action - action settings
 * @param {String} email - admin email
 * @return {Promise<Void>}
 */
function update(masterActionId, action, email) {
  // if no masterActionId, it means we are creating a new action
  masterActionId = masterActionId ? `/${masterActionId}` : ''
  return request({
    url: `/masterActions${masterActionId}`,
    method: 'post',
    data: {
      action,
      email
    },
    shouldSetDefaultError: true
  })
}

/**
 *  Delete action
 *
 * @param {String} masterActionId - Master Action Id
 * @param {String} email - admin email
 * @return {Promise<Void>}
 */
function deleteById(masterActionId, email) {
  return request({
    url: `/masterActions/${masterActionId}`,
    method: 'delete',
    data: {
      email
    },
    shouldSetDefaultError: true
  })
}

/**
 *  Return paginated master actions list
 *
 * @param {Object} filters
 * @param {String} filters.locale
 * @param {Number} filters.page - Current Page
 * @param {Number} filters.limit - element per page
 * @param {String} filters.action - action searched
 * @param {String} filters.industry.id - Specific industry
 * @param {Boolean} filters.industry.all - All industry
 * @param {Boolean} filters.industry.noIndustry - Core industry
 * @param {String} filters.categoryId
 * @param {Boolean} filters.noCategory
 * @return {Promise<Array>}
 */
function getPaginated(filters = {}) {
  return new Promise(resolve => {
    request({
      url: `/masterActions${getQueryString(filters)}`,
      method: 'get'
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        console.error('error getPaginated MasterActions', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}

/**
 * Get all the master actions
 */
function getAll() {
  return new Promise(resolve => {
    request({
      url: '/masterActions/all',
      method: 'get'
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.error('error getAll MasterActions', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}

/**
 *  Return all the master actions
 *
 * @return {Promise<Object>}
 */
function getSlugs() {
  return new Promise(resolve => {
    request({
      url: '/masterActions/all/slugs',
      method: 'get'
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        console.error('error getSlugs MasterActions', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}

/**
 *  Return a specific master action or null

 * @param {String} actionSlug - slug of the action
 * @return {Promise<Object>}
 */
function get(actionSlug) {
  return new Promise(resolve => {
    request({
      url: `/masterActions?slug=${actionSlug}`,
      method: 'get'
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        console.error('error get masterAction by slug', err)
        resolve({
          code: 50000,
          data: {},
          err: err
        })
      })
  })
}

export default {
  update,
  deleteById,
  get,
  getAll,
  getSlugs,
  getPaginated
}
