<i18n src="./../lang/fr.json"></i18n>
<i18n src="./../lang/en.json"></i18n>
<template>
  <!-- BEGIN: Topbar -->
  <div id="m_header_topbar" class="m-topbar m-stack m-stack--ver m-stack--general m-stack--fluid">
    <div class="m-stack__item m-topbar__nav-wrapper">
      <ul class="m-topbar__nav m-nav m-nav--inline">
        <li
          v-if="isTopMenuAppContentVisible"
          class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light webchat-button-container"
          m-dropdown-toggle="click"
        >
          <div v-if="$store.state.user.selectedAssistant" class="m-nav__link m-dropdown__toggle mt-2">
            <div class="btn-group btn-group" role="group" aria-label="...">
              <button
                type="button"
                class="btn btn-primary m-btn m-btn--icon webchat-button"
                :class="{ webchatButtonActive: isWebchatActive }"
                @click.prevent="openWebchat"
              >
                <span>
                  <i class="fa fa-play"></i>
                  <i class="fa fa-stop"></i>
                  <span>{{ $t('navbar.webchat_button', { name: assistantName }) }}</span>
                </span>
              </button>
              <button
                type="button"
                class="btn btn-success m-btn m-btn--icon btn-external"
                @click.prevent="goToYeldaHostedPage"
              >
                <i class="fa fa-external-link-alt text-white"></i>
              </button>
            </div>
          </div>
        </li>
        <li
          ref="profileDropdown"
          class="m-nav__item m-topbar__user-profile m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light m-dropdown--open"
        >
          <a href="#" class="m-nav__link m-dropdown__toggle" @click.prevent="toggleProfile">
            <span class="m-topbar__user-name">
              {{ $store.state.user.firstName }} {{ $store.state.user.lastName.slice(0, 1) }}.
              <i class="fas fa-caret-down pl-2 text-white"></i>
            </span>
          </a>
          <div v-show="profileMenuDisplay" class="m-dropdown__wrapper" style="z-index: 101">
            <span
              class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"
              style="left: auto; right: 12.5px"
            />
            <div class="m-dropdown__inner">
              <div class="m-dropdown__header m--align-center">
                <div class="m-card-user m-card-user--skin-dark">
                  <div class="m-card-user__details">
                    <span class="m-card-user__name m--font-weight-500">
                      {{ $store.state.user.firstName }} {{ $store.state.user.lastName }}
                    </span>
                    <span class="m-card-user__email m--font-weight-300">{{ $store.state.user.email }}</span>
                  </div>
                </div>
              </div>
              <div class="m-dropdown__body">
                <div class="m-dropdown__content">
                  <ul class="m-nav m-nav--skin-light">
                    <li class="m-nav__section m--hide">
                      <span class="m-nav__section-text">{{ capitalize($t('navbar.section')) }}</span>
                    </li>
                    <li class="m-nav__item">
                      <a href class="m-nav__link" @click.prevent="redirect('/profile')">
                        <i class="m-nav__link-icon flaticon-profile-1" />
                        <span class="m-nav__link-title">
                          <span class="m-nav__link-wrap">
                            <span class="m-nav__link-text">{{ capitalize($t('navbar.preferences')) }}</span>
                          </span>
                        </span>
                      </a>
                    </li>
                    <li class="m-nav__item">
                      <a href="https://support.yelda.ai" class="m-nav__link" target="_blank">
                        <i class="m-nav__link-icon flaticon-lifebuoy" />
                        <span class="m-nav__link-text">{{ capitalize($t('navbar.support')) }}</span>
                      </a>
                    </li>
                    <li class="m-nav__separator m-nav__separator--fit" />
                    <li class="m-nav__item">
                      <a
                        class="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
                        @click.prevent="logout"
                      >
                        {{ capitalize($t('log_out')) }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- END: Topbar -->
</template>

<script>
// Libs
import { capitalize } from '@/utils/text'

// Dependencies
import { mapGetters } from 'vuex'
import Bus from '@/Bus'

// Config
import platformConfig from '@/config/platformSettings'

export default {
  name: 'ProfileMenu',
  props: {
    isTopMenuAppContentVisible: {
      default: true,
      type: Boolean
    }
  },
  data: function () {
    return {
      profileMenuDisplay: false,
      isWebchatActive: false
    }
  },
  computed: {
    ...mapGetters(['assistantName', 'selectedAssistant', 'selectedAssistantLocale'])
  },
  created() {
    document.addEventListener('click', this.documentClick)
  },
  destroyed() {
    document.removeEventListener('click', this.documentClick)
  },
  methods: {
    capitalize,
    documentClick(event) {
      // close dropdown when we click outside the list
      const dropdown = this.$refs.profileDropdown
      const target = event.target
      if (dropdown && dropdown !== target && !dropdown.contains(target)) {
        this.profileMenuDisplay = false
      }
    },
    logout() {
      this.$store.dispatch('LogOut')
    },
    redirect(route) {
      this.$router.push({ path: route })
      this.profileMenuDisplay = false
    },
    toggleProfile() {
      this.profileMenuDisplay = !this.profileMenuDisplay
    },
    openWebchat() {
      this.isWebchatActive = !this.isWebchatActive
      Bus.$emit('openWebchat')
    },
    goToYeldaHostedPage() {
      window.open(
        `${platformConfig.YELDA_HOSTING_BASE_URL}/${this.selectedAssistant.id}/${this.selectedAssistant.slug}?locale=${this.selectedAssistantLocale}`,
        '_blank'
      )
    }
  }
}
</script>
<style scoped>
.fa-stop {
  display: none;
}
.webchatButtonActive .fa-play {
  display: none;
}

.webchatButtonActive .fa-stop {
  display: table-cell;
}
.m-dropdown.m-dropdown--header-bg-fill .m-dropdown__header {
  background-color: #2a67da;
}
.m-topbar
  .m-topbar__nav.m-nav
  > .m-nav__item.m-topbar__user-profile.m-topbar__user-profile--img.m-dropdown--arrow
  .m-dropdown__arrow {
  color: #2a67da;
}
.m-card-user.m-card-user--skin-dark .m-card-user__details .m-card-user__name {
  color: white;
}

.m-card-user.m-card-user--skin-dark .m-card-user__details .m-card-user__email,
.m-card-user.m-card-user--skin-dark .m-card-user__details .m-card-user__email:hover {
  color: #cbccd8;
}

.m-nav__link.m-dropdown__toggle .btn-primary {
  background: #1042a0;
  border-color: #1042a0;
}

.m-topbar__user-name {
  display: table-cell;
  vertical-align: middle;
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  color: #fff;
  padding: 2px 0 2px 15px;
}

.btn-success {
  background-color: #1e9a81;
  border-color: #1e9a81;
}
.btn-success:hover {
  background-color: #2ca189;
  border-color: #299781;
}
.btn-external i {
  vertical-align: 0px;
}
</style>
