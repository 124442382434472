import request from '@/utils/request'

/**
 * Create an admin
 * @param {Object} admin admin to create
 * @returns {Promise<Object>}
 */
export function createAdmin(admin) {
  return request({
    url: '/admins',
    method: 'post',
    data: {
      admin: admin
    },
    shouldSetDefaultError: true
  })
}

/**
 * Deactivate an admin
 * @param {String} adminId id of the admin to delete
 * @returns {Promise<Object>}
 */
export function deactivateAdmin(adminId) {
  return request({
    url: `/admins/${adminId}`,
    method: 'delete',
    shouldSetDefaultError: true
  })
}

/**
 * Get and admin details
 * @param {String} adminId id of the admin
 * @returns {Promise<Object>}
 */
export function getAdminDetails(adminId) {
  return request({
    url: `/admins/${adminId}`,
    method: 'get',
    shouldSetDefaultError: true
  })
}

/**
 * Get the list of admins
 * @returns {Promise<Object>}
 */
export function getAdminsList() {
  return request({
    url: '/admins',
    method: 'get',
    shouldSetDefaultError: true
  })
}

/**
 * Get the list of this assistant admins
 * @param {String} assistantId id of the assistant
 * @returns {Promise<Object>}
 */
export function getAssistantAdminsList(assistantId) {
  return request({
    url: `/assistants/${assistantId}/admins`,
    method: 'get',
    shouldSetDefaultError: true
  })
}

/**
 * Get the admin corresponding to this email
 * @param {String} email email of the assistant
 * @returns {Promise<Object>}
 */
export function getAdminFromEmail(email) {
  return request({
    url: `/admins/?email=${encodeURIComponent(email)}`,
    method: 'get',
    shouldSetDefaultError: true
  })
}

/**
 * Update an admin
 * @param {Object} admin admin to update
 * @param {String} adminId id of the admin
 * @returns {Promise<Object>}
 */
export function updateAdmin(admin, adminId) {
  return request({
    url: `/admins/${adminId}`,
    method: 'post',
    data: {
      admin: admin
    },
    shouldSetDefaultError: true
  })
}
/**
 * Update admin preferences locale and timezone
 * @param {Object} preferences preferences to update { locale, timezone }
 *    @param {String} preferences.locale
 *    @param {String} preferences.timezone
 * @param {String} adminId id of the admin
 * @returns {Promise<Object>}
 */
export function updateAdminPreferences(preferences, adminId) {
  return request({
    url: `/admins/${adminId}/preferences`,
    method: 'put',
    data: preferences,
    shouldSetDefaultError: true
  })
}

/**
 * Update admin identity
 * @param {Object} identity information to update
 * @param {String} identity.firstName
 * @param {String} identity.lastName
 * @param {String} identity.email
 * @param {String} identity.avatar
 * @param {String} adminId id of the admin
 * @returns {Promise<Object>}
 */
export function updateAdminIdentity(identity, adminId) {
  return request({
    url: `/admins/${adminId}/identity`,
    method: 'put',
    data: identity,
    shouldSetDefaultError: true
  })
}

/**
 * Upsert invited admin and send invitation mail
 * @param {Object} admin invited admin to create or update
 *      {
 *        email: String,
 *        firstName: String,
 *        lastName: String,
 *        assistantIds: Array,
 *        locale: String,
 *        roles: Array
 *       }
 * @param {String} expeditorName name of the admin sending the invitation
 * @param {Object} assistant { id: ObjectId, slug: String, name: String }
 * @returns {Promise<Object>}
 */
export function upsertInvitedAdmin(admin, expeditorName, assistant) {
  return request({
    url: `/assistants/${assistant.id}/invitation`,
    method: 'post',
    data: { admin, expeditorName, assistant },
    shouldSetDefaultError: true
  })
}

/**
 * Re send invitation to an admin
 * @param {String} adminEmail email of the invited admin
 * @param {String} expeditorName name of the admin sending the invitation
 * @param {Object} assistant { id: ObjectId, slug: String, name: String }
 * @param {String} locale
 * @returns {Promise<Object>}
 */
export function resendInviteMailToAdmin(adminEmail, expeditorName, assistant, locale) {
  return request({
    url: `/assistants/${assistant.id}/invitation/resend`,
    method: 'post',
    data: { adminEmail, expeditorName, assistant, locale },
    shouldSetDefaultError: true
  })
}

/**
 * Update admin selected locale
 * @param {String} email admin email
 * @param {String} locale
 * @returns {Promise<Object>}
 */
export function updateAdminPlatformLocale(adminId, locale) {
  return request({
    url: `/admins/${adminId}/locale`,
    method: 'post',
    data: { locale },
    shouldSetDefaultError: true
  })
}
