import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

// Libs
import { getToken, removeTokens } from '@/utils/auth' // getToken from cookie
import { hasAdminRole, hasSuperAdminRole } from '@/utils/validate'
import { getAssistantFromRouteParams, getDisplayId } from '@/utils/assistant'
import { getBaseRedirectionURL, getDefaultLoggedInPath, isForbiddenError } from '@/utils/routing'

// Config
import configSlot from '@/config/slot'
import configRoutes from '@/config/routes'
import constants from '@/config/constants'

NProgress.configure({
  showSpinner: false
}) // NProgress Configuration

// no redirect whitelist
const whiteListPath = configRoutes.WHITE_LIST_PATH
const whiteListName = configRoutes.WHITE_LIST_NAME

// ROUTES REGEX :
// Some requested routes require access to some data, so we need to get those data with async call or redirect the user
// If areRoutesNew is true, not all the next() call will work, so we need final `next(updatedTo)`
// Login path
const LOGIN_DASHBOARD_REGEX = /^((\/\w+){0,2}(\/signin))$|^((\/\w+){0,2}(\/signup))$|^(\/)$|^(\/dashboard)$/
const LOGIN_RESET_REGEX = /^((\/\w+){0,2}(\/signin(\/reset)?))$|^(\/)$/
const LOGIN_RESET_DASHBOARD_REGEX = /^((\/\w+){0,2}(\/signin(\/reset)?))$|^(\/)$|^(\/dashboard)$/
const LOGIN_PATH = [LOGIN_DASHBOARD_REGEX, LOGIN_RESET_REGEX, LOGIN_RESET_DASHBOARD_REGEX]
// Simple login regex with no extra params: /signin or /signup
const LOGIN_REGEX = /^((\/signin)|(\/signup))$/

// Non assistant path : non "login" or "assistant specific" routes
const ASSISTANT_CREATION_REGEX = /^((\/\w+){0,2}(\/assistant\/creation))\/?$/
const ADMIN_REGEX = /^(\/admin(\/\w*)*)$/
const DEMO_REGEX = /^(\/demo(\/\w*)*)$/
const PROFILE_REGEX = /^(\/profile\/\w+)$/
const APP_LIST_REGEX = /^(\/apps\/list)$/
const NON_ASSISTANT_PATH = [ASSISTANT_CREATION_REGEX, ADMIN_REGEX, PROFILE_REGEX, APP_LIST_REGEX]

// Assistant path : we need to find have a selectedAssistant to access to these routes
// All the assistant routes /[displayId]/[assistantSlug]/path/path/path/path
// display Id is a shorten identifier extracted from the DB id of the assistant
const ASSISTANT_REGEX = /^(\/[a-f0-9]{5}\/\w+\/?)\w*\/?\w*\/?\w*\/?\w*$/
// All the assistant creation routes (include steps and get params)
const ASSISTANT_CREATION_NEXT_REGEX = /^(\/assistant\/creation\/\w+)\/?(\?.*)?$/
// Post signin/signup route
const TEMPLOG_REGEX = /^(\/\w+){0,2}\/templog$/
const NEED_ASSISTANT_PATH = [ASSISTANT_CREATION_NEXT_REGEX, ASSISTANT_REGEX, TEMPLOG_REGEX]

// 404 Yelda page
const NOTFOUND_REGEX = /^(\/\w+){0,2}\/404/

/**
 * Return store selectedAssistant if valid, else null
 * @param {String} displayId shorten id of the searched assistant
 * @param {String} assistantSlug from the url
 * @return {Object||null} selectedAssistant or null
 */
const getAssistant = (displayId, assistantSlug) => {
  const assistant = getAssistantFromRouteParams(store.getters.assistants, { displayId, assistantSlug })
  if (assistant) {
    return assistant
  }
  // Warning : default is empty object, not null or undefined
  return store.getters.selectedAssistant && store.getters.selectedAssistant.id ? store.getters.selectedAssistant : null
}

/**
 * Return email query if valid, else null
 * @param {Route} to the target Route Object being navigated to
 * @param {Boolean} canbeSameEmailTarget the query email dont need to be different from the getter email
 * @return {Object||null} email query to pass to the route or null
 */
const getEmailQuery = (to, canbeSameEmailTarget = false) => {
  return canbeSameEmailTarget || decodeURI(to.query.email) !== store.getters.email
    ? {
        email: to.query.email,
        ...((to.params.assistantSlug && {
          assistantSlug: to.params.assistantSlug
        }) ||
          (to.query.assistantSlug && {
            assistantSlug: to.query.assistantSlug
          })),
        ...((to.params.displayId && {
          displayId: to.params.displayId
        }) ||
          (to.query.displayId && {
            displayId: to.query.displayId
          }))
      }
    : null
}

/**
 * Return assistant path, or empty string
 * @param {Route} to the target Route Object being navigated to
 * @return {String} assistant route segment /displayId/assistantSlug
 */
const formatAssistantPathFromRoute = to => {
  let assistantPath = ''
  if (to.params.displayId) {
    assistantPath = `/${to.params.displayId}/${to.params.assistantSlug ? to.params.assistantSlug : 'slug'}`
  } else if (to.query.displayId) {
    assistantPath = `/${to.query.displayId}/${to.query.assistantSlug ? to.query.assistantSlug : 'slug'}`
  }

  return assistantPath
}

/**
 * Handle not logged in user global before guards
 * @param {Route} to the target Route Object being navigated to
 * @param {Route} from the current route being navigated away from
 * @param {Function} next this function must be called to resolve the hook. The action depends on the arguments provided to next
 */
const handleWhiteListedPath = (to, from, next) => {
  if (whiteListPath.indexOf(to.path) !== -1 || whiteListName.indexOf(to.name) !== -1) {
    // while listed path do not require any specific checks
    next()
  } else {
    // Other path should be redirected to sign up, with eventually invite email details
    next({
      path: `${formatAssistantPathFromRoute(to)}${configRoutes.LOGIN_PATHS.SIGN_UP.PATH}`,
      query: getEmailQuery(to)
    })
  }
}

/**
 * Get admin data from DB if needed
 * @param {Route} to the target Route Object being navigated to
 * @param {Route} from the current route being navigated away from
 * @param {Function} next this function must be called to resolve the hook. The action depends on the arguments provided to next
 */
const populateAdminData = async (to, from, next) => {
  if (store.getters.roles.length === 0 || !store.getters.assistants || !store.getters.assistants[0]) {
    // GetInfo fetch basic mandatory data, it needs to be awaited
    try {
      await store.dispatch('GetInfo', store.getters.email)
    } catch (err) {
      // "Network Error" backend does not reply
      // And we don't want to logout the user in that case
      if (!err || err.message !== 'Network Error') {
        removeTokens()
        window.location.replace('/signin')
      }

      return
    }
  }

  const needMoreAssistantInfo =
    store.getters.assistants.length === 0 ||
    !Object.prototype.hasOwnProperty.call(store.getters.assistants[0].activatedBy, 'firstName')

  if (RegExp(APP_LIST_REGEX).test(to.path) && needMoreAssistantInfo) {
    await store.dispatch('GetAssistants', {}).catch(() => {})
  } else if (needMoreAssistantInfo && !store.getters.isFetchingAssistants) {
    // No need to wait for it to be populate, with automatically dispatch itself
    // assistantSlug is only used when an assistant is not already found and therefore when assistantId will be the displayId
    store.dispatch('GetAssistants', to.params.displayId ? to.params : to.query).catch(() => {})
  }
}

/**
 * Get super admin data from DB if needed
 * @param {Route} to the target Route Object being navigated to
 * @param {Route} from the current route being navigated away from
 * @param {Function} next this function must be called to resolve the hook. The action depends on the arguments provided to next
 */
const populateSuperAdminData = async (to, from, next) => {
  // Super admin assistant setting route
  if (!hasSuperAdminRole(store.getters.roles)) {
    return
  }

  if (
    to.params.assistantSlugAdmin &&
    (!store.state.admin.selectedAssistant || store.state.admin.selectedAssistant.slug !== to.params.assistantSlugAdmin)
  ) {
    await store.dispatch('GetAdminAssistant', to.params.assistantSlugAdmin)
  }

  if (to.params.actionSlug) {
    await store.dispatch('GetMasterAction', to.params.actionSlug)
  }
}

/**
 * Generate new route if needed
 * @return {Boolean} true if new route have been generate
 */
const generateRoutes = async () => {
  let areRoutesNew = false

  if (store.getters.addRouters.length === 0) {
    // Update route for logged in user
    await store.dispatch('RemoveRoute', 'login_dashboard')

    // Generates an accessible routing table based on roles permission
    await store.dispatch('GenerateRoutes', store.getters.roles)

    // Add dynamically accessible routing tables
    router.addRoutes(store.getters.addRouters)
    areRoutesNew = true
  }
  return areRoutesNew
}

// @TODO have a look to https://blog.sqreen.io/authentication-best-practices-vue/
/**
 * Handle user global before guards
 * @param {Route} to the target Route Object being navigated to
 * @param {Route} from the current route being navigated away from
 * @param {Function} next this function *must* be called to resolve the hook. The action depends on the arguments provided to next
 */
router.beforeEach(async (to, from, next) => {
  NProgress.start() // start progress bar
  // if the user has a token in local storage, it means he is logged in
  if (!getToken() || RegExp(DEMO_REGEX).test(to.path)) {
    // Loggued out or demo path that is accessible publicly
    handleWhiteListedPath(to, from, next)
    return
  }

  // At this point we know that a token exists => user logged in

  // If the logged in user try to access the login page (without assistant params), redirect to apps list
  if (RegExp(LOGIN_REGEX).test(to.path)) {
    next({
      path: '/apps/list'
    })
    return
  }

  /*
    Handle 404 route
    We might want to redirect to an existing route

    Rules of the 404 redirection when logged:
    - requested path does not contain assistant information:
      => redirect to 404 page (handleWhiteListedPath)
    - requested path contains assistant information, redirection depending on role:
      - admin => dashboard
      - live chat agent => live chat
      - other roles => apps list
    */
  if (RegExp(NOTFOUND_REGEX).test(to.path)) {
    if (!to.redirectedFrom || !RegExp(ASSISTANT_REGEX).test(to.redirectedFrom)) {
      handleWhiteListedPath(to, from, next)
      return
    }

    /*
      We don't have params or query, only the raw url in redirectedFrom
      The path was not recognised by vue router so it couldn't extract the assistant params
      But as to.redirectedFrom passed the previous regex,  we know it is composed as:
      /{displayId}/{assistantSlug}/remaining/path/elements

      So we can extract baseRedirectionURL from it and redirect on the user role expected path
    */
    const baseRedirectionURL = getBaseRedirectionURL({}, to.redirectedFrom)
    next({
      path: getDefaultLoggedInPath(baseRedirectionURL, store.getters.roles)
    })

    return
  }

  try {
    await populateAdminData(to, from, next)
    await populateSuperAdminData(to, from, next)
  } catch (error) {
    if (isForbiddenError(error)) {
      await store.dispatch('FedLogOut')

      next({
        path: configRoutes.LOGIN_PATHS.SIGN_IN.PATH
      })
    } else if (to.name === 'admin_slot_settings') {
      let path = configSlot.MASTER_PATH
      if (!hasSuperAdminRole(store.getters.roles)) {
        path = `${formatAssistantPathFromRoute(to)}${configSlot.ASSISTANT_COMMON_PATH}`
      }
      next(path)
    } else if (to.name === 'assistant_actions_slot_settings') {
      next({
        path: `${formatAssistantPathFromRoute(to)}${configSlot.ASSISTANT_COMMON_PATH}`
      })
    } else if (to.name === 'admin_assistant_settings') {
      next({
        path: '/admin/assistants'
      })
    }

    return
  }

  // I dont deserve to be here, my token should have been invalid
  if (!store.getters.email || store.getters.email === '') {
    // if no mail but token, means "Network Error" : backend does not reply
    // And we don't want to logout the user in that case
    if (!getToken()) {
      await store.dispatch('FedLogOut')
    }
    handleWhiteListedPath(to, from, next)
    return
  }

  const areRoutesNew = await generateRoutes()

  // if we can guess the assistant from url or store, let's go
  let displayId = to.params.displayId || to.query.displayId
  // If we detect that the 'displayId' has the format of an entire object id,
  // let try to get the displayId from it
  if (displayId && RegExp(constants.REGEX.OBJECT_ID).test(displayId)) {
    displayId = getDisplayId(displayId)
  }
  const assistantSlug = to.params.assistantSlug || to.query.assistantSlug
  let assistant = getAssistant(displayId, assistantSlug)

  // Get default redirection path depending on the user roles
  const baseRedirectionURL = getBaseRedirectionURL({ displayId, assistantSlug, assistant })
  const defaultPath = getDefaultLoggedInPath(baseRedirectionURL, store.getters.roles)

  // If route have just been generated, next wont work, so we will need next(updatedTo)
  let updatedTo = {
    ...Object.assign({}, to),
    replace: true
  }

  // Ensure that isSuperAdminView is set to the right value
  const isSuperAdminView = ADMIN_REGEX.test(to.path)
  if (store.getters.isSuperAdminView !== isSuperAdminView) {
    store.commit('SET_ADMIN_VIEW', isSuperAdminView)
  }

  // 404, assistant creation, admin, profile, ...
  if (NON_ASSISTANT_PATH.find(path => RegExp(path).test(to.path))) {
    if (isSuperAdminView && !hasSuperAdminRole(store.getters.roles)) {
      updatedTo = {
        path: '/apps/list',
        query: getEmailQuery(to, true)
      }
    } else if (!areRoutesNew) {
      next()
      return
    }
    // The admin require a page where assistant data are required and he does not have assistant
  } else if (!store.getters.assistants || !store.getters.assistants.length) {
    const path = hasAdminRole(store.getters.roles) ? '/assistant/creation' : 'apps/list'
    updatedTo = {
      path,
      query: getEmailQuery(to, true)
    }
    // Assistant path need selectedAssistant
  } else if (
    !assistant &&
    NEED_ASSISTANT_PATH.find(path => RegExp(path).test(to.path)) &&
    !LOGIN_PATH.find(path => RegExp(path).test(to.path))
  ) {
    updatedTo = {
      path: to.path,
      query: getEmailQuery(to)
    }

    // Temp log with should be redirected to dashboard
    if (RegExp(TEMPLOG_REGEX).test(to.path)) {
      updatedTo = {
        path: '/', // reset
        query: getEmailQuery(to)
      }
    }

    // The admin clicked on a expired invite to join an assistant
    const emailQuery = getEmailQuery(to, true)
    if (emailQuery && emailQuery.displayId) {
      updatedTo = {
        path: '/apps/list',
        query: emailQuery
      }
    }

    if (!areRoutesNew) {
      assistant = store.getters.assistants[0]
      store.dispatch('SwitchAssistant', {
        assistant
      })
      next(updatedTo)
      return
    }
    // Admin did not asked for any assistant or required assistant is not valid or is not activated
  } else if (!assistant || (assistant.isGhost && !assistant.isActivated)) {
    // If to.path is not a valid route, and has no explicit redirectTo, redirect to apps list to avoid infinite loop
    const path = from.query.redirectTo ? decodeURI(from.query.redirectTo) : '/'

    updatedTo = {
      path: path,
      query: getEmailQuery(to)
    }
  } else if (
    decodeURI(to.query.email) === store.getters.email &&
    configRoutes.LOGIN_REDIRECTS.includes(from.name) &&
    !RegExp(ASSISTANT_CREATION_NEXT_REGEX).test(to.path)
  ) {
    // We want to redirect the admins to a different page than its default one
    // So only use the default path for users without the 'admin' role
    if (!hasAdminRole(store.getters.roles)) {
      next({
        path: defaultPath
      })
      return
    }

    // Admin invited is redirected to the step 2
    // ASSISTANT_CREATION_NEXT_REGEX check is there to avoid infinite loop on step2
    // @TODO check if same id ?
    updatedTo = {
      path: '/assistant/creation/step2',
      query: getEmailQuery(to, true)
    }
  } else {
    // The user asked assistant is part, but it's not the selectedAssistant, so we dispatch the update
    if (!store.getters.selectedAssistant.id || assistant.id !== store.getters.selectedAssistant.id) {
      store.dispatch('SwitchAssistant', {
        assistant
      })
    }

    // If from assistant creation path
    if (!displayId && NEED_ASSISTANT_PATH.find(path => RegExp(path).test(to.path))) {
      if (!areRoutesNew) {
        next()
        return
      }
    } else if (
      !displayId ||
      LOGIN_PATH.find(path => RegExp(path).test(to.path)) ||
      RegExp(TEMPLOG_REGEX).test(to.path)
    ) {
      // If from selected assistant
      updatedTo = {
        path: defaultPath,
        query: getEmailQuery(to)
      }
    } else if (displayId) {
      if (!areRoutesNew) {
        next()
        return
      }
      // forcing "login_dashboard" update
      // Don't need an email query here
      updatedTo = {
        path: to.path
      }
    } else {
      updatedTo = {
        path: configRoutes.LOGIN_PATHS.NOT_FOUND_PATH.PATH,
        query: getEmailQuery(to)
      }
    }
  }

  next(updatedTo)
  return
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
