import request from '@/utils/request'
import { getQueryString, getAssistantPath } from '@/utils/url'

/**
 * Find assistant intents by filters
 *
 * @param {String} assistantId - Assistant identifier
 * @param {Object} filters - Search filters
 * @returns {Promise<Object>}
 */
function getByAssistant(assistantId, filters = {}) {
  return request({
    url: `/assistants/${assistantId}/intents${getQueryString(filters)}`,
    method: 'get'
  })
}

/**
 * Get assistant intents based on entity id
 *
 * @param {String} assistantId - Assistant identifier
 * @param {String} entityId - entity Id
 * @returns {Promise<any>}
 */

function getByAssistantEntity(assistantId, entityId) {
  return request({
    url: `/assistants/${assistantId}/intents?entityId=${entityId}&full=false&onlyCustom=false&count=0`,
    method: 'get'
  })
}

/**
 * Get assistant intents invocations numbers
 *
 * @param {String} assistantId - Assistant identifier
 * @param {String} locale - selected locale
 * @returns {Promise<any>}
 */
function getInvocationsNumbers(assistantId, locale) {
  return request({
    url: `/assistants/${assistantId}/intents/invocations?locale=${locale}`,
    method: 'get'
  })
}

/**
 * Get an assistant intent
 *
 * @param {String} assistantId - Assistant identifier
 * @param {String} intentSlug - Intent slug
 * @returns {Promise<any>}
 */
function getAssistantIntent(assistantId, intentSlug) {
  return request({
    url: `/assistants/${assistantId}/intents/${intentSlug}`,
    method: 'get'
  })
}

/**
 * Get an assistant intent
 *
 * @param {String} assistantId - Assistant identifier
 * @returns {Promise<any>}
 */
function getAssistantIntentsSlugs(assistantId) {
  return request({
    url: `/assistants/${assistantId}/intents/all/slugs`,
    method: 'get'
  })
}

/**
 * Add an assistant intent
 *
 * @param {String} assistantId - Assistant identifier
 * @param {Object} intent - Intent
 * @param {Array<Object>} entitiesValuesToUpdate - Array of entity values objects {id: '<EntityId>', locale:'xx_YY', valueToAdd: '<String>', selectedValue: '<String>'}
 * @returns {Promise<any>}
 */
function addAssistantIntent(assistantId, intent, entitiesValuesToUpdate) {
  return request({
    url: `/assistants/${assistantId}/intents`,
    method: 'post',
    data: {
      intent,
      entitiesValuesToUpdate
    }
  })
}

/**
 * Update an assistant intent
 *
 * @param {String} assistantId - Assistant identifier
 * @param {String} intentId - Intent identifier
 * @param {Object} intent - Intent
 * @param {Array<Object>} entitiesValuesToUpdate - Array of entity values objects {id: '<EntityId>', locale:'xx_YY', valueToAdd: '<String>', selectedValue: '<String>'}
 * @returns {Promise<any>}
 */
function updateAssistantIntent(assistantId, intentId, intent, entitiesValuesToUpdate) {
  return request({
    url: `/assistants/${assistantId}/intents/${intentId}`,
    method: 'post',
    data: {
      intent,
      entitiesValuesToUpdate
    }
  })
}

/**
 * Delete an assistant intent
 *
 * @param {String} assistantId - Assistant identifier
 * @param {String} intentId - Intent identifier
 * @returns {Promise<any>}
 */
function deleteAssistantIntent(assistantId, intentId) {
  return request({
    url: `/assistants/${assistantId}/intents/${intentId}`,
    method: 'delete'
  })
}

/**
 * Get a localized training phrase for one of the intentsIds
 * @param {String} assistantId - Assistant identifier
 * @param {Array<String>} filters.intentIds - intents Id
 * @param {String} filters.locale - locale
 * @returns {Promise<{Object}>} Object being {locale, trainingPhrase, intentId} or null
 */

function getLocalizedAssistantIntentsTrainingPhrase(assistantId, filters = {}) {
  if (!filters.intentIds || filters.intentIds.length === 0 || !filters.locale) {
    return null
  }

  filters.intentIds = JSON.stringify(filters.intentIds)

  return request({
    url: `/assistants/${assistantId}/intents/trainingPhrase${getQueryString(filters)}`,
    method: 'get'
  })
}

/**
 * Get a intent settings list which include searchedString in locale
 * @param {String} assistantId - Assistant identifier
 * @param {String} filters.searchedString - intents Id
 * @param {String} filters.locale - locale
 * @returns {Promise<{Array}>} Array of intent settings
 */
function getIntentSettingsWithString(assistantId, filters = {}) {
  const assistantPath = getAssistantPath(assistantId)
  return request({
    url: `${assistantPath}/intents/intentSettings/Id${getQueryString(filters)}`,
    method: 'get'
  })
}

/**
 * Get assistant intent's entities list
 * @param {String} assistantId
 * @param {String} intentId
 * @returns {Promise<Object[]>} Array of entities
 */
function getAssistantIntentEntities(assistantId, intentId) {
  return request({
    url: `/assistants/${assistantId}/intents/${intentId}/entities`,
    method: 'get'
  })
}
/**
 * Generate training phrases with AI for an intent
 * @param {String} assistantId
 * @param {String} intentId
 * @param {String} locale
 * @param {String} prompt
 * @returns {Promise<String[]>} Array of training phrases
 */
function generateTrainingPhrasesWithAi(assistantId, intentId, locale, prompt) {
  const queryParams = { locale, prompt }
  return request({
    url: `/assistants/${assistantId}/intents/${intentId}/generatePhrases${getQueryString(queryParams)}`,
    method: 'get',
    shouldSetDefaultError: true
  })
}

export default {
  getByAssistant,
  getByAssistantEntity,
  getInvocationsNumbers,
  getAssistantIntent,
  addAssistantIntent,
  updateAssistantIntent,
  deleteAssistantIntent,
  getAssistantIntentsSlugs,
  getLocalizedAssistantIntentsTrainingPhrase,
  getIntentSettingsWithString,
  getAssistantIntentEntities,
  generateTrainingPhrasesWithAi
}
