import constants from '@/config/constants'

/**
 * Return a valid UI locale from a detected locale
 *
 * Currently platform locales and interface locales match (only French and English) so the following code works
 * Check again when we'll add more platform locales
 *
 * @param {String} locale formatted like fr_FR
 * @returns {String} locale formatted like fr_FR
 *
 */
export function getValidInterfaceLocale(locale) {
  // Split "fr_CA" to "fr"
  const prefixLocale = (locale && locale.split(/[-_]/)[0]) || ''

  // Map(2) { "fr" => "fr_FR", ...}
  const interfaceLocales = new Map(constants.INTERFACE_AVAILABLE_LOCALES.map(item => [item.split(/[-_]/)[0], item]))

  return interfaceLocales.has(prefixLocale) ? interfaceLocales.get(prefixLocale) : constants.DEFAULT_INTERFACE_LOCALE
}

/**
 * Return a valid UI locale from a browser locale
 *
 * @returns {String} locale formatted like fr_FR
 *
 */
export function getValidInterfaceLocaleFromBrowser() {
  const locale = navigator.language || navigator.userLanguage
  return getValidInterfaceLocale(locale)
}
