// Libs
import { normalizeLowerCase } from '@/utils/text'

/**
 * Get an object property or return default value
 * @param {Object} object
 * @param {String} property
 * @param {Any} defaultValue
 * @returns {Any} Object property or default value
 */
export function getObjectProperty(object, property, defaultValue) {
  return object && Object.prototype.hasOwnProperty.call(object, property) ? object[property] : defaultValue
}

/**
 * Get the feature/module name from the localized settings
 * @param {Object} objSettings
 * @param {String} locale
 * @returns {String} name
 */
export function getLocalizedName(objSettings, locale) {
  return objSettings[locale] ? objSettings[locale].name : objSettings[Object.keys(objSettings)[0]].name
}

/**
 * Get the feature/module propName from the localized settings
 * @param {Object} objSettings
 * @param {String} locale
 * @param {String} propName
 * @param {String} defaultValue
 * @returns {String} name
 */
export function getLocalizedProp(objSettings, locale, propName, defaultValue = null) {
  return objSettings[locale] ? objSettings[locale][propName] : defaultValue
}

/**
 * Get the localized element from the array of elements
 * @param {Array<Object>} elements
 * @param {String} element.locale
 * @param {String} locale
 * @returns {Object} element
 */
export function getLocalizedElement(elements, locale) {
  return elements && elements.find(element => element.locale === locale)
}

/**
 * Get an element localized name from its settings
 * @param {Object} settings
 * @param {String} locale
 * @param {String} defaultValue fallback to this value if no settings name found
 * @param {Object} master // master element
 * @returns {String} element name
 */
export function getLocalizedNameFromSettings(settings, locale, defaultValue, master = null) {
  const localizedSettings = getLocalizedElement(settings, locale)

  let name = (localizedSettings && localizedSettings.name) || defaultValue
  if (master && master.settings && master.settings.length) {
    const localizedMasterSettings = getLocalizedElement(master.settings, locale)
    name = (localizedMasterSettings && localizedMasterSettings.name) || name
  }

  return name
}

/**
 * Filter method to get the item matching a user input
 * @param {String} itemName
 * @param {String} itemSlug
 * @param {String} filter - user input, what is searched
 * @return {Boolean} is the item matching the filter or not
 */
export function filterItemFromList(itemName, itemSlug, filter) {
  const normalizedFilter = normalizeLowerCase(filter)
  const name = itemName ? normalizeLowerCase(itemName) : null
  const slug = itemSlug ? normalizeLowerCase(itemSlug) : null
  return (name && name.includes(normalizedFilter)) || (slug && slug.includes(normalizedFilter))
}

/**
 * Get random hex color code
 * @returns {String}
 */
export function getRandomHexColorCode() {
  return '#' + Math.random().toString(16).slice(2, 8)
}

/**
 * Get the radio options
 * @param {Object} options
 * @param {String} labelKey
 * @returns {Array<object>} Array of labels object with value
 */
export function getRadioOptions(options, labelKey) {
  const array = []
  Object.keys(options).forEach(label => {
    array.push({
      label: label,
      text: this.$i18n.t(`${labelKey}.${label.toLowerCase()}_option`),
      value: options[label]
    })
  })
  return array
}

/**
 * Get the selected features/industries objects list
 * @param {Array<object>} mainElementsList main list of elements
 * @param {Array<String>} selectedIdsList list of selected elements ids
 * @returns {Array<object>} selectedElementsList list of selected elements objects
 */
export function getSelectedList(mainElementsList, selectedIdsList) {
  if (!mainElementsList || mainElementsList.length === 0 || !selectedIdsList || selectedIdsList.length === 0) {
    return []
  }

  return mainElementsList.filter(obj => selectedIdsList.includes(obj.id))
}

/**
 * return locale setting name or first localized setting name as default
 * @param {Object<Object>} elementSettings - object which keys is a locale
 * @param {String} locale - requested locale
 * @return {String} localized name or first locale settings names as default
 */
export function getSettingName(elementSettings, locale) {
  return elementSettings[locale] ? elementSettings[locale].name : elementSettings[Object.keys(elementSettings)[0]].name
}

/**
 * Return the component name depending on the selected tab
 * @param {Array<String>} tabs
 * @param {String} selectedTab
 * @param {String} defaultValue
 * @returns {String} componentName
 */
export function getComponentName(tabs, selectedTab, defaultValue = '') {
  for (const tab of Object.values(tabs)) {
    if (tab.tabName === selectedTab) {
      return tab.componentName
    }
  }

  return defaultValue
}

/**
 * Get html template for the dropdown detail
 * @param {String} customClass
 * @param {String} details
 * @returns {String} html template for the dropdown details
 */
export function getDropdownItemDetailsHtml(customClass, details) {
  return ` <span class="dropdown-description ${customClass}">${details}</span>`
}

/**
 * Stringify an element
 * @param {*} element
 * @returns {String|null}
 */
export function safeStringify(element) {
  try {
    return (element && element.length && JSON.stringify(element)) || null
  } catch (error) {
    return null
  }
}

/**
 * Check if a value is empty
 * @param {*} value
 * @returns {Boolean}
 */
export function isValueEmpty(value) {
  return value === null || value === undefined || value === ''
}

/**
 * Template function for tribute js
 * @param {Object} item
 * @returns {String}
 */
export function tributeTemplateFunction(item) {
  if (item) {
    return `[${item.original.value}]`
  }

  return ''
}

/**
 * Get the localized name of a element
 * It will fallback on the element's slug if no localized name is found
 * @param {Object} element
 * @param {Object} element.localizedNames { fr_FR: 'name', en_US: 'name US' }
 * @param {String} element.slug
 * @param {String} locale
 * @returns {String}
 */
export function getElementLocalizedName(element, locale) {
  return element.localizedNames[locale] || element.slug
}

/**
 * Parse the JWT token stiring
 * @param {String} token
 * @returns {Object}
 */
export function parseJwt(token) {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (err) {
    return null
  }
}

/**
 * Get localized setting object property
 * @param {Object[]} objects
 * @param {String} locale
 * @param {String|null} [specificProperty=null]
 * @param {any|null} defaultValue
 * @returns {any|null}
 */
export function getLocalizedObject(objects, locale, specificProperty = null, defaultValue = null) {
  const object = objects?.find(object => {
    if (object?.locale !== locale) {
      return false
    }
    return true
  })

  return object && specificProperty ? object[specificProperty] : defaultValue
}
