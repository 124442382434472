import { getAssistantLocalizedName } from '@/utils/assistant'

const getters = {
  // App state
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  TTSVoice: state => state.app.TTSVoice,
  TTSService: state => state.app.TTSService,
  isAudioActivated: state => state.app.isAudioActivated,
  envColor: state => state.app.envColor,
  isSuperAdminView: state => state.app.isSuperAdminView,
  pendingLocale: state => state.app.pendingLocale,
  isSaved: state => state.app.isSaved,
  showModal: state => state.app.showModal,
  pendingRoute: state => state.app.pendingRoute,
  filters: state => state.app.filters,
  nbActiveLiveChatConversations: state => state.app.nbActiveLiveChatConversations,
  yeldaOrWhiteBrandName: state => state.app.yeldaOrWhiteBrandName,
  isWebSocketConnected: state => state.app.isWebSocketConnected,
  liveChatAgents: state => state.app.liveChatAgents,
  usersListInfo: state => state.app.usersListInfo,

  // User (admin) state
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  assistants: state => state.user.assistants,
  isFetchingAssistants: state => state.user.isFetchingAssistants,
  selectedAssistant: state => state.user.selectedAssistant,
  selectedAssistantLocale: state => state.user.selectedAssistantLocale,
  firstName: state => state.user.firstName,
  lastName: state => state.user.lastName,
  userId: state => state.user.userId,
  email: state => state.user.email,
  roles: state => state.user.roles,
  locale: state => state.user.locale,
  timezone: state => state.user.timezone,
  assistantName: state => {
    return getAssistantLocalizedName(
      state.user.selectedAssistant,
      state.user.selectedAssistantLocale,
      state.user.selectedAssistant.slug
    )
  },

  // Permissions
  permission_routers: state => state.permission.routers,
  addRouters: state => state.permission.addRouters,

  // Admin (super admin) state
  adminsList: state => state.admin.adminsList,
  selectedMasterAction: state => state.admin.selectedMasterAction,
  selectedSuperAdminAssistant: state => state.admin.selectedAssistant,
  selectedSuperAdminLocale: state => state.admin.selectedLocale,

  // Master data
  intentsMaster: state => state.master.intents
}
export default getters
