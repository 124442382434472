<template>
  <div class="agent-container">
    <div v-for="(agent, key) in liveChatAgents" :key="key" class="agent-bubble">
      <span data-toggle="tooltip" data-placement="bottom" :title="getAgentName(agent)">
        {{ getAgentInitials(agent) }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ConnectedLiveChatAgentsList',
  props: {
    liveChatAgents: { type: Array, required: true }
  },
  methods: {
    getInitialInCaps(string) {
      return string.charAt(0).toUpperCase()
    },
    getAgentInitials(agent) {
      return `${this.getInitialInCaps(agent.firstName)}${this.getInitialInCaps(agent.lastName)}`
    },
    getAgentName(agent) {
      return `${agent.firstName} ${agent.lastName}`
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.agent-container {
  display: flex;
  height: 100%;
  align-items: center;
}

.agent-container .agent-bubble {
  background-color: white;
  padding: 2px;
  margin: 4px;
  border-radius: 50%;
  cursor: pointer;
}
</style>
