import { signUp, login, logout, getInfo } from '@/api/login'
import { setAssistantsIdsDateVisited, getAdminAssistants } from '@/api/assistant'
import intentAPI from '@/api/intent'
import { getToken, setToken, removeTokens } from '@/utils/auth'
import constants from '@/config/constants'
import { checkIfRouteParamsMatchAssistant } from '@/utils/assistant'
import { isForbiddenError } from '@/utils/routing'
import currentRouter from '@/router'

import i18n from '../../lang/i18n'

/**
 * return ordered assistants based on assistantsIdsDateVisited array
 * @param assistants
 * @param assistantsIdsDateVisited
 * @returns orderedAssistantIds
 */
function orderAssistantIds(assistants, assistantsIdsDateVisited) {
  const orderedAssistantIds = []

  if (!assistants) {
    return orderedAssistantIds
  }

  if (!assistantsIdsDateVisited) {
    return assistants
  }

  // List of the visited assistants still valid (part of assistants list)
  assistantsIdsDateVisited.forEach(visitedAssistant => {
    const matchingAssistant = assistants.find(assistant => assistant.id === visitedAssistant.assistantId)
    if (matchingAssistant) {
      orderedAssistantIds.push(matchingAssistant)
    }
  })

  // Other assistants not yet visited
  const notOrderedAssistantIds = assistants.filter(assistant => {
    return !orderedAssistantIds.find(foundAssistant => assistant.id === foundAssistant.id)
  })

  return orderedAssistantIds.concat(notOrderedAssistantIds)
}

/**
 * Update selectedAssistantLocale to a valid locale (selectedAssistant locales or null)
 * @param {Object} state
 */
const safelyUpdateAssistantSelectedLocale = function (state) {
  // Default case : state.selectedAssistantLocale match state.selectedAssistant.locales
  const locales = (state.selectedAssistant && state.selectedAssistant.locales) || []
  if (!locales || locales.length === 0 || !locales[0]) {
    // If no state.selectedAssistant.locales, let' set  state.selectedAssistantLocale to null
    state.selectedAssistantLocale = constants.DEFAULT_PLATFORMS_LOCALE
  } else if (!locales.includes(state.selectedAssistantLocale)) {
    // Update state.selectedAssistantLocale if current one is not activated
    state.selectedAssistantLocale = locales[0]
  }
}
const user = {
  state: {
    token: getToken(),
    firstName: '',
    lastName: '',
    email: '',
    avatar: '',
    userId: null,
    assistants: [],
    isFetchingAssistants: false,
    selectedAssistant: {},
    selectedAssistantLocale: constants.DEFAULT_PLATFORMS_LOCALE,
    roles: [],
    locale: '',
    timezone: {}
  },

  mutations: {
    ADD_ASSISTANT: (state, assistant) => {
      state.assistants.push(assistant)
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { firstName, lastName }) => {
      state.firstName = firstName
      state.lastName = lastName
    },
    SET_EMAIL: (state, email) => {
      state.email = email
    },
    SET_USER_ID: (state, id) => {
      state.userId = id
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_UI_LOCALE: (state, locale) => {
      state.locale = locale
    },
    SET_TIMEZONE: (state, timezone) => {
      state.timezone = timezone
    },

    SET_ASSISTANTS: (state, assistantsIdsDateVisited) => {
      state.assistants = assistantsIdsDateVisited
    },
    FIND_AND_UPSERT_SELECTED_ASSISTANT: (state, { assistantId, assistantObject }) => {
      if (!state.assistants.length) {
        return
      }
      const selectedAssistant = state.assistants.find(
        assistant => assistant._id === assistantId || checkIfRouteParamsMatchAssistant(assistant, assistantObject)
      )
      if (!selectedAssistant) {
        return
      }
      state.selectedAssistant = selectedAssistant
    },
    SET_SELECTED_ASSISTANT: (state, selectedAssistant) => {
      state.selectedAssistant = selectedAssistant
      safelyUpdateAssistantSelectedLocale(state)
    },
    SET_SELECTED_ASSISTANT_LOCALE: (state, locale) => {
      state.selectedAssistantLocale = locale

      if (state.selectedAssistant && state.selectedAssistant.id) {
        setAssistantsIdsDateVisited(state.selectedAssistant.id, locale)
      }
    },
    UNSET_SELECTED_ASSISTANT: state => {
      state.selectedAssistant = {}
    },
    SET_ASSISTANT_PROPERTY: (state, { assistantId, field, property }) => {
      const assistant = state.assistants.find(assistant => {
        return assistant.id === assistantId
      })

      if (!assistant) {
        console.error(`SET_ASSISTANT_PROPERTY error: assistant ${assistantId} not found`)
        return
      }

      assistant[field] = property
      state.selectedAssistant[field] = property
    },
    SET_ASSISTANT_SETTINGS: (state, { assistantId, settings }) => {
      state.assistants.find(assistant => {
        return assistant.id === assistantId
      }).settings = settings
    },
    UPDATE_ASSISTANT_LOCALES: (state, locales) => {
      state.selectedAssistant.locales = locales
      const index = state.assistants.findIndex(assistant => assistant.id === state.selectedAssistant.id)
      if (index !== -1) {
        state.assistants[index].locales = locales
      }

      safelyUpdateAssistantSelectedLocale(state)
    },
    UPSERT_SELECTED_ASSISTANT_SETTING: (state, { setting }) => {
      const settingUpdated = state.selectedAssistant.settings.find((element, index) => {
        const match = element.id === setting.id
        if (match) {
          state.selectedAssistant.settings[index] = setting
        }

        return match
      })

      if (!settingUpdated) {
        state.selectedAssistant.settings.push(setting)
      }
    },
    // @TODO later : handle roles per assistant
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_ASSISTANTS_STATUS: (state, isFetchingAssistants) => {
      state.isFetchingAssistants = isFetchingAssistants
    },
    SET_ASSISTANTS_REPORT_FREQUENCIES: (state, assistantsEmailReportFrequencies) => {
      state.assistantsEmailReportFrequencies = assistantsEmailReportFrequencies
    },
    REMOVE_ASSISTANT: (state, { assistantId, shouldRedirect = true }) => {
      const index = state.assistants.findIndex(assistant => assistant._id === assistantId)
      if (index === -1) {
        return
      }
      state.assistants.splice(index, 1)

      if (!shouldRedirect) {
        state.selectedAssistant = {}
        return
      }

      // Unset selected assistant
      if (state.selectedAssistant && state.selectedAssistant._id === assistantId) {
        currentRouter.push({ path: '/' }, () => {
          /*
            In a lot of views/components we are watching changes on the selectedAssistant to update the data displayed
            To avoid trying to fetch new data from the API with invalid urls such as https://localhost:8080/assistants/undefined/settings (which results in one or multiple 400 errors),
            we reset the selectedAssistant only when we have already been redirected on the apps list
          */
          state.selectedAssistant = {}
        })
      }
    }
  },

  actions: {
    SignUp({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        userInfo.email = userInfo.email.toLowerCase()
        signUp(userInfo)
          .then(token => {
            commit('SET_EMAIL', userInfo.email)
            const firstName = userInfo.firstName
            const lastName = userInfo.lastName
            commit('SET_NAME', {
              firstName,
              lastName
            })
            setToken(token, localStorage)
            commit('SET_TOKEN', token)
            resolve()
          })
          .catch(error => {
            removeTokens()
            reject(error)
          })
      })
    },

    // Login
    Login({ commit }, { userInfo, shouldRememberUser }) {
      return new Promise((resolve, reject) => {
        login(userInfo.email.toLowerCase(), userInfo.password, userInfo.googleId)
          .then(token => {
            const storage = shouldRememberUser ? localStorage : sessionStorage
            setToken(token, storage)
            commit('SET_TOKEN', token)
            commit('SET_EMAIL', userInfo.email)
            resolve()
          })
          .catch(error => {
            removeTokens()
            reject(error)
          })
      })
    },

    GetAssistants({ commit, state, dispatch }, assistantObject) {
      if (state.isFetchingAssistants) {
        return false
      }

      commit('SET_ASSISTANTS_STATUS', true)

      return new Promise((resolve, reject) => {
        getAdminAssistants()
          .then(data => {
            // Assistant data
            const orderedAssistants = orderAssistantIds(data.assistants, data.assistantsIdsDateVisited)
            commit('SET_ASSISTANTS', orderedAssistants)
            if (assistantObject.displayId) {
              commit('FIND_AND_UPSERT_SELECTED_ASSISTANT', { assistantObject })
            }
            resolve(data)
          })
          .catch(error => {
            if (isForbiddenError(error)) {
              // Delay the redirection to leave time to the user to read the error message (otherwise we have a blink effect too)
              dispatch('FedLogOut').then(() => setTimeout(() => location.reload(), constants.REDIRECTION_DELAY))
            }
          })
          .finally(() => {
            commit('SET_ASSISTANTS_STATUS', false)
          })
      })
    },
    // Get user information
    GetInfo({ commit, dispatch }, username) {
      return new Promise((resolve, reject) => {
        getInfo(username)
          .then(response => {
            if (!response || !response.data || !response.data.roles || !response.data.roles.length) {
              return reject(i18n.t('login.error_invalid_login'))
            }
            const data = response.data
            // App data
            // https://vuex.vuejs.org/api/#dispatch
            dispatch('SetEnvColor', response.color || null, null, {
              root: true
            })

            // User data
            commit('SET_ROLES', data.roles)
            commit('SET_NAME', {
              firstName: data.firstName,
              lastName: data.lastName
            })
            commit('SET_EMAIL', data.email)
            commit('SET_USER_ID', data._id)
            commit('SET_AVATAR', data.avatar)
            commit('SET_UI_LOCALE', data.UILocale)
            commit('SET_TIMEZONE', data.timezone)
            commit('SET_SELECTED_ASSISTANT_LOCALE', data.selectedPlatformLocale)
            commit('SET_SELECTED_SUPERADMIN_LOCALE', data.selectedPlatformLocale)
            commit('SET_ASSISTANTS_REPORT_FREQUENCIES', data.assistantsEmailReportFrequencies)
            // Assistant data
            const orderedAssistants = orderAssistantIds(data.assistantIds, data.assistantsIdsDateVisited)
            commit('SET_ASSISTANTS', orderedAssistants)
            resolve(response)
          })
          .catch(error => {
            return reject(error)
          })
      })
    },
    // Signout
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .finally(() => {
            removeTokens()
            // re-instantiate the vue-router object to avoid bugs and empty the store (no need of manual reset)
            location.reload()
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // Front end
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', 'null')
        removeTokens()
        resolve()
      })
    },

    UpdateUserToken({ commit }, token) {
      setToken(token, localStorage)
      commit('SET_TOKEN', token)
    },
    SwitchAssistant({ commit, state, dispatch }, { assistant }) {
      commit('SET_SELECTED_ASSISTANT', assistant)

      // Update master Intents to filter the master intents based on the assistant's industry
      dispatch('SetMasterIntents')

      if (assistant && assistant.id) {
        setAssistantsIdsDateVisited(assistant.id, state.selectedAssistantLocale)
      }
    },
    SetAssistantSetting: ({ commit }, { assistantId, newSetting, oldSetting, oldSettings }) => {
      let settings = []

      oldSettings &&
        oldSettings.forEach(setting => {
          if (setting && oldSetting && ((setting.id && oldSetting.id === setting.id) || !setting.id)) {
            settings.push(newSetting)
          } else {
            settings.push(setting)
          }
        })

      // If no old setting, it means that the new setting has just been created
      if (!oldSetting && newSetting._id) {
        settings.push(newSetting)
      }

      // If new setting is empty and old setting is not
      if (!newSetting && oldSetting._id) {
        settings = settings.filter(setting => setting._id !== oldSetting._id)
      }

      commit('SET_IS_SAVED', true)
      commit('SET_ASSISTANT_SETTINGS', {
        assistantId: assistantId,
        settings: settings
      })

      commit('FIND_AND_UPSERT_SELECTED_ASSISTANT', { assistantId })
    },
    SetAssistantSettings: ({ commit }, { assistantId, settings }) => {
      commit('SET_ASSISTANT_SETTINGS', {
        assistantId: assistantId,
        settings: settings
      })

      commit('FIND_AND_UPSERT_SELECTED_ASSISTANT', { assistantId })
    },
    SetAssistantTimezone: ({ commit }, { assistantId, timeZone }) => {
      commit('SET_ASSISTANT_PROPERTY', {
        assistantId,
        field: 'timeZone',
        property: timeZone
      })
    },
    FetchAssistantIntents: ({ commit }, assistantId) => {
      return new Promise((resolve, reject) => {
        // This call is especially long
        // => we postpone it so it will be called after main pages calls
        // So it won't slow down the whole pages display
        // @TODO : improve the call speed itself
        const delay = 300
        setTimeout(() => {
          intentAPI
            .getByAssistant(assistantId, {
              count: 0,
              full: false,
              onlyCustom: false
            })
            .then(response => {
              if (!response || !response.intents || !response.intents.length) {
                return resolve()
              }
              commit('SET_ASSISTANT_PROPERTY', {
                assistantId,
                field: 'intentIds',
                property: response.intents
              })
              resolve()
            })
            .catch(error => {
              return reject(error)
            })
        }, delay)
      })
    },
    CheckAssistantPermission: ({ commit }, assistant) => {
      if (!assistant) {
        return
      }
      // If the assistant is not in the admin assistants list anymore,
      // it means that the assistant should be removed from the store assistants list as well
      getAdminAssistants()
        .then(data => {
          if (!data.assistants.some(item => item._id === assistant._id)) {
            throw new Error()
          }
        })
        .catch(() => {
          commit('REMOVE_ASSISTANT', { assistantId: assistant._id })
        })
    },
    SetUILocale: ({ commit }, locale) => {
      commit('SET_UI_LOCALE', locale)
    }
  }
}

export default user
