// Utils
import request from '@/utils/request'
import { getQueryString } from '@/utils/url'

/**
 * Return assistant Settings
 *
 * @param {String} assistantId
 * @returns {Promise<Object>}
 */
export function getAssistantSettings(assistantId) {
  return new Promise(resolve => {
    request({
      url: `/assistants/${assistantId}/settings`,
      method: 'get'
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        console.error('error getAssistantSettings', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}

/**
 * Test an assistant escalation connection
 *
 * @param {String} assistantId
 * @param {String} escalationService
 * @param {Object} parameters - EasiWare parameters { siteId, password, userId, apiKey }
 * @returns {Promise<Object>}
 */
export function testAssistantEscalationConnection(assistantId, escalationService, parameters) {
  return new Promise(resolve => {
    request({
      url: `/assistants/${assistantId}/settings/escalation/${escalationService}/connection`,
      method: 'post',
      data: {
        parameters: parameters
      }
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        console.error('error testAssistantEscalationConnection', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}

/**
 * Update or insert an assistant setting
 *
 * @param {String} assistantId
 * @param {Object} setting
 * @returns {Promise<Object>}
 */
export function upsertAssistantSetting(assistantId, setting) {
  delete setting._id
  return new Promise(resolve => {
    request({
      url: `/assistants/${assistantId}/setting`,
      method: 'post',
      data: {
        setting
      }
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        console.error('error upsertAssistantSetting', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}
/**
 * Get all assistants (both activated and deactivated)
 * @returns {Promise<Object>} all assistants
 */
export function getAllAssistants() {
  return new Promise(resolve => {
    request({
      url: `/assistants?all=true`,
      method: 'get'
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        console.error('error getAllAssistants', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}

/**
 * Get admin assistants
 * @returns {Promise<Object>} all admin assistants
 */
export function getAdminAssistants() {
  return request({
    url: '/assistants',
    method: 'get'
  })
}

/**
 * Get all activated assistants
 * @returns {Promise<Object>} all assistants
 */
export function getActivatedAssistants() {
  return new Promise(resolve => {
    request({
      url: '/assistants?activated=true&full=false',
      method: 'get'
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        console.error('error getActivatedAssistants', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}

/**
 * Find assistant from its slug
 * @param {String} assistantSlug
 * @returns {Promise<Object>} assistant found
 */
export function getAssistantBySlug(assistantSlug) {
  return request({
    url: `/assistants/details/${assistantSlug}`,
    method: 'get'
  })
}

/**
 * Create or update assistant NLP settings
 * @param {Object} nlpSettings
 * @param {String} email of the admin updating those NLP settings
 * @param {String} assistantId
 * @returns {Promise<Object>} assistant updated or created with its NLP settings
 */
export function upsertAssistantNLPSettings(nlpSettings, email, assistantId) {
  // if no assistantId, it means we are creating a ghost assistant
  const id = assistantId ? `${assistantId}/` : ''
  return request({
    url: `/assistants/${id}nlp`,
    method: 'post',
    data: {
      nlpSettings,
      email
    },
    shouldSetDefaultError: true
  })
}

/**
 * Create or update assistant LLM settings
 * @param {Object} llmSettings
 * @param {String} assistantId
 * @returns {Promise<Object>} assistant updated or created with its NLP settings
 */
export function upsertAssistantLLMSettings(llmSettings, assistantId) {
  return request({
    url: `/assistants/${assistantId}/llm`,
    method: 'post',
    data: {
      llmSettings
    },
    shouldSetDefaultError: true
  })
}

/**
 * Create or update assistant sms segment cost settings
 * @param {Object} segmentCostSetting
 * @param {String} assistantId
 * @returns {Promise<Object>} assistant
 */
export function upsertAssistantSmsSegmentCostSettings(segmentCostSetting, assistantId) {
  return request({
    url: `/assistants/${assistantId}/sms/segmentCost`,
    method: 'post',
    data: {
      segmentCostSetting
    },
    shouldSetDefaultError: true
  })
}

/**
 * activate ghost assistant and assign it to admin
 * @param {Object} assistant to create
 * @param {String} email of the admin who activate it
 * @param {Object} assistantVoices to set as default value in AboutAssistant KB
 * @returns {Promise<Object>} activated assistant
 */
export function activateGhostAssistant(assistant, email, assistantVoices) {
  return new Promise(resolve => {
    request({
      url: '/assistants/admin',
      method: 'post',
      data: {
        assistant,
        email,
        assistantVoices
      }
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        console.error('error activateGhostAssistant', err)
        resolve({
          code: 50000,
          data: {},
          err: err
        })
      })
  })
}

/**
 * Update the list of assistant's admins
 * @param {String} assistantId id of the selected assistant
 * @param {Array<Object>} admins assistant's admins [{ _id: String, roles: Array }]
 * @returns {Promise<Object>}
 */
export function updateAssistantAdmins(assistantId, admins) {
  return request({
    url: `/assistants/${assistantId}/admins`,
    method: 'post',
    data: {
      admins
    },
    shouldSetDefaultError: true
  })
}

/**
 * Get the assistant industries
 * @returns {Promise<Object>}
 */
export function getAssistantIndustries() {
  return new Promise((resolve, reject) => {
    request({
      url: `/assistants/getIndustries`,
      method: 'get'
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.error('error getAssistantIndustries', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}
/**
 * Get the assistant localized name
 * @param {String} displayId
 * @param {String} locale
 * @param {String} assistantSlug
 * @returns {Promise<Object>}
 */
export function getLocalizedAssistantName(displayId, locale, assistantSlug) {
  return request({
    url: `/app/${displayId}/name/${assistantSlug}${getQueryString({ locale })}`,
    method: 'get'
  })
}

/**
 * Set the assistantsIdsDateVisited array
 * @param {String} assistantId id of the selected assistant
 * @returns {Promise<Object>}
 */
export function setAssistantsIdsDateVisited(assistantId, locale) {
  const query = locale ? `?locale=${locale}` : ''
  return new Promise((resolve, reject) => {
    request({
      url: `/assistants/${assistantId}/assistantsIdsDateVisited${query}`,
      method: 'post'
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.error('error setAssistantsIdsDateVisited', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}

/**
 * Set the set assistantId email report frequency for a locale
 * @param {String} assistantId id of the selected assistant
 * @param {String} frequency for the email report
 * @param {String} locale
 * @returns {Promise<Object>}
 */
export function setAssistantsEmailReportFrequencies(assistantId, frequency, locale) {
  return new Promise((resolve, reject) => {
    request({
      url: `/assistants/${assistantId}/assistantsEmailReportFrequency`,
      method: 'post',
      data: {
        frequency,
        locale
      }
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.error('error setAssistantsEmailReportFrequencies', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}

/**
 * Update assistant activated locales
 * @param {String} assistantId
 * @param {Array<String>} locales
 * @returns {Promise<Object>}
 */
export function updateAssistantLocales(assistantId, locales) {
  return new Promise((resolve, reject) => {
    request({
      url: `/assistants/${assistantId}/locales`,
      method: 'post',
      data: {
        locales
      }
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        console.error('error updateAssistantLocales', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}

/**
 * Update Assistant Nlp Threshold
 * @param {String} assistantId
 * @param {String} nlpThreshold
 * @returns {Promise<Object>}
 */
export function updateAssistantNlpThreshold(assistantId, nlpThreshold) {
  return new Promise(resolve => {
    request({
      url: `/assistants/${assistantId}/nlpThreshold`,
      method: 'post',
      data: {
        nlpThreshold: nlpThreshold
      }
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.error('error assistant api updateAssistantNLPThreshold', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}
/**
 * Force update Assistant DF
 * @param {String} assistantId
 * @param {String} nlpThreshold
 * @returns {Promise<Object>}
 */
export function updateDFAgent(assistantId) {
  return new Promise(resolve => {
    request({
      url: `/assistants/${assistantId}/DF`,
      method: 'post'
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.error('error assistant api updateAssistantNLPThreshold', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}

/**
 * Get assistant nlp settings
 * @param {String} assistantId
 * @param {String} platform
 * @returns {Promise<Object>}
 */
export function getAssistantNlpSettings(assistantId, platform = null) {
  return new Promise(resolve => {
    const query = platform ? `?platform=${encodeURIComponent(platform)}` : ''
    request({
      url: `/assistants/${assistantId}/nlp${query}`,
      method: 'get'
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        console.error('error assistant api getAssistantNlpSettings', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}

/**
 * Get assistant followup settings
 * @param {String} assistantId
 * @param {String} locale
 * @returns {Promise<Object>}
 */

export function getAssistantFollowupSettings(assistantId, locale) {
  return new Promise((resolve, reject) => {
    request({
      url: `/assistants/${assistantId}/settings/followup?locale=${locale}`,
      method: 'get'
    })
      .then(res => {
        if (res.code === 20000) {
          resolve(res.data)
        } else {
          reject({ message: res.error })
        }
      })
      .catch(err => {
        console.error('error getAssistantFollowupSettings', err)
        reject({
          code: 50000,
          data: [],
          message: err
        })
      })
  })
}

/**
 * Update assistant followup settings
 * @param {String} assistantId
 * @param {Object} settings - Followup Settings { locale <string>, answers <Array>, isAutomatic <Boolean>}
 * @returns {Promise<Object>}
 */
export function updateAssistantFollowupSettings(assistantId, settings) {
  return new Promise((resolve, reject) => {
    request({
      url: `/assistants/${assistantId}/settings/followup`,
      method: 'post',
      data: {
        settings
      }
    })
      .then(res => {
        if (res.code === 20000) {
          resolve(res.data)
        } else {
          reject({ message: res.error })
        }
      })
      .catch(err => {
        console.error('error updateAssistantFollowupSettings', err)
        reject({
          code: 50000,
          data: [],
          message: err
        })
      })
  })
}

/**
 * Upsert conversation rating settings
 * @param {String} assistantId
 * @param {Object} setting - Conversation rating settings
 * { _id <string>, isActivated <boolean>, conversation.displayPanelDelay <Number>, conversation.endConversationActions <Array> }
 * @returns {Promise<Object>}
 */
export function upsertConversationRatingSetting(assistantId, setting) {
  return request({
    url: `/assistants/${assistantId}/settings/rating`,
    method: 'post',
    data: setting,
    shouldSetDefaultError: true
  })
}

/**
 * Upsert AI chatbot (generative AI fallback) setting
 * @param {String} assistantId
 * @param {Object} setting - AI chatbot settings
 * @param {String|undefined} setting._id (undefined if new setting)
 * @param {Boolean} setting.isActivated
 * @param {String} setting.locale
 * @param {Object} setting.aiChatbot
 * @param {Number} setting.aiChatbot.nbPreviousConversationInteractions
 * @param {Number} setting.aiChatbot.nbFallbackMessagesBeforeAI
 * @param {String} setting.aiChatbot.assistantPromptDefinition
 * @returns {Promise<Object>}
 */
export function upsertAIChatbotSetting(assistantId, setting) {
  return request({
    url: `/assistants/${assistantId}/settings/aiChatbot`,
    method: 'post',
    data: setting,
    shouldSetDefaultError: true
  })
}

/**
 * Upsert assistant identity settings
 * @param {String} assistantId
 * @param {Object} settings - Identity settings
 * { _id <string>, identity['assistant-name'] <String>, identity.image <Object>, identity.voice <String> }
 * @returns {Promise<Object>}
 */
export function upsertIdentitySettings(assistantId, settings) {
  return new Promise((resolve, reject) => {
    request({
      url: `/assistants/${assistantId}/settings/identity`,
      method: 'post',
      data: {
        settings
      }
    })
      .then(res => {
        if (res.code === 20000) {
          resolve(res)
        } else {
          reject({ message: res.error })
        }
      })
      .catch(err => {
        console.error('error upsertIdentitySettings', err)
        reject({
          code: 50000,
          data: [],
          message: err
        })
      })
  })
}

/**
 * Has the number of conversations reached the authorized limit for the demo page
 * @param {String} assistantId
 * @param {String} locale
 * @returns {Promise<Object>}
 */
export function hasReachedLimitConversationsForDemoPage(assistantId, locale) {
  return new Promise((resolve, reject) => {
    request({
      url: `/assistants/${assistantId}/conversationsLimit/${locale}`,
      method: 'get'
    })
      .then(res => {
        if (res.code === 20000) {
          resolve(res)
        } else {
          reject({ message: res.error })
        }
      })
      .catch(err => {
        console.error('error hasReachedLimitConversationsForDemoPage', err)
        reject({
          code: 50000,
          data: [],
          message: err
        })
      })
  })
}

/**
 * Delete an assistant
 * @param {String} assistantId
 * @returns {Promise<Object>}
 */
export function deleteAssistant(assistantId) {
  return request({
    url: `/assistants/${assistantId}`,
    method: 'delete',
    shouldSetDefaultError: true
  })
}

/**
 * Check if an assistant can be deleted
 * @param {String} assistantId
 * @returns {Promise<Object>}
 */
export function isAssistantDeletionAuthorized(assistantId) {
  return request({
    url: `/assistants/${assistantId}/deletionAuthorization`,
    method: 'get'
  })
}

/**
 * Duplicate an assistant
 * @param {String} assistantId of the assistant being duplicated
 * @param {String} duplicateAssistantName name of the new assistant
 * @returns {Promise<Object>} activated assistant
 */
export function duplicateAssistant(assistantId, duplicateAssistantName) {
  return request({
    url: `/assistants/${assistantId}/duplicate`,
    method: 'post',
    data: {
      duplicateAssistantName
    },
    shouldSetDefaultError: true
  })
}

/**
 * Get assistant conversations
 * @param {String} assistantId
 * @param {Object} filters
 * @param {Number} filters.page
 * @param {Number} filters.limit
 * @param {String} filters.platform
 * @param {String} filters.intent
 * @param {String} filters.locale
 * @param {String} filters.nbMessages
 * @param {String} filters.rating
 * @returns {Promise<Object>} { conversations, platforms }
 */
export function getConversations(assistantId, filters) {
  return request({
    url: `/assistants/${assistantId}/conversations${getQueryString(filters)}`,
    method: 'get'
  })
}

/**
 * Get number of conversations
 * @param {String} assistantId
 * @param {String} [userId]
 * @param {Object} [filters]
 * @param {Number} filters.page
 * @param {Number} filters.limit
 * @param {String} filters.platform
 * @param {String} filters.intent
 * @param {String} filters.locale
 * @param {String} filters.nbMessages
 * @param {String} filters.rating
 * @returns {Promise<Object>} { conversations, platforms }
 */
export function getTotalConversations(assistantId, userId, filters) {
  const url = userId
    ? `/assistants/${assistantId}/users/${userId}/conversations/total`
    : `/assistants/${assistantId}/conversations/total`

  return request({
    url: `${url}${getQueryString(filters)}`,
    method: 'get'
  })
}

/**
 * Get list of intents from conversations
 * @param {String} assistantId
 * @param {String} locale
 * @returns {Promise<Object>}
 */
export function getConversationsIntents(assistantId, locale) {
  return request({
    url: `/assistants/${assistantId}/conversations/intents${getQueryString({ locale })}`,
    method: 'get'
  })
}

/**
 * Return a specific conversation
 *
 * @param {String} assistantId
 * @param {String} conversationId
 * @returns {Promise<Object>}
 */
export function getConversation(assistantId, conversationId) {
  return request({
    url: `/assistants/${assistantId}/conversations/${conversationId}`,
    method: 'get'
  })
}

/**
 * Update assistant timezone
 * @param {String} assistantId - Assistant mongo id
 * @param {String} timezone - Date timezone
 * @returns {Promise<Object>}
 */
export function updateTimezone(assistantId, timezone) {
  return request({
    url: `/assistants/${assistantId}/timezone`,
    method: 'put',
    data: {
      timezone
    }
  })
}

/**
 * Get assistant's on going conversations
 * @param {String} assistantId
 * @param {String} locale
 * @param {String} conversationFilter
 * @param {Array<String>} liveChatPlatformTypes
 * @returns {Promise<Array>}
 */
export function getInProgressConversations(assistantId, locale, conversationFilter, liveChatPlatformTypes) {
  return request({
    url: `/assistants/${assistantId}/conversations/inProgress${getQueryString({
      locale,
      conversationFilter,
      liveChatPlatformTypes: JSON.stringify(liveChatPlatformTypes)
    })}`,
    method: 'get',
    shouldSetDefaultError: true
  })
}

/**
 * Get assistant's resolved conversations
 * @param {String} assistantId
 * @param {String} locale
 * @param {Array<String>} liveChatPlatformTypes
 * @returns {Promise<Array>}
 */
export function getResolvedConversations(assistantId, locale, liveChatPlatformTypes) {
  return request({
    url: `/assistants/${assistantId}/conversations/resolved${getQueryString({
      locale,
      liveChatPlatformTypes: JSON.stringify(liveChatPlatformTypes)
    })}`,
    method: 'get',
    shouldSetDefaultError: true
  })
}

/**
 * Get assistant users
 * @param {String} assistantId
 * @param {Object} filters
 * @param {Number} filters.page
 * @param {Number} filters.limit
 * @param {String} filters.platform
 * @param {String} filters.locale
 * @returns {Promise<Object>} { users, platforms, totalDocs }
 */
export function getUsers(assistantId, filters) {
  return request({
    url: `/assistants/${assistantId}/users${getQueryString(filters)}`,
    method: 'get'
  })
}

/**
 * Get user info for the user
 * @param {String} assistantId
 * @param {String} userId
 * @returns {Promise<Object>} { user }
 */
export function getUser(assistantId, userId) {
  return request({
    url: `/assistants/${assistantId}/users/${userId}`,
    method: 'get'
  })
}

/**
 * Get user's conversations list
 * @param {String} assistantId
 * @param {String} userId
 * @param {Object} filters
 * @param {Number} filters.page
 * @param {Number} filters.limit
 * @param {String} filters.platform
 * @param {String} filters.intent
 * @param {String} filters.locale
 * @param {String} filters.nbMessages
 * @param {String} filters.rating
 * @returns {Promise<Object>} { conversations }
 */
export function getUserConversationsList(assistantId, userId, filters) {
  return request({
    url: `/assistants/${assistantId}/users/${userId}/conversations${getQueryString(filters)}`,
    method: 'get'
  })
}

/**
 * Get messages of a conversation with navigation links to adjacent conversation
 * @param {String} assistantId
 * @param {String} conversationId
 * @param {Object} filters
 * @param {String} filters.assistantId
 * @param {String} filters.platform
 * @param {String} filters.intent
 * @param {String} filters.locale
 * @param {Boolean} filters.preSignUrl
 * @returns {Promise<Object>}
 */
export function getConversationWithAdjacent(assistantId, conversationId, filters) {
  return request({
    url: `/assistants/${assistantId}/conversationsWithAdjacent/${conversationId}${getQueryString(filters)}`,
    method: 'get',
    shouldSetDefaultError: true
  })
}

/**
 * Get messages of a conversation with navigation links to adjacent conversation for user conversations
 * @param {String} assistantId
 * @param {String} conversationId
 * @param {String} userId
 * @param {Object} filters
 * @param {String} filters.assistantId
 * @param {String} filters.platform
 * @param {String} filters.intent
 * @param {String} filters.locale
 * @param {Boolean} filters.preSignUrl
 * @returns {Promise<Object>}
 */
export function getUserConversationWithAdjacent(assistantId, conversationId, userId, filters) {
  return request({
    url: `/assistants/${assistantId}/users/${userId}/conversationsWithAdjacent/${conversationId}${getQueryString(
      filters
    )}`,
    method: 'get',
    shouldSetDefaultError: true
  })
}
