// Utils
import { getQueryStringParam } from '@/utils/url'

const tokenKey = 'adminToken'

export function getToken() {
  // Take storage token as prio
  const storageToken = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey)

  if (storageToken && storageToken.length) {
    return storageToken
  }

  // If no token stored, check if there is a token in the URL
  const URLtoken = getQueryStringParam('token')

  if (URLtoken && URLtoken.length) {
    localStorage.setItem(tokenKey, URLtoken)
    sessionStorage.setItem(tokenKey, URLtoken)
    return URLtoken
  }

  return ''
}

export function setToken(token, storage) {
  return storage.setItem(tokenKey, token)
}

export function removeTokens() {
  localStorage.removeItem(tokenKey)
  sessionStorage.removeItem(tokenKey)
  return
}
