const config = {
  ADMIN_EVENT_TYPES: {
    LIVE_CHAT: {
      NB_ACTIVE_CONV_UPDATE: 'NB_ACTIVE_CONV_UPDATE',
      LIST_CONV_UPDATE: 'LIST_CONV_UPDATE',
      ADMIN_MESSAGE: 'ADMIN_MESSAGE',
      ERROR_MESSAGE: 'ERROR_MESSAGE',
      LIVE_AGENTS_LIST: 'LIVE_AGENTS_LIST'
    },
    ACTIVE_CONVERSATIONS: {
      SINGLE_CONV_UPDATE: 'ACTIVE_SINGLE_CONV_UPDATE',
      LIST_UPDATE: 'ACTIVE_LIST_CONV_UPDATE'
    },
    JOIN_ROOM: 'JOIN_ROOM',
    LEAVE_ROOM: 'LEAVE_ROOM'
  },
  RECONNECT_INTERVAL: 10000,
  ROOM_ID_SUFFIXES: {
    LIVE_CHAT: {
      MAIN: 'live_chat',
      LIST_UPDATE: 'live_chat_conversations'
    },
    ACTIVE_CONVERSATIONS: {
      LIST_UPDATE: 'active_conversations'
    }
  },
  ROOM_ID_SEPARATOR: '_'
}

export default config
