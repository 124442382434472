// Utils
import { removeInBetweenSpaces } from '@/utils/text'

export default class Cursor {
  /**
   * get/set cursor position
   * @param {HTMLColletion} target
   */
  constructor(target) {
    this.isContentEditable = target && target.contentEditable
    this.target = target
    this.endOffset = null
    this.endNodeIndex = null
  }
  /**
   * get cursor position
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Range}
   * @returns {Number}
   */
  getPosition() {
    // if the targeted element is contentEditable we have different approach to get the Cursor position
    if (this.isContentEditable) {
      this.target.focus({ preventScroll: true })
      const selectedText = this.getSelectionRangeText()
      if (selectedText && selectedText.length) {
        return selectedText.length
      }
    }

    // for texterea/input element
    return this.target.selectionStart
  }

  /**
   * Get selection range text
   * @returns {String}
   */
  getSelectionRangeText() {
    const selection = document.getSelection && document.getSelection()

    if (!selection || selection.rangeCount <= 0) {
      return null
    }

    const _range = document.getSelection().getRangeAt(0)
    const range = _range.cloneRange()
    range.selectNodeContents(this.target)
    range.setEnd(_range.endContainer, _range.endOffset)

    this.endOffset = range.endOffset
    this.endNodeIndex = this.indexInParent(range.endContainer)

    // Remove the extra spaces in between words
    return removeInBetweenSpaces(range.toString())
  }

  /**
   * set cursor position
   * @param {Number} pos - cursor position
   */
  setPosition(pos) {
    if (pos < 0) {
      return
    }
    // if the targeted element is contentEditable we have different approach to set the cursor position

    if (this.isContentEditable) {
      this.target.focus({ preventScroll: true })

      // 1. Get the NodeIndex (Index of the node of many elements inside the content editable element) and endOffset (Position inside the node)
      let index = this.endNodeIndex
      let offset = this.endOffset
      // 2. When the index is greater than the total node length set the index to the last node
      if (index + 1 > this.target.childNodes.length) {
        index = this.target.childNodes.length - 1
      }
      // 3. When index is not equal to the first element of the nodeList adjust the position of the offset
      if (index !== 0) {
        offset += 1
      }

      // 4. if no index node found, put the cursor at the end of input
      if (index === -1 || !this.target.childNodes[index]) {
        index = this.target.childNodes.length - 1
        offset = pos
      }

      // 5. When the offset greater than the length of the node, set the offset to the length
      if (this.target.childNodes && this.target.childNodes[index] && offset > this.target.childNodes[index].length) {
        offset = this.target.childNodes[index].length
      }

      // 6. If we have the valid node, set the position
      if (typeof this.target.childNodes[index] !== 'undefined') {
        // Calculate the offset position from the given position
        // Example: if the given position is 19 & we have the node list like "I am from <tag>country</tag> Hello"
        // "I am from " - Node 0 - total length 10
        // "country" - Node 1 - total length 7
        // " Hello" - Node 2 - total length 6
        // We need to get the offset postion inside " Hello" since the given position is 19

        let totalLength = 0
        let offsetPos = null
        for (const [idx, node] of this.target.childNodes.entries()) {
          // If the index greater than selected node index then break
          if (idx > index) {
            break
          }

          // Get the node text length
          const nbSpace = 2 // add +2 after the trim to consider space before and after the tag
          const nodeLength = typeof node === 'string' ? node.length : node.textContent.trim().length + nbSpace
          // Calculated the totalLength for traversed nodes
          totalLength += nodeLength

          // If the given position greater than the totalLength of traversed nodes then calculate the offsetPos value
          if (pos > totalLength) {
            offsetPos = offsetPos ? offsetPos - nodeLength : pos - nodeLength
          }
        }

        // Incase we have only one node set the offsetPos to given position
        if (!offsetPos) {
          offsetPos = pos
        }

        // Setting the cursor position
        // Cap offsetPos if it is greater then node length
        if (this.target.childNodes[index].textContent.length < offsetPos) {
          offsetPos = this.target.childNodes[index].textContent.length
        }

        // Set position only if node exists
        if (this.target.childNodes[index]) {
          this.setCursorAtOffsetPosition(this.target.childNodes[index], offsetPos)
        }
      }

      return
    }
    this.target.setSelectionRange(pos, pos)
  }

  /**
   * Set the cursor at the provided offset position
   * @param {<HTMLNode>} node
   * @param {Number} offsetPos
   */
  setCursorAtOffsetPosition(node, offsetPos) {
    try {
      const range = document.createRange()
      range.setStart(node, offsetPos)
      range.setEnd(node, offsetPos)
      const selection = window.getSelection()
      range.collapse(true)
      selection.removeAllRanges()
      selection.addRange(range)
    } catch (error) {
      console.error('setCursorAtOffsetPosition error', error)
    }
  }

  /**
   * Set the endNodeIndex
   * @param {Number} index
   */
  setEndNodeIndex(index) {
    this.endNodeIndex = index
  }

  /**
   * Finds the index of the specific node from its parent's list of node array, if not found returns -1
   * This index is used to place the cursor in respective node
   * @param {DOM} node
   * @returns {Number} Index of the node in the parents children
   */
  indexInParent(node) {
    const childrens = node.parentNode.childNodes

    for (let index = 0; index < childrens.length; index++) {
      // nodeType equal to 1 means a it is a html tag so we ignore it
      if (childrens[index] === node && childrens[index].nodeType !== 1) {
        return index
      }
    }
    return -1
  }

  /**
   * Place the cursor at the end of content editable element
   */
  placeCaretAtEnd() {
    this.target.focus({ preventScroll: true })

    if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
      const range = document.createRange()
      range.selectNodeContents(this.target)
      range.collapse(false)
      const selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
    } else if (typeof document.body.createTextRange !== 'undefined') {
      const textRange = document.body.createTextRange()
      textRange.moveToElementText(this.target)
      textRange.collapse(false)
      textRange.select()
    }
  }

  /**
   * Places the cursor next to the provided tag if it is contentEditableDiv
   * @param {<HTMLNode>} tag
   */
  placeCaretAtEndOfTag(tag) {
    if (!this.isContentEditable) {
      this.placeCaretAtEnd()
    }

    let isSetCursor = false

    for (const [idx, node] of this.target.childNodes.entries()) {
      if (isSetCursor) {
        this.setCursorAtOffsetPosition(this.target.childNodes[idx], 0)
        break
      }

      // Check if the node is same as the tag
      if (node.isSameNode(tag)) {
        // if the tag found place cursor at the starting of next node
        isSetCursor = true
        continue
      }
    }
  }
}
