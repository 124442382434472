<i18n src="../../lang/fr.json"></i18n>
<i18n src="../../lang/en.json"></i18n>
<template>
  <div
    v-if="selectedAssistant && selectedAssistant.slug"
    ref="dropdownAssistants"
    class="m-dropdown m-dropdown--arrow m-dropdown--open m-dropdown-app dropdown-header-top"
    m-dropdown-toggle="click"
    @click.prevent="AssistantMenuDisplay = !AssistantMenuDisplay"
  >
    <a href="#" class="m-dropdown__toggle btn btn-white btn-lg">
      {{ getAssistantLocalizedName(selectedAssistant, selectedAssistantLocale, selectedAssistant.slug) }}
    </a>
    <i class="fa fa-angle-down"></i>
    <div v-show="AssistantMenuDisplay && assistantsList && assistantsList.length > 0" class="m-dropdown__wrapper">
      <span class="m-dropdown__arrow m-dropdown__arrow--left" />
      <div class="m-dropdown__inner">
        <div class="m-dropdown__body dropdown">
          <div class="m-dropdown__content">
            <ul class="m-nav">
              <li v-for="(assistant, key) in assistantsList" :key="key" class="m-nav__item" :value="assistant">
                <a
                  href="#"
                  class="m-nav__link"
                  data-toggle="m-tooltip"
                  :title="getAssistantLocalizedName(assistant, userLocale, assistant.slug)"
                  data-placement="right"
                  data-skin="dark"
                  data-container="body"
                  :data-original-title="getAssistantLocalizedName(assistant, userLocale, assistant.slug)"
                  @click.prevent="selectAssistant(key, assistant)"
                >
                  <i class="m-nav__link-icon">
                    <img :src="getLocalizedAvatarURL(assistant, userLocale)" />
                  </i>
                  <span class="m-nav__link-text">
                    {{ getAssistantLocalizedName(assistant, userLocale, assistant.slug) }}
                  </span>
                </a>
              </li>
              <li v-if="hasAdminRole" class="m-nav__item border-top pt-2">
                <a href="#" class="m-nav__link" @click.prevent="addAssistant">
                  <i class="m-nav__link-icon la la-plus" />
                  <span class="m-nav__link-text">{{ $t('assistants.add_assistant_button') }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Dependances
import { mapGetters } from 'vuex'

// Utils
import { capitalize } from '@/utils/text'
import { getAssistantLocalizedName, getLocalizedAvatarURL } from '@/utils/assistant'
import { hasAdminRole } from '@/utils/validate'

// Bus
import Bus from '@/Bus.js'

export default {
  name: 'AssistantDropdown',
  props: {
    assistants: { type: Array, required: true },
    selectedAssistant: { type: Object, required: true },
    selectedAssistantLocale: { type: String, required: true }
  },
  data: function () {
    return {
      AssistantMenuDisplay: false,
      assistantsList: [],
      userLocale: this.$i18n.locale || this.$store.getters.locale
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar', 'roles']),
    hasAdminRole() {
      return hasAdminRole(this.roles)
    }
  },
  watch: {
    assistants: function (newValue, oldValue) {
      this.assistantsList = this.filterAssistants(this.assistants)

      if (newValue && newValue.length) {
        const newAssistant = newValue.find(assistant => assistant.slug === this.selectedAssistant.slug)

        // Replace current non populated selected assistant with new populated assistant
        if (!newAssistant) {
          // If currently selected assistant is not in current assistants list,
          // replace selectedAssistant with the first valid assistant of the list
          this.$store.dispatch('SwitchAssistant', { assistant: newValue[0] })
        } else if (!this.selectedAssistant['settings']) {
          if (newAssistant['settings'] && typeof newAssistant.settings[0] !== 'string') {
            // If current selectedAssistant was not populated but the new one is, update selectedAssistant with newAssistant
            this.$store.dispatch('SwitchAssistant', { assistant: newAssistant })
          } else {
            // newAssistant is not populated, that's not normal => log a warning
            console.warn(`${newAssistant.slug} is not populated`)
          }
        }
      }
    }
  },
  created() {
    document.addEventListener('click', this.documentClick)
    this.assistantsList = this.filterAssistants(this.assistants)
  },
  destroyed() {
    document.removeEventListener('click', this.documentClick)
  },
  methods: {
    getAssistantLocalizedName,
    getLocalizedAvatarURL,
    capitalize,
    addAssistant() {
      Bus.$emit('unLoadChat')
      this.$router.push({ path: '/assistant/creation' })
    },
    documentClick(event) {
      // close dropdown when we click outside the list
      const assistantDropdown = this.$refs.dropdownAssistants
      const target = event.target
      if (assistantDropdown && assistantDropdown !== target && !assistantDropdown.contains(target)) {
        this.AssistantMenuDisplay = false
      }
    },
    filterAssistants(assistants) {
      return assistants.filter(assistant => {
        return assistant.isActivated && !assistant.isGhost
      })
    },
    selectAssistant(key, assistant) {
      this.$emit('SwitchAssistant', { key, assistant })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.m-dropdown__inner {
  width: 270px;
}
.dropdown {
  max-height: 300px;
  overflow: auto;
  line-height: 1.6;
}
.btn-white,
.btn-white * {
  color: white;
  white-space: normal;
  text-align: center;
  line-height: 1.2;
}
.btn-white:hover,
.btn-white:hover * {
  color: #d8e5ff;
}
.m-brand__tools {
  text-align: left !important;
}
.m-header .m-dropdown__toggle.btn.btn-white.btn-lg {
  max-width: 132px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 3px;
  padding-right: 0;
  margin-left: 5px;
  text-align: left;
}
.m-header .m-dropdown .fa-angle-down {
  color: white;
  cursor: pointer;
}
.m-header .m-dropdown .fa-angle-down:hover {
  color: #d8e5ff;
}
.m-nav__link-icon {
  height: 35px !important;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  margin: 5px;
  padding: 3px;
  text-align: left;
}
.m-nav__link-icon img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 21px;
}
.m-nav .m-nav__item > .m-nav__link .m-nav__link-text {
  padding-left: 8px;
}
.m-dropdown-app a.m-dropdown__toggle {
  padding-top: 2px;
  padding-bottom: 0;
}
.m-dropdown-app-lang a.m-dropdown__toggle {
  padding-top: 2px;
  padding-bottom: 4px;
  padding-right: 0;
  margin-left: 5px;
  padding-left: 3px;
}
.m-dropdown-app .fa-angle-down {
  vertical-align: -4px;
}
.m-dropdown-app-lang .fa-angle-down {
  vertical-align: -1px;
  font-size: 10px;
}
.m-brand {
  padding: 0 30px 0 16px;
}
</style>
