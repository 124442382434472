import request from '@/utils/request'
import voiceConfig from '@/config/voice'

/**
 * Return TTS voices and audio recording
 * @param {String} yeldaLocale requested Yelda locale, format xx_XX
 * @param {String} ssml requested text
 * @param {String} service google or voxygen
 * @returns {Promise<Object>}
 */
function getVoices(yeldaLocale, ssml, service = voiceConfig.SERVICE.GOOGLE.name) {
  return new Promise(resolve => {
    request({
      url: `/tts/${service}/locales/${yeldaLocale}/voices`,
      method: 'post',
      data: {
        ssml
      }
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.error('ttsApi.getVoices error', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}

/**
 * Return TTS voice for a given voice
 * @param {String} ssml requested text
 * @param {String} locale requested Yelda locale, format xx_XX
 * @param {String} voice requested voice
 * @param {String} service voiceConfig.SERVICE
 * @returns {Promise<Object>}
 */
function generateTTS(ssml, voice, locale, service = voiceConfig.SERVICE.GOOGLE.name) {
  if (typeof voice === 'undefined' || typeof locale === 'undefined') {
    return
  }

  return new Promise(resolve => {
    request({
      url: `/tts/${service}/locales/${locale}/voices/${voice} `,
      method: 'post',
      data: {
        ssml: ssml
      }
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.error('ttsApi.generateTTS error', err)
        resolve({
          code: 50000,
          data: [],
          err: err
        })
      })
  })
}

export default {
  getVoices,
  generateTTS
}
